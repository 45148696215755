/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import classNames from "classnames";
import moment from "moment";
import { bool, func, instanceOf, objectOf, shape, string } from "prop-types";
import { isMobileNative } from "util/device";
import FieldError from "pages/_components/fields/FieldError";
import FieldLabel from "pages/_components/fields/FieldLabel";
import DatePicker from "pages/_components/fields/datepicker";
import { getLimitDate } from "util/date";

import * as i18n from "util/i18n";
import Hint from "../hints/Hint";

export class DateField extends Component {
    static propTypes = {
        field: shape({ name: string }).isRequired,
        form: shape({ errors: objectOf(string), touched: objectOf(bool) }).isRequired,
        handleChange: func,
        hideLabel: bool,
        hidePlaceholder: bool,
        endDate: instanceOf(moment),
        errorClassName: string,
        maxDate: instanceOf(moment),
        minDate: instanceOf(moment),
        dateFormat: string,
        idForm: string,
        showDisabledMonthNavigation: bool,
        startDate: instanceOf(moment),
        showYearDropdown: bool,
        showMonthDropdown: bool,
        idField: string.isRequired,
        formikValueFirst: bool,
        formGroupClassName: string,
        style: shape({}),
        onCustomChange: func,
        customKey: string,
        small: bool,
        isDisabled: bool,
        handleCustomChange: func,
        controlLabelClassName: string,
        controlLabelChildClassName: string,
        formGroupTextClassName: string,
        hintIdText: string,
        disableEmptyDate: bool,
    };

    static defaultProps = {
        handleChange: () => {
            // fix sonar empty arrow function
        },
        handleCustomChange: null,
        hideLabel: false,
        hidePlaceholder: false,
        maxDate: getLimitDate(3650),
        minDate: moment().add(-6, "months"),
        dateFormat: null,
        endDate: null,
        errorClassName: "",
        idForm: "",
        showDisabledMonthNavigation: true,
        startDate: null,
        showYearDropdown: false,
        showMonthDropdown: false,
        formikValueFirst: false,
        formGroupClassName: "",
        style: {},
        onCustomChange: null,
        customKey: null,
        small: false,
        isDisabled: false,
        controlLabelClassName: "",
        controlLabelChildClassName: "",
        formGroupTextClassName: "",
        hintIdText: "",
        disableEmptyDate: false,
    };

    state = {
        isFocused: false,
        selectedDate: null,
    };

    validateDate = (selectedDate) => {
        const { maxDate, minDate } = this.props;
        if (selectedDate) {
            const fromDate = new Date(minDate).setDate(new Date(minDate).getDate() - 1);
            const toDate = new Date(maxDate).setDate(new Date(maxDate).getDate() + 1);

            if (minDate && selectedDate.diff(moment(fromDate)) < 0) {
                return false;
            }

            if (maxDate && selectedDate.diff(moment(toDate)) >= 0) {
                return false;
            }
        }

        return true;
    };

    handleChange = (selectedDate) => {
        const { field, form, handleChange, onCustomChange, handleCustomChange, disableEmptyDate } = this.props;

        if (disableEmptyDate && !selectedDate) {
            return;
        }

        if (!this.validateDate(selectedDate)) {
            return;
        }

        if (handleChange) {
            handleChange(selectedDate);
        }

        if (onCustomChange) {
            onCustomChange(selectedDate.toDate());
        }

        if (handleCustomChange) {
            handleCustomChange(selectedDate, form);
        } else {
            this.setState({ selectedDate });

            form.setFieldValue(field.name, selectedDate ? selectedDate.toDate() : null);
        }
    };

    render() {
        const { isFocused, selectedDate } = this.state;
        const {
            endDate,
            errorClassName,
            field,
            form: { touched, errors },
            hideLabel,
            hidePlaceholder,
            idForm,
            maxDate,
            minDate,
            showDisabledMonthNavigation,
            startDate,
            dateFormat,
            idField,
            formikValueFirst,
            formGroupClassName,
            style,
            customKey,
            isDisabled,
            small,
            controlLabelClassName,
            controlLabelChildClassName,
            formGroupTextClassName,
            hintIdText,
            ...rest
        } = this.props;

        const hasError = touched && touched[field.name] && errors && errors[field.name];
        return (
            <div
                className={classNames("form-group", "form-group--datepicker", formGroupClassName, {
                    "has-error": hasError,
                    "has-focus": isFocused,
                })}>
                {!hideLabel && (
                    <FieldLabel
                        labelKey={customKey || `${idForm}.${field.name}.label`}
                        idField={field.name}
                        isDisabled={isDisabled}
                        small={small}
                        controlLabelClassName={controlLabelClassName}
                        controlLabelChildClassName={controlLabelChildClassName}
                        formGroupTextClassName={formGroupTextClassName}
                        extraLabel={
                            hintIdText ? (
                                <Hint
                                    idMessage={hintIdText}
                                    classNameGroup="justify-content-center d-flex my-0"
                                    classNameImage="my-0 ml-2 justify-content-center align-self-center"
                                    classNameMessage="hint__content"
                                    classNameHintP="aling-self-center d-flex my-0"
                                />
                            ) : (
                                undefined
                            )
                        }
                    />
                )}
                <div className={`input-group ${isDisabled ? "input-group-disable" : ""}`}>
                    <div className="form-control">
                        <DatePicker
                            dateFormat={dateFormat === null ? i18n.get("datepicker.format") : dateFormat}
                            className="form-control"
                            formatWeekDay={isMobileNative && ((day) => day.substr(0, 2))}
                            calendarClassName={classNames({ "form-datepicker": isMobileNative })}
                            startDate={startDate ? moment(startDate) : undefined}
                            endDate={endDate ? moment(endDate) : undefined}
                            maxDate={maxDate ? moment(maxDate) : undefined}
                            minDate={minDate ? moment(minDate) : undefined}
                            placeholderText={hidePlaceholder ? "" : i18n.get(`${idForm}.${field.name}.placeholder`)}
                            selected={(!formikValueFirst && selectedDate) || (field.value && moment(field.value))}
                            showDisabledMonthNavigation={showDisabledMonthNavigation}
                            onChange={this.handleChange}
                            id={field.name}
                            style={style}
                            autocomplete="off"
                            {...rest}
                        />
                    </div>
                </div>
                {hasError && <FieldError error={errors[field.name]} errorClassName={errorClassName} />}
            </div>
        );
    }
}

export default DateField;
