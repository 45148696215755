import React from "react";
import Nav from "react-bootstrap/lib/Nav";
import { connect } from "react-redux";
import { func, string, object as obj, shape, bool, node, oneOfType } from "prop-types";

import { actions as sessionActions, selectors as sessionSelectors } from "reducers/session";
import { actions as transactionsActions } from "reducers/transactions";
import { actions as servicePaymentsActions } from "reducers/servicePayments";
import { actions as paymentsAFIPActions } from "reducers/paymentsAFIP";
import { actions as debinActions } from "reducers/debin";
import { actions as globalActions } from "reducers/types/global";
import { actions as cedipActions } from "reducers/cedip";
import { selectors as i18nSelectors } from "reducers/i18n";
import { CORPORATE_GROUP_ENVIRONMENT_TYPE } from "constants.js";
import * as i18n from "util/i18n";
import * as config from "util/config";
import MenuFormItem from "pages/_components/menu/MenuFormItem";
import MenuItem from "pages/_components/menu/MenuItem";
import MenuSection from "pages/_components/menu/MenuSection";
import classNames from "classnames";
import isTokenActive from "util/token";
import FeatureFlag from "../FeatureFlag";
import ToggleSidebar from "./ToggleSidebar";

class Menu extends React.Component {
    static propTypes = {
        dispatch: func.isRequired,
        isMobile: bool,
        isAdministrator: bool,
        path: oneOfType([string, shape({ pathname: string })]).isRequired,
        activeEnvironment: shape({
            permissions: shape({
                accounts: bool,
                requestTransactionCancellation: bool,
                transferInternal: bool,
                transferThirdParties: bool,
            }),
            forms: obj,
            administrationScheme: string,
            type: string,
        }).isRequired,
        lang: string.isRequired,
        isCollapsed: bool,
        onToggleSidebar: func.isRequired,
        isRetailEnvironment: bool,
        isSmallDesktop: bool.isRequired,
    };

    static defaultProps = {
        isMobile: false,
        isAdministrator: false,
        isCollapsed: false,
        isRetailEnvironment: false,
    };

    logOut = () => {
        const { dispatch } = this.props;
        dispatch(sessionActions.logout());
    };

    buildAdministrationMenu = () => {
        const {
            isMobile,
            isAdministrator,
            activeEnvironment,
            path,
            isRetailEnvironment,
            isSmallDesktop,
            isCollapsed,
        } = this.props;

        let admMenu = null;

        const showAdminTab = !isMobile && isAdministrator && !isRetailEnvironment;

        if (showAdminTab) {
            if (activeEnvironment.administrationScheme !== "simple") {
                admMenu = (
                    <MenuSection
                        titleKey="administration.menu.name"
                        key="menu.administration"
                        image="images/administration.svg"
                        isSmallDesktop={isSmallDesktop}
                        isCollapsed={isCollapsed}
                        isSelected={path && path.startsWith("/administration")}>
                        <MenuItem
                            titleKey="administration.menu.users"
                            key="menu.administration.users"
                            linkTo="/administration/users"
                            isSelected={path && path.startsWith("/administration/users")}
                        />
                        {activeEnvironment.administrationScheme !== "medium" && (
                            <MenuItem
                                titleKey="administration.menu.groups"
                                key="menu.administration.groups"
                                linkTo="/administration/groups"
                                isSelected={path && path.startsWith("/administration/groups")}
                            />
                        )}
                        <MenuItem
                            titleKey="administration.menu.signatureScheme"
                            key="menu.administration.signatureScheme"
                            linkTo={`/administration/${activeEnvironment.administrationScheme}/signaturesSchemes`}
                            isSelected={
                                path &&
                                path === `/administration/${activeEnvironment.administrationScheme}/signaturesSchemes`
                            }
                        />
                        <MenuItem
                            titleKey="administration.restrictions.environment.title"
                            key="menu.administration"
                            linkTo="/administration/restrictions"
                            isSelected={path && path === "/administration/restrictions"}
                        />
                    </MenuSection>
                );
            } else {
                admMenu = (
                    <div className="toggle-menu-button-container">
                        <MenuItem
                            titleKey="administration.menu.name"
                            key="menu.administration"
                            linkTo="/administration/users"
                            image="images/administration.svg"
                            isSelected={path && path === "/administration/users"}
                        />
                    </div>
                );
            }
        }

        return admMenu;
    };

    constructMenuFormItems(sectionForms) {
        const { lang } = this.props;
        const menuItems = [];

        if (sectionForms && sectionForms.length > 0) {
            sectionForms.forEach((section) => {
                menuItems.push(
                    <MenuFormItem link={`/form/${section.idForm}`} name={section.name[lang]} key={section.idForm} />,
                );
            });
        }
        return menuItems;
    }

    constructMenuSection(activeEnvironment, menuName, titleKey, ImageSection, linkTo) {
        const { path, isSmallDesktop, isCollapsed } = this.props;
        let menuSection = null;
        let menuItems = [];

        if (!activeEnvironment) {
            return <></>;
        }
        if (menuName !== "deposits" || activeEnvironment.permissions[menuName]) {
            // provisorio por esta iteracion
            menuItems.push(
                <MenuItem titleKey={titleKey} key={titleKey} linkTo={linkTo} isSelected={path && path === linkTo} />,
            );
        }

        let sectionForms = activeEnvironment.forms[menuName];

        if (menuName === "accounts" && sectionForms) {
            sectionForms = sectionForms.filter(
                ({ idForm }) => !["requestOfManagementCheck", "accountOpening"].includes(idForm),
            );
        }

        menuItems = menuItems.concat(this.constructMenuFormItems(sectionForms));

        if (menuItems.length > 0) {
            menuSection = (
                <MenuSection
                    id={`menu.${menuName}`}
                    titleKey={`menu.${menuName}`}
                    key={`menu.${menuName}`}
                    image={ImageSection}
                    isSmallDesktop={isSmallDesktop}
                    isCollapsed={isCollapsed}
                    isSelected={path && path === linkTo}>
                    {menuItems}
                </MenuSection>
            );
        }
        return menuSection;
    }

    render() {
        const {
            activeEnvironment,
            isMobile,
            onToggleSidebar,
            isCollapsed,
            path,
            isRetailEnvironment,
            dispatch,
            isSmallDesktop,
        } = this.props;
        const showSecondRelease = config.getBoolean("frontend.show.second.release.functionalities");
        const showMultipleTransfers = config.getBoolean("frontend.show.MultipleTransfers.functionalities");
        const showCedip = config.getBoolean("backend.show.cedipmvp1.functionalities");

        if (!activeEnvironment) {
            return <></>;
        }

        // Desktop
        const desktopMenuSection = (
            <div
                className={classNames("toggle-menu-button-container menu-item-single d-flex", {
                    "menu-section-selected": path && path === "/desktop",
                })}>
                <MenuItem
                    titleKey="menu.desktop"
                    key="menu.desktop"
                    linkTo="/desktop"
                    image="images/escritorio.svg"
                    svgClassName="svg-image menu-svg-single"
                    isSelected={path && path === "/desktop"}
                />
            </div>
        );

        // CMFToken
        const tokenMenuSection = (
            <div
                className={classNames("toggle-menu-button-container d-flex", {
                    "menu-section-selected": path && path === "/tokenPassword",
                })}>
                <MenuItem
                    titleKey="menu.token"
                    key="menu.token"
                    linkTo="/tokenPassword"
                    image="images/shield-check.svg"
                    isSelected={path && path === "/tokenPassword"}
                />
            </div>
        );

        const configurationTokenMenuSection = (
            <div
                className={classNames("toggle-menu-button-container menu-item-single d-flex", {
                    "menu-section-selected": path && path === "/settings/token",
                })}>
                <MenuItem
                    titleKey="settings.token"
                    key="settings.token"
                    linkTo="/settings/token"
                    image="images/shield-check-solid.svg"
                    svgClassName="svg-image menu-svg-single"
                    isSelected={path && path === "/settings/token"}
                />
            </div>
        );

        // Checks
        let checksMenuSection = null;
        const checksMenuItems = [];
        if (activeEnvironment?.permissions.requestCheckbook) {
            checksMenuItems.push(
                <MenuItem
                    titleKey="menu.checks.requestCheckbook"
                    key="menu.checks.requestCheckbook"
                    linkTo="/checks"
                    isSelected={path && path === "/checks"}
                />,
            );
        }
        if (activeEnvironment?.permissions["operate.echeqs"]) {
            checksMenuItems.push(
                <MenuItem
                    titleKey="menu.checks.echeqs"
                    key="menu.checks.echeqs"
                    linkTo="/echeqs"
                    isSelected={path && path.startsWith("/echeqs")}
                />,
            );
        }

        if (activeEnvironment?.permissions["checks.detail.view"]) {
            checksMenuItems.push(
                <MenuItem
                    titleKey="menu.checks.list.checksDetail"
                    key="menu.checks.list.checksDetail"
                    linkTo="/checksDetail"
                    isSelected={path && path === "/checksDetail"}
                />,
            );
        }

        if (checksMenuItems.length > 0) {
            checksMenuSection = (
                <MenuSection
                    id="menu.checks"
                    titleKey="menu.checks"
                    key="menu.checks"
                    image="images/cheques.svg"
                    isSmallDesktop={isSmallDesktop}
                    isCollapsed={isCollapsed}
                    isSelected={
                        path &&
                        (path.startsWith("/cheqs") ||
                            path.startsWith("/echeqs") ||
                            path === "/checksDetail" ||
                            path === "/discountedChecksDetail")
                    }>
                    {checksMenuItems}
                </MenuSection>
            );
        }

        // Acounts
        const accountMenuSection = (
            <div
                className={classNames("toggle-menu-button-container menu-item-single d-flex", {
                    "menu-section-selected": (path && path === "/accounts") || (path && path === "/accountOpening"),
                })}>
                <MenuItem
                    titleKey="menu.accounts.myAccounts"
                    key="menu.accounts.myAccounts"
                    linkTo="/accounts"
                    image="images/cuentas.svg"
                    svgClassName="svg-image menu-svg-single"
                    isSelected={(path && path === "/accounts") || (path && path === "/accountOpening")}
                />
            </div>
        );

        // Investments and Fundcorp
        let investmentsMenuSection = null;
        const investmentsMenuItem = [];

        if (activeEnvironment.permissions.deposits) {
            investmentsMenuItem.push(
                <MenuItem
                    titleKey="menu.deposits.myDeposits"
                    key="menu.deposits.myDeposits"
                    linkTo="/deposits"
                    isSelected={path && path.startsWith("/deposits")}
                />,
            );
        }

        if (showSecondRelease && activeEnvironment.permissions.fundcorpOperations) {
            investmentsMenuItem.push(
                <MenuItem
                    titleKey="menu.invesments.tradeFunds"
                    key="menu.invesments.tradeFunds"
                    linkTo="/fundcorpOperations"
                    isSelected={path && path === "/fundcorpOperations"}
                />,
            );
        }

        if (showCedip && activeEnvironment.permissions.cedip) {
            investmentsMenuItem.push(
                <MenuItem
                    titleKey="menu.investments.manageCedip"
                    key="menu.investments.manageCedip"
                    linkTo="/cedip"
                    isSelected={path && path === "/cedip"}
                    onSelect={() => {
                        if (path !== "/cedip") {
                            dispatch(cedipActions.resetFilters());
                            dispatch(cedipActions.setRecievedCedipSelected());
                        }
                    }}
                />,
            );
        }

        if (showSecondRelease && activeEnvironment.permissions.fundcorpInformation) {
            investmentsMenuItem.push(
                <MenuItem
                    titleKey="menu.fundcorp.fundInformation"
                    key="menu.fundcorp.fundInformation"
                    linkTo="/fundInformation"
                    isSelected={path && path === "/fundInformation"}
                />,
            );
        }

        if (investmentsMenuItem.length > 0) {
            investmentsMenuSection = (
                <MenuSection
                    id="menu.investments"
                    titleKey="menu.investments"
                    key="menu.investments"
                    image="images/inversiones.svg"
                    isSmallDesktop={isSmallDesktop}
                    isCollapsed={isCollapsed}
                    isSelected={
                        path &&
                        (path.startsWith("/deposits") ||
                            path.startsWith("/fundcorpOperations") ||
                            path === "/fundInformation")
                    }>
                    {investmentsMenuItem}
                </MenuSection>
            );
        }

        // financing
        let financingMenuSection = null;

        if (activeEnvironment.permissions.financingList) {
            financingMenuSection = (
                <MenuSection
                    id="menu.financing"
                    titleKey="menu.financing"
                    key="menu.financing"
                    image="images/financiaciones.svg"
                    isSmallDesktop={isSmallDesktop}
                    isCollapsed={isCollapsed}
                    isSelected={path && path === "/financing/categories"}>
                    <MenuFormItem
                        link="/financing/categories"
                        key="financing"
                        name={i18n.get("menu.financing.categories")}
                        isSelected={path && path === "/financing/categories"}
                    />
                </MenuSection>
            );
        }

        // Transferencias
        let transferMenuSection = null;
        const transferMenuItems = [];
        if (activeEnvironment.permissions.frequentDestination) {
            transferMenuItems.push(
                <MenuFormItem
                    link="/FrequentDestination"
                    key="frequentDestinationList"
                    name={i18n.get("frequentDestinationList.main.label")}
                    isSelected={path && path.startsWith("/FrequentDestination")}
                />,
            );
        }
        if (activeEnvironment.permissions.transferInternal || activeEnvironment.permissions.transferThirdParties) {
            transferMenuItems.push(
                <MenuFormItem
                    link="/transfer"
                    key="transfer"
                    name={i18n.get("transfers.main.label")}
                    isSelected={path && path === "/transfer"}
                />,
            );
        }
        if (showMultipleTransfers && activeEnvironment?.permissions?.transferMultiple) {
            transferMenuItems.push(
                <MenuItem
                    linkTo="/transfer/multiple"
                    key="transferMultiple"
                    titleKey="menu.multiple.transfers"
                    onSelect={() => {
                        if (path !== "/transfer/multiple") {
                            dispatch(globalActions.resetSelectedElements());
                        }
                    }}
                    isSelected={path && path.startsWith("/transfer/multiple")}
                />,
            );
        }
        if (activeEnvironment.permissions["send.credin"]) {
            transferMenuItems.push(
                <MenuFormItem
                    link="/requestCredin"
                    key="requestCredin"
                    name={i18n.get("credin.main.label")}
                    isSelected={path && path === "/requestCredin"}
                />,
            );
        }
        if (transferMenuItems.length > 0) {
            transferMenuSection = (
                <MenuSection
                    id="menu.transfers"
                    titleKey="menu.transfers"
                    key="menu.transfers"
                    image="images/transferencias.svg"
                    isSmallDesktop={isSmallDesktop}
                    isCollapsed={isCollapsed}
                    isSelected={
                        path &&
                        (path.startsWith("/FrequentDestination") ||
                            path === "/transfer" ||
                            path.startsWith("/transfer/multiple") ||
                            path === "/requestCredin")
                    }>
                    {transferMenuItems}
                </MenuSection>
            );
        }

        // Payments
        let paymentMenuSection = null;
        const paymentMenuItems = [];

        if (activeEnvironment.permissions["pay.debin"]) {
            paymentMenuItems.push(
                <MenuItem
                    titleKey="menu.payments.payDebin"
                    key="menu.payments.payDebin"
                    linkTo="/payments/payDebin"
                    onSelect={() => {
                        if (path !== "/payments/payDebin") {
                            dispatch(debinActions.resetFilters());
                        }
                    }}
                    isSelected={path && path.startsWith("/payments/payDebin")}
                />,
            );
        }

        if (activeEnvironment.permissions["pay.third.parties"]) {
            paymentMenuItems.push(
                <MenuItem
                    titleKey="menu.payments.payThirdParties"
                    key="menu.payments.thirdParties"
                    linkTo="/payThirdParties/list"
                    isSelected={path && path === "/payThirdParties/list"}
                />,
            );
        }
        if (activeEnvironment.permissions["pay.afip"] && activeEnvironment.permissions["service.payments.view"]) {
            paymentMenuItems.push(
                <MenuItem
                    titleKey="menu.payments.payServicesAfip"
                    key="menu.payments.payServicesAfip"
                    onSelect={() => {
                        if (path !== "/servicePayments") {
                            dispatch(servicePaymentsActions.cleanUp());
                            dispatch(paymentsAFIPActions.cleanUp());
                        }
                    }}
                    linkTo="/servicePayments"
                    isSelected={path && path === "/servicePayments"}
                />,
            );
        }
        if (paymentMenuItems.length > 0) {
            paymentMenuSection = (
                <MenuSection
                    titleKey="menu.payments"
                    key="menu.payments"
                    image="images/pagos.svg"
                    isSmallDesktop={isSmallDesktop}
                    isCollapsed={isCollapsed}
                    isSelected={
                        path &&
                        (path === "/payments/payDebin" ||
                            path === "/payThirdParties/list" ||
                            path === "/servicePayments" ||
                            path === "/payments/recurrent")
                    }>
                    {paymentMenuItems}
                </MenuSection>
            );
        }

        // Metrocorp
        let metrocorpMenuSection = null;
        const metrocorpMenuItems = [];

        if (activeEnvironment.permissions.metrocorp) {
            metrocorpMenuItems.push(
                <MenuItem
                    titleKey="menu.metrocorp.consultOperations"
                    key="menu.metrocorp.consultOperations"
                    linkTo="/metrocorp"
                    isSelected={path && path === "/metrocorp"}
                />,
            );

            if (metrocorpMenuItems.length > 0) {
                metrocorpMenuSection = (
                    <MenuSection
                        titleKey="menu.metrocorp"
                        key="menu.metrocorp"
                        image="images/metrocorp.svg"
                        isSmallDesktop={isSmallDesktop}
                        isCollapsed={isCollapsed}
                        isSelected={path && path === "/metrocorp"}>
                        {metrocorpMenuItems}
                    </MenuSection>
                );
            }
        }

        // Ecomex Operations
        let ecomexMenuSection = null;
        const ecomexMenuItems = [];

        if (
            activeEnvironment.permissions.ecomexConsultOperations ||
            activeEnvironment.permissions.ecomexInformativeRegime ||
            activeEnvironment.permissions.ecomexAlertsAndDues
        ) {
            if (activeEnvironment.permissions.ecomexConsultOperations) {
                ecomexMenuItems.push(
                    <MenuItem
                        titleKey="menu.ecomex.consultOperations"
                        key="menu.ecomex.consultOperations"
                        linkTo="/ecomex/consultOperations"
                        isSelected={path && path === "/ecomex/consultOperations"}
                    />,
                );
            }

            if (activeEnvironment.permissions.ecomexInformativeRegime && !isRetailEnvironment) {
                ecomexMenuItems.push(
                    <MenuItem
                        titleKey="menu.ecomex.informativeRegime"
                        key="menu.ecomex.informativeRegime"
                        linkTo="/ecomex/informativeRegime"
                        isSelected={path && path === "/ecomex/informativeRegime"}
                    />,
                );
            }

            if (activeEnvironment.permissions.ecomexAlertsAndDues && !isRetailEnvironment) {
                ecomexMenuItems.push(
                    <MenuItem
                        titleKey="menu.ecomex.alertsAndDues"
                        key="menu.ecomex.alertsAndDues"
                        linkTo="/ecomex/alertsAndDues"
                        isSelected={path && path === "/ecomex/alertsAndDues"}
                    />,
                );
            }

            if (ecomexMenuItems.length > 0) {
                ecomexMenuSection = (
                    <MenuSection
                        titleKey="menu.ecomex"
                        key="menu.ecomex"
                        image="images/ecomex-logo.svg"
                        isSmallDesktop={isSmallDesktop}
                        isCollapsed={isCollapsed}
                        isSelected={
                            path &&
                            (path === "/ecomex/consultOperations" ||
                                path === "/ecomex/informativeRegime" ||
                                path === "/ecomex/alertsAndDues")
                        }>
                        {ecomexMenuItems}
                    </MenuSection>
                );
            }
        }

        const transactionsMenuSection = (
            <div
                className={classNames("toggle-menu-button-container menu-item-single d-flex", {
                    "menu-section-selected": path && path === "/transactions/list",
                })}>
                <MenuItem
                    titleKey="menu.transactions"
                    key="menu.transactions"
                    linkTo="/transactions/list"
                    isSelected={path && path === "/transactions/list"}
                    image="images/historial.svg"
                    svgClassName="svg-image menu-svg-single"
                    onSelect={() => {
                        if (path !== "/transactions/list") {
                            dispatch(globalActions.resetSelectedElements());
                        }
                    }}
                />
            </div>
        );

        // Charges
        let charges = null;

        if (activeEnvironment.permissions.charges) {
            charges = (
                <MenuSection
                    titleKey="menu.charges"
                    key="menu.charges"
                    image="images/cobros.svg"
                    isSmallDesktop={isSmallDesktop}
                    isCollapsed={isCollapsed}
                    isSelected={path && path.startsWith("/charges")}>
                    <MenuItem
                        titleKey="menu.generateDebin"
                        key="menu.generateDebin"
                        linkTo="/charges/generateDebin"
                        isSelected={path && path.startsWith("/charges")}
                        onSelect={() => {
                            if (path !== "/charges/generateDebin") {
                                dispatch(globalActions.resetSelectedElements());
                            }
                        }}
                    />
                </MenuSection>
            );
        }

        // Pending dispatch
        const pendingDispatch = (
            <FeatureFlag id="feature.signatureSchema.dispatchControl">
                <div
                    className={classNames("toggle-menu-button-container menu-item-single d-flex", {
                        "menu-section-selected": path && path === "/pendingTransaction/list",
                    })}>
                    <MenuItem
                        titleKey="menu.pendingDispatch"
                        key="menu.pendingDispatch"
                        linkTo="/pendingTransaction/list"
                        image="images/transaccionesPendientes.svg"
                        svgClassName="svg-image menu-svg-single"
                        onSelect={() => {
                            if (path !== "/pendingTransaction/list") {
                                dispatch(transactionsActions.initPendings());
                            }
                        }}
                        isSelected={path && path === "/pendingTransaction/list"}
                    />
                </div>
            </FeatureFlag>
        );

        // Settings
        const settingsMenuItem = (
            <>
                <MenuSection
                    id="menu.settings"
                    titleKey="menu.settings"
                    key="menu.settings"
                    image="images/perfil.svg"
                    isCollapsed={isCollapsed}
                    isSmallDesktop={isSmallDesktop}>
                    <MenuItem
                        titleKey="settings.index"
                        key="menu.settings"
                        linkTo="/settings"
                        image="images/configuration.svg"
                        isSelected={path && path === "/settings"}
                    />
                    <MenuItem
                        titleKey="settings.index.accountsOfficer.label"
                        key="menu.accountsOfficer"
                        linkTo="/accountsOfficer"
                        image="images/account_circle.svg"
                        isSelected={path && path === "/accountsOfficer"}
                    />
                    <MenuItem
                        titleKey="settings.token"
                        key="menu.profile.getToken"
                        linkTo="/settings/token"
                        image="images/shield-check-filled.svg"
                        isSelected={path && path === "/settings/token"}
                    />
                </MenuSection>
            </>
        );

        // Administration
        const administrationMenuSection = this.buildAdministrationMenu();

        const logoutItem = (
            <div className={classNames("toggle-menu-button-container btn-logout d-flex")}>
                <MenuItem
                    titleKey="global.logout"
                    key="global.logout"
                    image="images/send-out.svg"
                    onClick={this.logOut}
                    itemClassName="my-3"
                />
            </div>
        );

        // Fixed term acceptance
        const fixedTermAcceptanceMenuItem = (
            <div
                className={classNames("toggle-menu-button-container menu-item-single d-flex", {
                    "menu-section-selected": path && path === "/fixedTermAcceptance",
                })}>
                <MenuItem
                    titleKey="menu.fixedTermAcceptance"
                    key="menu.fixedTermAcceptance"
                    linkTo="/fixedTermAcceptance"
                    image="images/aceptacionPlazoFijo.svg"
                    svgClassName="svg-image menu-svg-single"
                    isSelected={path && path === "/fixedTermAcceptance"}
                />
            </div>
        );
        return (
            <Nav
                className={classNames("menu-list", {
                    "menu-retail": isRetailEnvironment,
                    "menu-retail-mb": isRetailEnvironment && isMobile,
                })}
                role="menu">
                {!isMobile && <ToggleSidebar isSidebarCollapsed={isCollapsed} onToggle={onToggleSidebar} />}

                <FirstLevelItem>{desktopMenuSection}</FirstLevelItem>
                {isTokenActive() ? <FirstLevelItem>{tokenMenuSection}</FirstLevelItem> : undefined}
                <FirstLevelItem>{accountMenuSection}</FirstLevelItem>
                <FirstLevelItem>{transferMenuSection}</FirstLevelItem>
                <FirstLevelItem>{checksMenuSection}</FirstLevelItem>
                <FirstLevelItem>{investmentsMenuSection}</FirstLevelItem>
                <FirstLevelItem>{financingMenuSection}</FirstLevelItem>
                <FirstLevelItem>{showSecondRelease && ecomexMenuSection}</FirstLevelItem>
                <FirstLevelItem>{paymentMenuSection}</FirstLevelItem>
                <FirstLevelItem>{charges}</FirstLevelItem>
                {activeEnvironment.type !== CORPORATE_GROUP_ENVIRONMENT_TYPE && (
                    <FirstLevelItem>{pendingDispatch}</FirstLevelItem>
                )}
                <FirstLevelItem>{showSecondRelease && metrocorpMenuSection}</FirstLevelItem>
                <FirstLevelItem>{transactionsMenuSection}</FirstLevelItem>
                {!isMobile && <FirstLevelItem>{configurationTokenMenuSection}</FirstLevelItem>}
                {!isMobile && <FirstLevelItem className="mb-5">{administrationMenuSection}</FirstLevelItem>}
                {isMobile && <FirstLevelItem className="mb-5 dropdown-colored">{settingsMenuItem}</FirstLevelItem>}
                <FirstLevelItem>{fixedTermAcceptanceMenuItem}</FirstLevelItem>
                <FirstLevelItem className="mb-5">{logoutItem}</FirstLevelItem>
                <li className="version-text">{window.appVersion}</li>
            </Nav>
        );
    }
}
function FirstLevelItem({ children, className }) {
    FirstLevelItem.propTypes = {
        className: string,
    };

    FirstLevelItem.defaultProps = {
        className: "",
    };
    return (
        <li role="menuitem" className={classNames("menu-list-item", className)}>
            {children}
        </li>
    );
}

FirstLevelItem.propTypes = {
    children: node.isRequired,
};

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    lang: i18nSelectors.getLang(state),
    isAdministrator: sessionSelectors.isAdministrator(state),
    isRetailEnvironment: sessionSelectors.isRetailEnvironment(state),
});

export default connect(mapStateToProps)(Menu);
