/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from "react";
import Col from "react-bootstrap/lib/Col";
import { func, arrayOf, shape, bool, string } from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { routerActions } from "react-router-redux/actions";
import { Field, Form, withFormik } from "formik";
import { compose } from "redux";
import Yup from "yup";
import { push } from "react-router-redux";
import { format } from "date-fns";
import moment from "moment";
import { Row } from "react-bootstrap";

import I18n from "pages/_components/I18n";
import Notification from "pages/_components/Notification";
import Container from "pages/_components/Container";
import MainContainer from "pages/_components/MainContainer";
import Head from "pages/_components/Head";
import Button from "pages/_components/Button";
import TextField from "pages/_components/fields/TextField";
import Selector from "pages/_components/fields/formik/Selector";
import MultiSelectField from "pages/_components/fields/MultiSelectField";
import Hint from "pages/_components/hints/Hint";
import DateField from "pages/_components/fields/DateField";

import { actions, selectors } from "reducers/administration/usersInvite";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as notificationActions } from "reducers/notification";

import * as i18nUtils from "util/i18n";
import * as configUtils from "util/config";

const FORM_ID = "administration.users.invite";
const REGEX_ONLY_TEXT = "[A-Za-z\u00f1\u00D1\u00E1\u00E9\u00ED\u00F3\u00FA\u00C1\u00C9\u00CD\u00D3\u00DA\\s]*";

class UserInviteStep2 extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isSubmitting: bool.isRequired,
        isValid: bool.isRequired,
        isInvitingNewUser: bool.isRequired,
        signatureLevels: arrayOf(shape({ id: string, label: string })),
        roleList: arrayOf(shape({ id: string, label: string })).isRequired,
        groupList: arrayOf(shape({ idGroupAsString: string, name: string })).isRequired,
        selectedDocument: shape({ country: string, document: string, type: string }).isRequired,
        setErrors: func.isRequired,
        existentUserInfo: shape({}).isRequired,
    };

    static defaultProps = {
        signatureLevels: [],
    };

    componentDidUpdate(prevProps) {
        const { dispatch, isSubmitting, isValid } = this.props;
        if (prevProps.isSubmitting && isSubmitting !== prevProps.isSubmitting && !isValid) {
            dispatch(
                notificationActions.showNotification(i18nUtils.get("forms.fieldsErrors"), "error", [
                    "administrationUserInvite",
                ]),
            );
        }
    }

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(actions.userInviteFinish());
        dispatch(routerActions.goBack());
    };

    renderMediumSchemeConfiguration = () => {
        const { roleList } = this.props;
        const transactionSignatureOptions = [
            { id: "yes", label: i18nUtils.get("administration.users.invite.transaction.signature.yes") },
            { id: "no", label: i18nUtils.get("administration.users.invite.transaction.signature.no") },
        ];

        return (
            <>
                <div className="form-section-title">
                    <h2>
                        <I18n id="administration.users.invite.initial.configuration.subtitle" />
                    </h2>
                    <hr />
                </div>
                <div className="form-group form-group--select">
                    <div className="form-group-text">
                        <label className="control-label">
                            <I18n id="administration.users.invite.transaction.signature" />
                        </label>
                    </div>
                    <Field
                        component={Selector}
                        options={transactionSignatureOptions}
                        idForm={FORM_ID}
                        name="signatureLevel"
                        renderAs="radio"
                        inLineControl
                    />
                </div>
                <div className="form-group-text">
                    <label className="control-label">
                        <I18n id="administration.users.invite.roles.label" />
                    </label>
                </div>

                <Field component={Selector} options={roleList} idForm={FORM_ID} name="role" renderAs="radio" />
            </>
        );
    };

    renderAdvancedSchemeConfiguration = () => {
        const { groupList, signatureLevels } = this.props;

        const groupOptionsMap = new Map();
        groupList.forEach((group) => {
            groupOptionsMap.set(group.idGroupAsString, group.name);
        });
        const groupOptions = [...groupOptionsMap.keys()];

        return (
            <>
                <div className="form-section-title">
                    <h2>
                        <I18n id="administration.users.invite.initial.configuration.subtitle" />
                    </h2>
                    <hr />
                </div>
                <div className="form-group form-group--select">
                    <div className="form-group-text">
                        <label className="control-label">
                            <I18n id="administration.users.edit.signatureLevel" />
                        </label>
                    </div>
                    <Field
                        component={Selector}
                        options={signatureLevels}
                        idForm={FORM_ID}
                        name="signatureLevel"
                        renderAs="radio"
                        inLineControl
                    />
                </div>

                <div className="form-group-text">
                    <label className="control-label">
                        <I18n id="administration.users.edit.groups" />
                    </label>
                </div>
                <Field
                    component={MultiSelectField}
                    hidePlaceholder
                    idForm={FORM_ID}
                    name="groups"
                    options={groupOptions}
                    hideLabel
                    textOptionsMap={groupOptionsMap}
                />
            </>
        );
    };

    continueButton = (isSubmitting) => (
        <Container className="container--layout align-items-center" gridClassName="form-content">
            <Col className="col col-12 px-0 inputs-invite-user" md={9} lg={6}>
                <Button
                    bsStyle="primary"
                    label="global.continue"
                    loading={isSubmitting}
                    type="submit"
                    className="my-0"
                />
            </Col>
        </Container>
    );

    formNewUser = (inputFieldsViewMode, isSubmitting, setErrors) => (
        <Form className="above-the-fold">
            <Container
                className="container--layout align-items-center container-invite-user"
                gridClassName="form-content">
                <Col className="col col-12 col-md-10">
                    <I18n
                        component="h2"
                        componentProps={{ className: "text-center mb-4 mt-4" }}
                        id="administration.users.invite.personal.data.subtitle"
                    />
                </Col>
                <Col className="col col-12 px-xl-0 inputs-invite-user mt-2 mb-2" md={9} lg={6}>
                    <Col className="col col-12 p-0 mb-2">
                        <Field
                            mode={inputFieldsViewMode}
                            component={TextField}
                            hidePlaceholder
                            idForm={FORM_ID}
                            name="firstName"
                            type="text"
                            pattern={REGEX_ONLY_TEXT}
                        />
                    </Col>
                    <Col className="col col-12 p-0">
                        <Field
                            mode={inputFieldsViewMode}
                            component={TextField}
                            hidePlaceholder
                            idForm={FORM_ID}
                            name="lastName"
                            type="text"
                            pattern={REGEX_ONLY_TEXT}
                        />
                    </Col>
                    <Col className="col col-12 p-0">
                        <Row>
                            <Col className="col col-12" md={6}>
                                <Field
                                    idField="birthDate"
                                    type="date"
                                    component={DateField}
                                    hidePlaceholder
                                    idForm={FORM_ID}
                                    name="birthdate"
                                    maxDate={moment().format("YYYY-MM-DD")}
                                />
                            </Col>
                            <Col className="col col-12" md={6}>
                                <Field
                                    mode={inputFieldsViewMode}
                                    hidePlaceholder
                                    placeholder={i18nUtils.get("administration.mobilePhone.placeholder")}
                                    component={TextField}
                                    onChange={() => {
                                        setErrors({});
                                    }}
                                    idForm={FORM_ID}
                                    name="mobilePhone"
                                    type="text"
                                    maxLength={15}
                                />
                            </Col>
                            <Col className="invite-user-hint">
                                <Hint classNameHintP="w-100" idMessage="administration.users.info.user.1" />
                            </Col>
                        </Row>
                    </Col>
                    <Col className="col col-12 p-0">
                        <Row>
                            <Col className="col col-12">
                                <Field
                                    mode={inputFieldsViewMode}
                                    hidePlaceholder
                                    component={TextField}
                                    idForm={FORM_ID}
                                    name="email"
                                    type="text"
                                />
                            </Col>
                            <Col className="invite-user-hint">
                                <Hint classNameHintP="w-100" idMessage="administration.users.info.user.2" />
                            </Col>
                        </Row>
                    </Col>
                    <Col className="col col-12 p-0">
                        <Row>
                            <Col className="col col-12" md={6}>
                                <Field
                                    hidePlaceholder
                                    disabled
                                    component={TextField}
                                    idForm={FORM_ID}
                                    name="country"
                                    type="text"
                                    isDisabled
                                />
                            </Col>
                            <Col className="col col-12" md={6}>
                                <Field
                                    mode={inputFieldsViewMode}
                                    hidePlaceholder
                                    component={TextField}
                                    idForm={FORM_ID}
                                    maxLength={50}
                                    name="province"
                                    type="text"
                                    pattern={REGEX_ONLY_TEXT}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col className="col col-12 p-0">
                        <Row>
                            <Col className="col col-8" md={9}>
                                <Field
                                    mode={inputFieldsViewMode}
                                    hidePlaceholder
                                    component={TextField}
                                    idForm={FORM_ID}
                                    name="address"
                                    type="text"
                                />
                            </Col>
                            <Col className="col col-4" md={3}>
                                <Field
                                    mode={inputFieldsViewMode}
                                    hidePlaceholder
                                    component={TextField}
                                    idForm={FORM_ID}
                                    maxLength={12}
                                    name="number"
                                    type="text"
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col className="col col-12 p-0">
                        <Row>
                            <Col className="col col-4" md={3}>
                                <Field
                                    mode={inputFieldsViewMode}
                                    hidePlaceholder
                                    component={TextField}
                                    idForm={FORM_ID}
                                    maxLength={20}
                                    name="postalCode"
                                    type="text"
                                />
                            </Col>
                            <Col className="col col-8" md={9}>
                                <Field
                                    mode={inputFieldsViewMode}
                                    hidePlaceholder
                                    component={TextField}
                                    idForm={FORM_ID}
                                    maxLength={80}
                                    name="localidad"
                                    type="text"
                                />
                            </Col>
                        </Row>
                    </Col>
                </Col>
            </Container>
            {this.continueButton(isSubmitting)}
        </Form>
    );

    formExistinUser = (existentUserInfo, isSubmitting) => (
        <Form className="above-the-fold">
            <div className="above-the-fold">
                <Container
                    className="container--layout align-items-center container-invite-user pb-5 pb-xl-8"
                    gridClassName="form-content">
                    <I18n
                        component="h2"
                        componentProps={{ className: "invite-user-title mb-0 mt-4" }}
                        id="administration.users.invite.existing.user.data.subtitle"
                    />

                    <I18n
                        component="h4"
                        componentProps={{ className: "invite-user-subtitle w-100" }}
                        id="administration.users.invite.existing.user.data.confirm"
                    />

                    <Col className="col col-12 px-xl-0 inputs-invite-user mt-2" md={9} lg={6}>
                        <div className="data-wrapper-flex">
                            <div className="data-label data-label-inline">
                                <I18n id="administration.users.invite.firstName.label" />
                            </div>
                            <div className="data-desc data-desc-inline mb-0">{existentUserInfo.firstName}</div>
                        </div>

                        <div className="data-wrapper-flex">
                            <div className="data-label data-label-inline">
                                <I18n id="administration.users.invite.lastName.label" />
                            </div>
                            <div className="data-desc data-desc-inline mb-0 ">{existentUserInfo.lastName}</div>
                        </div>

                        <div className="data-wrapper-flex">
                            <div className="data-label data-label-inline">
                                <I18n id="administration.users.invite.birthdate.label" />
                            </div>
                            <div className="data-desc data-desc-inline mb-0">{existentUserInfo.birthdate}</div>
                        </div>

                        <div className="data-wrapper-flex">
                            <div className="data-label data-label-inline">
                                <I18n id="administration.users.invite.mobilePhone.label" />
                            </div>
                            <div className="data-desc data-desc-inline mb-0">{existentUserInfo.mobilePhone}</div>
                        </div>

                        <div className="data-wrapper-flex">
                            <div className="data-label data-label-inline">
                                <I18n id="administration.users.invite.email.label" />
                            </div>
                            <div className="data-desc data-desc-inline mb-0">{existentUserInfo.email}</div>
                        </div>
                        <div className="data-wrapper-flex">
                            <div className="data-label data-label-inline">
                                <I18n id="administration.users.invite.country.label" />
                            </div>
                            <div className="data-desc data-desc-inline mb-0">{existentUserInfo.country}</div>
                        </div>
                        <div className="data-wrapper-flex">
                            <div className="data-label data-label-inline">
                                <I18n id="administration.users.invite.province.label" />
                            </div>
                            <div className="data-desc data-desc-inline mb-0">{existentUserInfo.province}</div>
                        </div>
                        <div className="data-wrapper-flex">
                            <div className="data-label data-label-inline">
                                <I18n id="administration.users.invite.address.label" />
                            </div>
                            <div className="data-desc data-desc-inline mb-0">{existentUserInfo.address}</div>
                        </div>
                        <div className="data-wrapper-flex">
                            <div className="data-label data-label-inline">
                                <I18n id="administration.users.invite.number.label" />
                            </div>
                            <div className="data-desc data-desc-inline mb-0">{existentUserInfo.number}</div>
                        </div>
                        <div className="data-wrapper-flex">
                            <div className="data-label data-label-inline">
                                <I18n id="administration.users.invite.postalCode.label" />
                            </div>
                            <div className="data-desc data-desc-inline mb-0">{existentUserInfo.postalCode}</div>
                        </div>
                        <div className="data-wrapper-flex">
                            <div className="data-label data-label-inline">
                                <I18n id="administration.users.invite.localidad.label" />
                            </div>
                            <div className="data-desc data-desc-inline mb-0">{existentUserInfo.localidad}</div>
                        </div>
                    </Col>
                </Container>
                {this.continueButton(isSubmitting)}
            </div>
        </Form>
    );

    render() {
        const { selectedDocument, isInvitingNewUser, existentUserInfo, isSubmitting, setErrors } = this.props;

        if (!selectedDocument) {
            return <Redirect to="/administration/users/invite" />;
        }
        const inputFieldsViewMode = isInvitingNewUser ? "edit" : "view";
        return (
            <>
                <Notification scopeToShow="administrationUserInvite" />
                <div className="admin-detail-head admin-detail-head-section px-0 userinvitehead rigth-position-desktop">
                    <Head onBack={this.handleBack} closeLinkTo="/administration/users" />
                    <div className="mb-4">
                        <I18n
                            component="h1"
                            componentProps={{ className: "view-title" }}
                            id="administration.users.invite.title"
                        />
                    </div>
                </div>
                <MainContainer className="main-container administration" viewContentClassName="pt-0" showLoader={false}>
                    {isInvitingNewUser
                        ? this.formNewUser(inputFieldsViewMode, isSubmitting, setErrors)
                        : this.formExistinUser(existentUserInfo, isSubmitting)}
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    roleList: selectors.getRoleList(state),
    groupList: selectors.getGroupList(state),
    encryptedDocument: selectors.getEncryptedDocument(state),
    selectedDocument: selectors.getSelectedDocument(state),
    isInvitingNewUser: selectors.isInvitingNewUser(state),
    userFinalInformation: selectors.getUserFinalInformation(state),

    existentUserInfo: selectors.getExistentUserInfo(state),
    signatureLevels: configUtils
        .getArray("administration.signatures.signatureLevels")
        .map((value) => ({
            id: value,
            label: value,
        }))
        .concat({
            id: "N",
            label: i18nUtils.get("administration.users.edit.signatureLevel.dontSign"),
        }),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: (props) => {
            const { isInvitingNewUser, existentUserInfo, userFinalInformation } = props;
            const birthdateFormat = userFinalInformation.birthdate
                ? format(userFinalInformation.birthdate, "YYYY-MM-DD")
                : "";
            return {
                firstName: !isInvitingNewUser && existentUserInfo ? existentUserInfo.firstName : "",
                lastName: !isInvitingNewUser && existentUserInfo ? existentUserInfo.lastName : "",
                mobilePhone:
                    !isInvitingNewUser && existentUserInfo
                        ? existentUserInfo.mobilePhone
                        : userFinalInformation.mobilePhone,
                email: !isInvitingNewUser && existentUserInfo ? existentUserInfo.email : userFinalInformation.email,
                country: "Argentina",
                province: !isInvitingNewUser && existentUserInfo ? existentUserInfo.province : "",
                localidad:
                    !isInvitingNewUser && existentUserInfo
                        ? existentUserInfo.localidad
                        : userFinalInformation.localidad,
                postalCode:
                    !isInvitingNewUser && existentUserInfo
                        ? existentUserInfo.postalCode
                        : userFinalInformation.postalCode,
                birthdate: !isInvitingNewUser && existentUserInfo ? existentUserInfo.birthdate : birthdateFormat,
                address:
                    !isInvitingNewUser && existentUserInfo ? existentUserInfo.address : userFinalInformation.address,
                number: !isInvitingNewUser && existentUserInfo ? existentUserInfo.number : userFinalInformation.number,
            };
        },

        validationSchema: (props) => {
            const { isInvitingNewUser } = props;

            return Yup.object().shape({
                email: isInvitingNewUser
                    ? Yup.string()
                          .email(i18nUtils.get("administration.users.emailIncorrectFormat"))
                          .required(i18nUtils.get("administration.users.fieldEmpty"))
                    : Yup.string().notRequired(),
                firstName: isInvitingNewUser
                    ? Yup.string().required(i18nUtils.get("administration.users.fieldEmpty"))
                    : Yup.string().notRequired(),
                lastName: isInvitingNewUser
                    ? Yup.string().required(i18nUtils.get("administration.users.fieldEmpty"))
                    : Yup.string().notRequired(),
                mobilePhone: isInvitingNewUser
                    ? Yup.string().required(i18nUtils.get("administration.users.fieldEmpty"))
                    : Yup.string().notRequired(),
                province: isInvitingNewUser
                    ? Yup.string().required(i18nUtils.get("administration.users.fieldEmpty"))
                    : Yup.string().notRequired(),
                localidad: isInvitingNewUser
                    ? Yup.string().required(i18nUtils.get("administration.users.fieldEmpty"))
                    : Yup.string().notRequired(),
                address: isInvitingNewUser
                    ? Yup.string().required(i18nUtils.get("administration.users.fieldEmpty"))
                    : Yup.string().notRequired(),
                number: isInvitingNewUser
                    ? Yup.string().required(i18nUtils.get("administration.users.shortFieldEmpty"))
                    : Yup.string().notRequired(),
                postalCode: isInvitingNewUser
                    ? Yup.string().required(i18nUtils.get("administration.users.shortFieldEmpty"))
                    : Yup.string().notRequired(),
                birthdate: isInvitingNewUser
                    ? Yup.string().required(i18nUtils.get("administration.users.fieldEmpty"))
                    : Yup.string().notRequired(),
            });
        },
        handleSubmit: (data, formikBag) => {
            const {
                dispatch,
                selectedDocument,
                encryptedDocument,
                isInvitingNewUser,
                existentUserInfo,
            } = formikBag.props;

            const params = {
                ...data,
                firstName: isInvitingNewUser ? data.firstName : existentUserInfo.firstName,
                lastName: isInvitingNewUser ? data.lastName : existentUserInfo.lastName,
                email: isInvitingNewUser ? data.email : existentUserInfo.email,
                mobilePhone: isInvitingNewUser ? data.mobilePhone : existentUserInfo.mobilePhone,
                country: "Argentina",
                province: isInvitingNewUser ? data.province : existentUserInfo.province,
                localidad: isInvitingNewUser ? data.localidad : existentUserInfo.localidad,
                address: isInvitingNewUser ? data.address : existentUserInfo.address,
                number: isInvitingNewUser ? data.number : existentUserInfo.number,
                postalCode: isInvitingNewUser ? data.postalCode : existentUserInfo.postalCode,
                birthdate: isInvitingNewUser ? moment(data.birthdate) : existentUserInfo.birthdate,

                document: encryptedDocument,
                docType: selectedDocument.type,
                docNumber: selectedDocument.document,
            };

            let mobilePhoneWithoutBlanks = data.mobilePhone.replaceAll(/\s/g, "").trim();
            mobilePhoneWithoutBlanks = mobilePhoneWithoutBlanks.replaceAll("-", "").trim();
            mobilePhoneWithoutBlanks = mobilePhoneWithoutBlanks.replaceAll("*", "").trim();
            const isnum = /^\d+$/.test(mobilePhoneWithoutBlanks);
            const { mobilePhone } = data;

            if (!isnum || mobilePhone.length < 10 || mobilePhone.length > 15) {
                formikBag.setErrors({ mobilePhone: i18nUtils.get("login.step1.wrong.format") });
                formikBag.setSubmitting(false);
            } else {
                dispatch(actions.userFinalInformation(params));
                dispatch(push("/administration/users/GroupSignature"));
            }
        },
    }),
)(UserInviteStep2);
