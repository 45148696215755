/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from "react";
import { arrayOf, bool, func, number, shape, string } from "prop-types";
import { Field, Form } from "formik";
import { Col, Row } from "react-bootstrap";
import classNames from "classnames";
import { isAndroid, isIOS } from "react-device-detect";
import moment from "moment";

import Container from "pages/_components/Container";
import I18n from "pages/_components/I18n";
import TabletSelectors from "pages/_components/TabletSelectors";
import Button from "pages/_components/Button";
import Image from "pages/_components/Image";
import TextField from "pages/_components/fields/TextField";
import Tooltip from "pages/_components/tooltip/Tooltip";
import Date from "pages/_components/fields/DateField";
import AmountField from "pages/_components/fields/formik/AmountField";
import Selector from "pages/_components/fields/formik/Selector";

import { actions } from "reducers/checks";

import * as i18n from "util/i18n";
import * as configUtils from "util/config";
import { getLimitDate } from "util/date";
import EcheqsDatesSelectors from "./EcheqsDatesSelectors";
import EcheqsFilterSelect from "./EcheqsFilterSelect";
import IdEcheqSelector from "./_components/IdEcheqsSelector";

const FORM_ID = "echeqs";
const INITIAL_ECHEQS_STATES = [
    { value: "Activo", label: "Activo" },
    { value: "Activo-pendiente", label: "Activo-Pendiente" },
    { value: "Anulado", label: "Anulado" },
    { value: "Caducado", label: "Caducado" },
    { value: "Custodia", label: "Custodia" },
    { value: "Cesion-pendiente", label: "Cesión-Pendiente" },
    { value: "Depositado", label: "Depositado" },
    { value: "Devolucion-pendiente", label: "Devolución-Pendiente" },
    { value: "Emitido-Pendiente", label: "Emitido-Pendiente" },
    { value: "Negociación", label: "Negociación" },
    { value: "Negociacion-Pendiente", label: "Negociación-Pendiente" },
    { value: "Pagado", label: "Pagado" },
    { value: "Presentado", label: "Presentado" },
    { value: "Rechazado", label: "Rechazado" },
    { value: "Repudiado", label: "Repudiado" },
];

const echeqsActions = [
    { status: INITIAL_ECHEQS_STATES, value: "all", label: "echeqs.actions.options.all" },
    {
        status: [
            { value: "Activo-pendiente", label: "Activo-Pendiente" },
            { value: "Emitido-Pendiente", label: "Emitido-Pendiente" },
            { value: "Cesion-pendiente", label: "Cesion-Pendiente" },
        ],
        value: "to_admit",
        label: "echeqs.actions.options.to_admit",
    },
    {
        status: [
            { value: "Activo", label: "Activo" },
            { value: "Activo-pendiente", label: "Activo-Pendiente" },
            { value: "Emitido-Pendiente", label: "Emitido-Pendiente" },
            { value: "Cesion-pendiente", label: "Cesión-Pendiente" },
        ],
        value: "to_custody",
        label: "echeqs.actions.options.to_custody",
    },
    {
        status: [
            { value: "Activo", label: "Activo" },
            { value: "Activo-pendiente", label: "Activo-Pendiente" },
            { value: "Emitido-Pendiente", label: "Emitido-Pendiente" },
            { value: "Cesion-pendiente", label: "Cesión-Pendiente" },
        ],
        value: "to_deposit",
        label: "echeqs.actions.options.to_deposit",
    },
    {
        status: [
            { value: "Activo", label: "Activo" },
            { value: "Activo-pendiente", label: "Activo-Pendiente" },
            { value: "Emitido-Pendiente", label: "Emitido-Pendiente" },
            { value: "Cesion-pendiente", label: "Cesión-Pendiente" },
        ],
        value: "request_discount",
        label: "echeqs.actions.options.request_discount",
    },
];

const INITIAL_OPTIONS = ["received.echeqs", "emitted.echeqs"];

function EcheqsFilters({
    dispatch,
    values,
    setValues,
    handleSubmit,
    isSubmitting,
    setSubmitting,
    option,
    cleanFilter,
    changeFilterFunc,
    filterOrder,
    showSnackbarFiltred,
    setEcheqsState,
    selectAll,
    multipleSelection,
    enabledEcheqMassive,
    showForm,
    showCleanFilterButton,
    stateList,
    idSelectorError,
    numberSelectorError,
    activeHint,
    width,
    isSmallDesktop,
    isDesktop,
    isMobile,
}) {
    const initialAllStatus = INITIAL_ECHEQS_STATES.reduce((acc, option) => [...acc, option.value], []).join(",");
    const [options, setOptions] = useState([]);
    const [possibleOptions, setPossibleOptions] = useState(INITIAL_OPTIONS);
    const [defaultStatusOption, setDefaultStatusOption] = useState({ value: initialAllStatus, label: "Todos" });
    const [echeqsStates, setEcheqsStates] = useState(INITIAL_ECHEQS_STATES);

    const isNotFullsize = isDesktop && width < 1625;

    const limitPeriod = configUtils.getInteger("frontend.MassiveEcheq.allowedPeriod.Months", 6);
    const massiveStates = stateList.map((state) => ({
        value: state,
        label: i18n.get(`echeq.batch.status.${state.toLowerCase()}`).toUpperCase(),
    }));

    const nrsIdsFiltersEnabled = configUtils.getBoolean("FilterEcheqs.NrsIds.functionalities", false);

    const orderByReceived = [
        {
            value: "fecha_pago",
            label: i18n.get("echeqs.filters.searchBy.payDate"),
        },
    ];

    const orderByEmitted = [
        {
            value: "fecha_emision",
            label: i18n.get("echeqs.filters.searchBy.emitedDate"),
        },
        {
            value: "fecha_pago",
            label: i18n.get("echeqs.filters.searchBy.payDate"),
        },
    ];

    const radioOptions = [
        {
            id: "0",
            label: <I18n id="echeqs.filters.searchBy.upward" />,
        },
        {
            id: "1",
            label: <I18n id="echeqs.filters.searchBy.falling" />,
        },
    ];

    useEffect(() => {
        if (enabledEcheqMassive) {
            setPossibleOptions([...INITIAL_OPTIONS, "emitted.massive.echeq"]);
        }
    }, [enabledEcheqMassive]);

    useEffect(() => {
        setOptions(
            possibleOptions.map((possibleOption) => ({
                value: possibleOption,
                label: possibleOption,
            })),
        );
    }, [possibleOptions]);

    useEffect(() => {
        if (values.action !== "all") {
            const statusAllValue = (
                echeqsActions.find((action) => action.value === values.action)?.status || INITIAL_ECHEQS_STATES
            )
                .reduce((acc, option) => [...acc, option.value], [])
                .join(",");
            setDefaultStatusOption({
                value: statusAllValue,
                label: "Todos",
            });
            setEcheqsStates(
                echeqsActions.find((action) => action.value === values.action)?.status || INITIAL_ECHEQS_STATES,
            );
        }
    }, [values.action]);

    const getSelectedOption = (op) => {
        switch (op) {
            case "emitted.echeqs":
                return "emitted";
            case "received.echeqs":
                return "received";
            case "emitted.massive.echeq":
                return "massive";
            default:
                return "";
        }
    };

    const states = getSelectedOption(option) === "massive" ? massiveStates : echeqsStates;

    const changeOption = async (value) => {
        await dispatch(actions.setEcheqOption(value));
        cleanFilters(value);
        cleanSelectedEcheqs();
        setEcheqsStates(INITIAL_ECHEQS_STATES);
    };

    const cleanSelectedEcheqs = () => {
        setEcheqsState({
            selectedRows: [],
            selectedAll: false,
        });
    };

    const selectAction = (value) => {
        const statusAllValue = (echeqsActions.find((action) => action.value === value)?.status || INITIAL_ECHEQS_STATES)
            .reduce((acc, option) => [...acc, option.value], [])
            .join(",");
        setEcheqsStates(echeqsActions.find((action) => action.value === value)?.status || INITIAL_ECHEQS_STATES);
        setDefaultStatusOption({
            value: statusAllValue,
            label: "Todos",
        });
        setValues({
            ...values,
            payedFrom:
                (value === "to_custody" || value === "request_discount") &&
                moment(values.payedFrom).diff(moment(), "days") < -30
                    ? getLimitDate(-30)
                    : values.payedFrom,
            payedTo:
                value === "to_deposit" && moment(values.payedTo).diff(moment(), "days") > 30
                    ? getLimitDate(30)
                    : values.payedTo,
            action: value,
            status: statusAllValue,
            ownership: value === "all" || value === "to_admit" ? "TODOS" : "TITULAR",
        });
    };

    const cleanFilters = (option, statusValue) => {
        const selectedOption = getSelectedOption(option);
        let newValues = {
            payedCheck: true,
            emitedCheck: true,
            receiver: "",
            emitted: "",
            ownership: "TODOS",
            number: [],
            grouper: "",
            id: [],
            action: "all",
            status: initialAllStatus,
        };
        if (selectedOption === "received") {
            newValues = {
                ...newValues,
                createdFrom: getLimitDate(-7),
                createdTo: getLimitDate(0),
                payedFrom: getLimitDate(0),
                payedTo: getLimitDate(7),
                orderColumName: "fecha_pago",
                orderDirection: "descendent",
            };
        } else if (selectedOption === "emitted") {
            newValues = {
                ...newValues,
                createdFrom: getLimitDate(-365),
                createdTo: getLimitDate(0),
                payedFrom: getLimitDate(-30),
                payedTo: getLimitDate(365),
                orderColumName: "fecha_emision",
                orderDirection: "descendent",
            };
        } else if (selectedOption === "massive") {
            const limitPeriod = configUtils.getInteger("frontend.MassiveEcheq.allowedPeriod.Months", 6);
            newValues = {
                ...newValues,
                status: "TODOS",
                createdFrom: getLimitDate(-(limitPeriod * 30)),
                createdTo: getLimitDate(0),
                amountFrom: "",
                amountTo: "",
                number: [],
                id: [],
            };
        }
        setValues(newValues);
        setSubmitting(true);
        dispatch(actions.setEcheqFilters(newValues));
        dispatch(actions.resetShowCleanFilterButton());
        dispatch(
            actions.filterEcheqList(
                {
                    ...newValues,
                    option,
                    status: newValues.status.label || newValues.status,
                    pageNumber: 1,
                },
                setSubmitting,
                isDesktop,
            ),
        );
        cleanSelectedEcheqs();
        setEcheqsStates(INITIAL_ECHEQS_STATES);
        setDefaultStatusOption({
            value: initialAllStatus,
            label: "Todos",
        });
        setEcheqsState({ cleanFilter: true, showCleanFilterButton: false });
        dispatch(actions.cleanEcheqsIdsNrs());
        dispatch(actions.resetErrorsIdNumber());
    };

    const handleHintDisplay = (hintId) => {
        this.setState({ activeHint: hintId });
    };

    return (
        <Form
            className="w-100 echeqs__filter-form"
            onSubmit={async (e) => {
                await handleSubmit(e);
                selectAll(false);
                setEcheqsState((prev) => ({
                    ...prev,
                    showForm: false,
                    showSnackbarFiltred: !prev.showSnackbarFiltred,
                }));
            }}>
            <Container
                className={`flex-grow align-items-center container-white ${
                    !isDesktop ? "transparent pt-0 mt-0" : "my-2 pt-2 pb-4"
                }`}
                rowClassName={classNames({ "justify-content-center": !enabledEcheqMassive })}
                gridClassName="form-content">
                <Col
                    xs={12}
                    md={isMobile || isNotFullsize ? 12 : 6}
                    lg={10}
                    className={classNames("container-white", { "px-3 pt-25": isDesktop })}>
                    <div className={!isDesktop && "d-flex flex-column"}>
                        <TabletSelectors
                            allDisabled={isSubmitting && !isDesktop}
                            isDesktop={isDesktop}
                            possibleOptions={options}
                            changeOption={changeOption}
                            optionSelected={option}
                            containerClass={classNames({ "my-3": !isDesktop, "echeq-tablet-selectors": isDesktop })}
                            buttonContainerClass={classNames({
                                "mx-0": !isDesktop,
                                "m-0": isDesktop,
                            })}
                            buttonClass="align-self-flex-end m-0"
                            leftButtons
                        />
                    </div>
                </Col>
                <Col
                    xs={12}
                    md={isMobile || isNotFullsize ? 12 : 6}
                    lg={2}
                    className={classNames(
                        "d-flex align-items-center justify-content-end",
                        { "px-3 pt-25": isDesktop },
                        { "my-3": !isDesktop },
                    )}>
                    <Button
                        block
                        bsStyle="outline"
                        className="px-25 py-15 m-0 font-light right width-3 mh-auto height-2 text-transform-none"
                        label={
                            showForm
                                ? "global.hide.filter"
                                : showCleanFilterButton
                                ? "echeq.btn.filter.active"
                                : "global.see.filter"
                        }
                        image={showForm ? "images/eye-off.svg" : "images/eye.svg"}
                        onClick={() => setEcheqsState({ showForm: !showForm })}
                    />
                    {showCleanFilterButton && (
                        <Button
                            label="echeq.clean.filters"
                            className="clean-filters-label btn-clear-filter font-light mr-1 mt-0 pt-1"
                            onClick={() => cleanFilters(option, "TODOS")}
                            image="images/cross.svg"
                            imageStyle="circle-cross"
                        />
                    )}
                </Col>
                {showSnackbarFiltred && !isDesktop && (
                    <Container
                        className={
                            isIOS
                                ? "hint-snackbar-mobile p-relative echeq__snackbar-ios filted p-3"
                                : "hint-snackbar-mobile p-relative echeq__snackbar filted p-3"
                        }>
                        <Col xs={12}>
                            <Col xs={10} className="mt-1">
                                <span>
                                    {i18n.get(multipleSelection ? "echeqs.multiple.active" : "echeq.filter.active")}
                                </span>
                            </Col>
                            <Col xs={2}>
                                <button
                                    type="button"
                                    className="btn-link mt-1"
                                    aria-label="Close"
                                    onClick={() => setEcheqsState({ showSnackbarFiltred: false })}>
                                    <Image
                                        src="images/cross-mobile-blue.svg"
                                        styles={{ width: "20px", height: "20px" }}
                                    />
                                </button>
                            </Col>
                        </Col>
                    </Container>
                )}
            </Container>
            {getSelectedOption(option) === "massive" && showForm && (
                <Container
                    className={`flex-grow align-items-center container-white ${
                        !isDesktop ? "transparent pt-0 mt-0" : "p-3"
                    }`}
                    rowClassName={classNames({ "justify-content-center": !enabledEcheqMassive })}
                    gridClassName="form-content">
                    <>
                        <Col
                            xs={12}
                            md={3}
                            lg={isNotFullsize ? 3 : 2}
                            className={classNames({ "px-2 pb-2": isDesktop })}>
                            <Field
                                component={Selector}
                                hidePlaceholder
                                options={[defaultStatusOption, ...states]}
                                onCustomChange={cleanSelectedEcheqs}
                                customOnChange
                                idForm={FORM_ID}
                                name="status"
                                isRequired
                                value={values?.status}
                                formGroupClassName="my-0"
                                className="ellipsis-text"
                                searchable={isDesktop}
                            />
                        </Col>
                        <Col xs={12} md={3} lg={isNotFullsize ? 3 : 2} className={classNames({ "px-2": isDesktop })}>
                            <Field
                                component={TextField}
                                hidePlaceholder
                                idForm={FORM_ID}
                                name="number"
                                type="text"
                                formGroupClassName="my-0"
                                pattern="\d*"
                                inputmode="numeric"
                                maxLength={8}
                                labelText={`${FORM_ID}.batchId.label`}
                            />
                        </Col>
                    </>
                </Container>
            )}
            {showForm ? (
                <Container
                    className={classNames("flex-grow align-items-center py-3", {
                        "container-white px-3": isDesktop,
                    })}
                    rowClassName={classNames("justify-content-left", {
                        "mb-5": getSelectedOption(option) === "massive",
                    })}
                    gridClassName="form-content">
                    {getSelectedOption(option) === "massive" ? (
                        <>
                            {!isDesktop ? (
                                <Col xs={12}>
                                    <I18n
                                        id="echeq.batch.table.header.processDate"
                                        upperCase
                                        componentProps={{ className: "control-label" }}
                                    />
                                </Col>
                            ) : (
                                undefined
                            )}
                            <Col xs={6} md={3} lg={isNotFullsize ? 3 : 2} className={classNames({ "px-2": isDesktop })}>
                                <Field
                                    idField="createdFrom"
                                    formikValueFirst
                                    component={Date}
                                    hidePlaceholder
                                    idForm={FORM_ID}
                                    name="createdFrom"
                                    customKey={!isDesktop ? "echeqs.from.label" : `${FORM_ID}.processDateFrom.label`}
                                    maxDate={values.createdTo}
                                    minDate={getLimitDate(-(limitPeriod * 30), values?.createdTo)}
                                    formGroupClassName={classNames("mr-1 my-0 w-100")}
                                />
                            </Col>
                            <Col xs={6} md={3} lg={isNotFullsize ? 3 : 2} className={classNames({ "px-2": isDesktop })}>
                                <Field
                                    idField="createdTo"
                                    formikValueFirst
                                    component={Date}
                                    hidePlaceholder
                                    idForm={FORM_ID}
                                    name="createdTo"
                                    customKey={!isDesktop ? "echeqs.to.label" : `${FORM_ID}.processDateTo.label`}
                                    minDate={values.createdFrom}
                                    maxDate={getLimitDate(0)}
                                    formGroupClassName={classNames("w-100 my-0")}
                                />
                            </Col>
                            <Col xs={12} md={2} lg={2} className={classNames({ "px-2": isDesktop })}>
                                <Field
                                    idForm={FORM_ID}
                                    component={AmountField}
                                    name="amountFrom"
                                    formGroupClassName="my-0"
                                    clearable={false}
                                    maxLength={17}
                                    isFocused
                                    plainValue
                                    hideCurrency
                                    hidePlaceholder
                                    fixedDecimalScale
                                />
                            </Col>
                            <Col xs={12} md={2} lg={2} className={classNames({ "px-2": isDesktop })}>
                                <Field
                                    idForm={FORM_ID}
                                    component={AmountField}
                                    name="amountTo"
                                    formGroupClassName="my-0"
                                    clearable={false}
                                    maxLength={17}
                                    isFocused
                                    plainValue
                                    hideCurrency
                                    hidePlaceholder
                                    fixedDecimalScale
                                />
                            </Col>
                            <Col
                                xs={12}
                                md={2}
                                lg={isNotFullsize ? 2 : 1}
                                className={classNames("ml-auto my-0 py-0", { "align-items-end px-2": isDesktop })}>
                                <Button
                                    type="submit"
                                    label="echeq.filter"
                                    className={classNames("w-100 mr-md-0 mb-0 text-capitalize", {
                                        "my-0 filter-button": isDesktop,
                                        "my-4": !isDesktop,
                                    })}
                                    bsStyle="primary"
                                    loading={isSubmitting}
                                    disabled={idSelectorError || numberSelectorError}
                                    image="images/echeq__filter-icon.svg"
                                    imageStyle="mr-2"
                                />
                            </Col>
                        </>
                    ) : (
                        <>
                            <Row className="w-100 mb-3 m-0">
                                {getSelectedOption(option) === "received" &&
                                    configUtils.getBoolean("backend.show.ActionsFilter.functionalities") && (
                                        <Col
                                            xs={12}
                                            md={7}
                                            lg={isNotFullsize ? 2 : 3}
                                            className={classNames({ "px-2 pb-2": isDesktop, "mb-3": !isDesktop })}>
                                            <Field
                                                component={Selector}
                                                hidePlaceholder
                                                options={echeqsActions.map((obj) => ({
                                                    ...obj,
                                                    label: i18n.get(obj.label),
                                                }))}
                                                onCustomChange={selectAction}
                                                customOnChange
                                                idForm={FORM_ID}
                                                name="action"
                                                isRequired
                                                value={values?.action}
                                                formGroupClassName="my-0"
                                                searchable={isDesktop}
                                            />
                                        </Col>
                                    )}
                                <EcheqsDatesSelectors
                                    values={values}
                                    isNotFullsize={isNotFullsize}
                                    isSmallDesktop={isSmallDesktop}
                                    isDesktop={isDesktop}
                                    width={width}
                                />
                            </Row>
                            <Col xs={12} md={4} lg={3} className={classNames({ "px-2": isDesktop })}>
                                {getSelectedOption(option) === "received" && (
                                    <Field
                                        component={TextField}
                                        idForm={FORM_ID}
                                        name="receiver"
                                        type="text"
                                        pattern="\d*"
                                        inputmode="numeric"
                                        formGroupClassName="my-0"
                                        maxLength={11}
                                    />
                                )}
                                {getSelectedOption(option) !== "received" && (
                                    <Field
                                        component={TextField}
                                        idForm={FORM_ID}
                                        name="emitted"
                                        type="text"
                                        pattern="\d*"
                                        inputmode="numeric"
                                        formGroupClassName="my-0"
                                        maxLength={11}
                                    />
                                )}
                            </Col>
                            <Col xs={12} md={4} lg={3} className={classNames({ "px-2 pb-2": isDesktop })}>
                                <Field
                                    component={Selector}
                                    hidePlaceholder
                                    options={[defaultStatusOption, ...echeqsStates]}
                                    onCustomChange={cleanSelectedEcheqs}
                                    customOnChange
                                    idForm={FORM_ID}
                                    name="status"
                                    isRequired
                                    buttonInfo={
                                        configUtils.getBoolean("backend.show.ActionsFilter.functionalities") && (
                                            <Tooltip
                                                id="hint_three"
                                                message={configUtils.get("status.echeqReceived.Tooltip", "")}
                                            />
                                        )
                                    }
                                    onClickHint={handleHintDisplay}
                                    activeHint={activeHint}
                                    value={values?.status}
                                    formGroupClassName="my-0"
                                    searchable={isDesktop}
                                />
                            </Col>
                            <Col xs={12} md={4} lg={2} className={classNames({ "px-2": isDesktop })}>
                                <Field
                                    component={TextField}
                                    hidePlaceholder
                                    idForm={FORM_ID}
                                    name="grouper"
                                    type="text"
                                    formGroupClassName="my-0"
                                    pattern="\d*"
                                    inputmode="numeric"
                                    maxLength={8}
                                />
                            </Col>
                            <Col xs={12} md={5} lg={2} className={classNames({ "px-2": isDesktop })}>
                                {nrsIdsFiltersEnabled ? (
                                    <Field
                                        idField="idSelector"
                                        component={IdEcheqSelector}
                                        hidePlaceholder
                                        idForm={FORM_ID}
                                        name="number"
                                        type="text"
                                        formGroupClassName="my-0"
                                        echeqNumber
                                        dispatch={dispatch}
                                        isDesktop={isDesktop}
                                        idTooltip="hint_one"
                                        cleanFilter={cleanFilter}
                                    />
                                ) : (
                                    <Field
                                        component={TextField}
                                        hidePlaceholder
                                        idForm={FORM_ID}
                                        name="number"
                                        type="text"
                                        formGroupClassName="my-0"
                                        dispatch={dispatch}
                                        isDesktop={isDesktop}
                                        pattern="\d*"
                                        inputmode="numeric"
                                        maxLength={8}
                                    />
                                )}
                            </Col>
                            <Col xs={12} md={5} lg={2} className={classNames({ "px-2": isDesktop })}>
                                {nrsIdsFiltersEnabled ? (
                                    <>
                                        <Field
                                            idField="idSelector"
                                            component={IdEcheqSelector}
                                            hidePlaceholder
                                            idForm={FORM_ID}
                                            name="id"
                                            type="text"
                                            formGroupClassName="my-0"
                                            echeqId
                                            isDesktop={isDesktop}
                                            idTooltip="hint_two"
                                            cleanFilter={cleanFilter}
                                        />
                                        {isAndroid && (
                                            <Field component="input" type="text" className="opacity-0 height-0" />
                                        )}
                                    </>
                                ) : (
                                    <Field
                                        component={TextField}
                                        hidePlaceholder
                                        idForm={FORM_ID}
                                        name="id"
                                        type="text"
                                        formGroupClassName="my-0"
                                        isDesktop={isDesktop}
                                        inputmode="text"
                                        maxLength={15}
                                    />
                                )}
                            </Col>
                            <Col xs={12} className="px-2">
                                <Col
                                    xs={12}
                                    md={10}
                                    lg={8}
                                    className={classNames({ "px-0": isDesktop, "px-2": !isDesktop })}>
                                    <div className="d-flex">
                                        <Field
                                            component={Selector}
                                            formGroupClassName="mb-0"
                                            renderAs="radio"
                                            inLineControl
                                            name="ownership"
                                            options={[
                                                {
                                                    id: `TODOS`,
                                                    label: <I18n id={`${FORM_ID}.no.diference`} />,
                                                },
                                                {
                                                    id: `TITULAR`,
                                                    label: (
                                                        <div className="d-flex">
                                                            <I18n id={`${FORM_ID}.owner`} />{" "}
                                                            <Image
                                                                src="images/ownershipEcheqsNew.svg"
                                                                className="ml-2"
                                                            />
                                                        </div>
                                                    ),
                                                },
                                                {
                                                    id: `NO TITULAR`,
                                                    label: <I18n id={`${FORM_ID}.not.owner`} />,
                                                },
                                            ]}
                                        />
                                    </div>
                                </Col>
                            </Col>
                            <Col lg={12} className="echeqs__filter-select d-flex space-between align-items-center pr-0">
                                {isDesktop && (
                                    <EcheqsFilterSelect
                                        options={option === "received.echeqs" ? orderByReceived : orderByEmitted}
                                        radioOptions={radioOptions}
                                        optionType={option === "received.echeqs"}
                                        isDesktop={isDesktop}
                                        columnFilter={{
                                            filterSelected: filterOrder.column,
                                            directionFilter: filterOrder.direction,
                                        }}
                                        changeFilter={changeFilterFunc}
                                        cleanFilter={cleanFilter}
                                        setCleanFilter={(value) => setEcheqsState({ cleanFilter: value })}
                                    />
                                )}
                                <Col
                                    xs={12}
                                    md={2}
                                    lg={2}
                                    className={classNames("d-flex my-0 p-0", {
                                        "align-self-end px-2": isDesktop,
                                        "pr-3": !isDesktop,
                                    })}>
                                    <Button
                                        type="submit"
                                        label="echeq.filter"
                                        className={classNames("w-100 mr-md-0 mb-0 text-capitalize", {
                                            "my-0 filter-button": isDesktop,
                                            "my-4": !isDesktop,
                                        })}
                                        bsStyle="primary"
                                        loading={isSubmitting}
                                        disabled={idSelectorError || numberSelectorError}
                                        image="images/echeq__filter-icon.svg"
                                        imageStyle="mr-2"
                                    />
                                </Col>
                            </Col>
                        </>
                    )}
                </Container>
            ) : (
                <></>
            )}
        </Form>
    );
}

EcheqsFilters.propTypes = {
    dispatch: func.isRequired,
    values: shape({}).isRequired,
    setValues: func.isRequired,
    handleSubmit: func.isRequired,
    isSubmitting: bool.isRequired,
    setSubmitting: func.isRequired,
    option: string.isRequired,
    cleanFilter: bool.isRequired,
    changeFilterFunc: func.isRequired,
    filterOrder: string.isRequired,
    showSnackbarFiltred: bool.isRequired,
    setEcheqsState: func.isRequired,
    selectAll: func.isRequired,
    multipleSelection: bool.isRequired,
    enabledEcheqMassive: bool.isRequired,
    showForm: bool.isRequired,
    showCleanFilterButton: bool.isRequired,
    stateList: arrayOf(shape({})).isRequired,
    idSelectorError: string.isRequired,
    numberSelectorError: string.isRequired,
    activeHint: string.isRequired,
    width: number.isRequired,
    isSmallDesktop: bool.isRequired,
    isDesktop: bool.isRequired,
    isMobile: bool.isRequired,
};

export default EcheqsFilters;
