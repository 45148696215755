/* eslint-disable react/no-did-update-set-state */
/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Field, Form, withFormik } from "formik";
import Col from "react-bootstrap/lib/Col";
import Row from "react-bootstrap/lib/Row";
import { bool, func, shape, string, arrayOf, number } from "prop-types";
import { push } from "react-router-redux/actions";
import moment from "moment";
import Yup from "yup";

import { selectors as sessionSelectors } from "reducers/session";
import { selectors as accountsSelectors } from "reducers/accounts";
import { selectors as thirdPaymentSelector, actions as thirdPaymentActions } from "reducers/thirdPayment";
import { actions as multilineFileActions } from "reducers/formFields/multilineFile";
import { actions as transactionsActions } from "reducers/transactions";

import { statuses, retailStatuses } from "util/thirdPayment";
import * as i18n from "util/i18n";

import Selector from "pages/_components/fields/formik/Selector";
import Date from "pages/_components/fields/DateField";
import Container from "pages/_components/Container";
import Button from "pages/_components/Button";
import Notification from "pages/_components/Notification";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import Image from "pages/_components/Image";
import PageLoading from "pages/_components/PageLoading";
import MainContainer from "pages/_components/MainContainer";
import Pagination from "pages/_components/pagination/Pagination";
import ContextMenu from "pages/_components/ContextMenu";
import DownloadButton from "pages/_components/DownloadButton";
import AmountField from "pages/_components/fields/formik/AmountField";
import TransactionThirdPaymentTable from "./_components/TransactionThirdPaymentTable";

const FORM_ID = "payments.payThirdParties";

class PayThirdPartiesList extends Component {
    state = {
        selectedDateFrom: null,
        isSupplierPayments: true,
        showForm: false,
        showSnackbarFiltered: false,
        hasChanged: false,
    };

    static propTypes = {
        dispatch: func.isRequired,
        dateFrom: shape({}).isRequired,
        dateTo: shape({}).isRequired,
        amountFrom: shape({}).isRequired,
        amountTo: shape({}).isRequired,
        values: shape({}).isRequired,
        status: string.isRequired,
        isDesktop: bool.isRequired,
        isSmallDesktop: bool.isRequired,
        handleSubmit: func.isRequired,
        requests: arrayOf(shape({})).isRequired,
        latestPageNumber: number.isRequired,
        latestTotalPages: number.isRequired,
        fetching: bool.isRequired,
        isFirstFetching: bool.isRequired,
        activeEnvironment: shape({
            permissions: shape({
                newThirdPayment: bool,
            }),
        }).isRequired,
        fetchingDownload: bool.isRequired,
        setFieldValue: func.isRequired,
        filtersBack: shape({}).isRequired,
        location: shape({}).isRequired,
    };

    componentDidMount() {
        const {
            dispatch,
            amountFrom,
            amountTo,
            dateFrom,
            dateTo,
            status,
            filtersBack,
            setFieldValue,
            latestPageNumber,
            latestTotalPages,
            location,
        } = this.props;
        dispatch(transactionsActions.saveTransactionRoute(location?.pathname));
        const typePayment = this.paymentSelected();

        if (filtersBack) {
            setFieldValue("status", null);
            setFieldValue("dateFrom", null);
            setFieldValue("dateTo", null);
            setFieldValue("amountFrom", null);
            setFieldValue("amountTo", null);

            if (filtersBack.typeL === "PP") {
                this.setState({
                    isSupplierPayments: true,
                });
                dispatch(thirdPaymentActions.setTypeSU());
            } else {
                this.setState({
                    isSupplierPayments: false,
                });
                dispatch(thirdPaymentActions.setTypeH());
            }

            dispatch(
                thirdPaymentActions.loadListThirdRequest({
                    dateFrom: null,
                    dateTo: null,
                    amountFrom: null,
                    amountTo: null,
                    status: null,
                    typePayment: filtersBack.typeL,
                    latestPageNumber,
                    latestTotalPages,
                    isFirstFetching: true,
                }),
            );
        } else {
            dispatch(
                thirdPaymentActions.loadListThirdRequest({
                    dateFrom,
                    dateTo,
                    amountFrom,
                    amountTo,
                    status,
                    typePayment,
                    latestPageNumber: "1",
                    latestTotalPages: "1",
                    isFirstFetching: true,
                }),
            );
        }
    }

    componentDidUpdate = (prevProps) => {
        const { values } = this.props;
        const { amountFrom, amountTo, status, dateFrom, dateTo } = values;
        if (
            amountFrom !== prevProps.values.amountFrom ||
            amountTo !== prevProps.values.amountTo ||
            status !== prevProps.values.status ||
            dateFrom !== prevProps.values.dateFrom ||
            dateTo !== prevProps.values.dateFrom
        ) {
            if (
                (amountFrom !== "" || amountTo !== "" || status !== "" || dateFrom !== null || dateTo !== null) &&
                !this.state.hasChanged
            ) {
                this.setState({ hasChanged: true });
            } else if ((amountFrom === null || amountTo === null || status === null) && this.state.hasChanged) {
                this.setState({ hasChanged: false });
            }
        }
    };

    paymentSelected = () => {
        const { isSupplierPayments } = this.state;
        if (!isSupplierPayments) {
            return "PH";
        }
        return "PP";
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(push("/desktop/"));
    };

    handleDownloadClick = (format) => {
        const { dispatch, requests } = this.props;
        requests.forEach((request) => {
            delete request.transaction.fullDescription;
        });
        dispatch(thirdPaymentActions.downloadListThirdPayment(format, requests));
    };

    handleClickNewPayment = () => {
        const { dispatch } = this.props;
        dispatch(thirdPaymentActions.clearData());
        dispatch(multilineFileActions.onFileRemoved());
        return dispatch(push("newPayment"));
    };

    rightContent = () => {
        const { isDesktop, isSmallDesktop, activeEnvironment, fetchingDownload } = this.props;

        const items = [];
        if (activeEnvironment.permissions.newThirdPayment) {
            items.push({
                label: "payments.payThirdParties.newpayment.button",
                onClick: () => this.handleClickNewPayment(),
            });
        }
        items.push({
            label: "payments.payThirdParties.download.button",
            onClick: () => this.handleDownloadClick("pdf"),
        });

        if (isDesktop && !isSmallDesktop) {
            return (
                <>
                    {activeEnvironment.permissions.newThirdPayment ? (
                        <Button
                            block={false}
                            onClick={() => this.handleClickNewPayment()}
                            label="payments.payThirdParties.newpayment.button"
                            bsStyle="primary"
                            className="cmf-button-width"
                        />
                    ) : (
                        undefined
                    )}
                    <DownloadButton
                        fetching={fetchingDownload}
                        handleClick={this.handleDownloadClick}
                        handleClickMessage="global.download"
                        csvDownload
                        xlsDownload
                        imageStyle="mr-2"
                    />
                </>
            );
        }

        return (
            <ContextMenu
                isDesktop={isDesktop}
                buttonClassName="align-self-center account-dropdown-header-font font-black-alpha ml-2"
                items={items}
            />
        );
    };

    renderEmptyState = () => {};

    handleChangeDateFrom = (selectedDate) => {
        this.setState({ selectedDateFrom: selectedDate });
    };

    changeOption = (option) => {
        const { dispatch, amountFrom, amountTo, dateFrom, dateTo, status, setFieldValue } = this.props;

        setFieldValue("status", "");
        setFieldValue("dateFrom", null);
        setFieldValue("dateTo", null);
        setFieldValue("amountFrom", "");
        setFieldValue("amountTo", "");

        if (this.state.hasChanged) {
            this.setState({ hasChanged: false });
        }

        const values = { amountFrom, amountTo, dateFrom, dateTo, status };
        if (option === "supplier") {
            this.setState({
                isSupplierPayments: true,
            });
            dispatch(thirdPaymentActions.setTypeSU());
            dispatch(thirdPaymentActions.setFiltersBack({ values, typeL: "PP" }));

            dispatch(
                thirdPaymentActions.loadListThirdRequest({
                    dateFrom,
                    dateTo,
                    amountFrom,
                    amountTo,
                    status,
                    typePayment: "PP",
                    latestPageNumber: "1",
                    latestTotalPages: "1",
                    isFirstFetching: true,
                }),
            );
        } else {
            this.setState({
                isSupplierPayments: false,
            });
            dispatch(thirdPaymentActions.setTypeH());
            dispatch(thirdPaymentActions.setFiltersBack({ values, typeL: "PH" }));

            dispatch(
                thirdPaymentActions.loadListThirdRequest({
                    dateFrom,
                    dateTo,
                    amountFrom,
                    amountTo,
                    status,
                    typePayment: "PH",
                    latestPageNumber: "1",
                    latestTotalPages: "1",
                    isFirstFetching: true,
                }),
            );
        }
    };

    renderCloseButton = () => (
        <button
            type="button"
            className="btn-link mt-1"
            aria-label="Close"
            onClick={() => this.setState({ showSnackbarFiltered: false })}>
            <Image src="images/cross-mobile-blue.svg" styles={{ width: "20px", height: "20px" }} />
        </button>
    );

    renderHeader = () => {
        const { isDesktop } = this.props;
        const { showSnackbarFiltered } = this.state;

        if (isDesktop) {
            return (
                <>
                    <Head onBack={this.handleBack} />
                    <Head
                        title="payments.payThirdParties.title"
                        handleClickMessage="global.download"
                        rightContent={() => this.rightContent()}
                    />
                </>
            );
        }

        return (
            <>
                <Head
                    headerClassName="blue-main-header-mobile"
                    titleClassName="w-100 align-center"
                    onBack={this.handleBack}
                    title="payments.payThirdParties.title"
                    rightContent={() => this.rightContent()}
                />

                {!isDesktop && showSnackbarFiltered && (
                    <Container className="thirdPaymentList hint-snackbar-mobile filted py-4">
                        <Col xs={12}>
                            <Col xs={10} className="justify-content-center text-align-center mt-2">
                                <span>{i18n.get("echeq.filter.active")}</span>
                            </Col>
                            <Col xs={2}>{this.renderCloseButton()}</Col>
                        </Col>
                    </Container>
                )}
            </>
        );
    };

    getDateFilter = () => {
        const { dateFrom, dateTo, isDesktop, isSmallDesktop, values } = this.props;
        const { selectedDateFrom } = this.state;

        const formName = isDesktop ? FORM_ID : `${FORM_ID}.mobile`;

        const dateFromField = () => (
            <Field
                idField="dateFrom"
                component={Date}
                idForm={formName}
                name="dateFrom"
                hidePlaceholder
                selectsStart
                startDate={new Date()}
                endDate={dateTo}
                handleChange={this.handleChangeDateFrom}
                handleCustomChange={(value, form) => {
                    form.setFieldValue("dateFrom", value);
                }}
                autoComplete="off"
                errorClassName="p-absolute"
                value={values?.dateFrom}
            />
        );

        const dateToField = () => (
            <Field
                idField="dateTo"
                component={Date}
                hidePlaceholder
                idForm={formName}
                name="dateTo"
                selectsEnd
                startDate={dateFrom}
                endDate={dateTo}
                minDate={selectedDateFrom}
                handleCustomChange={(value, form) => {
                    form.setFieldValue("dateTo", value);
                }}
                autoComplete="off"
                errorClassName="p-absolute"
                value={values?.dateTo}
            />
        );

        if (isDesktop) {
            return (
                <>
                    <Col xs={12} lg={isSmallDesktop ? 4 : 2} md={6} className="px-1">
                        {dateFromField()}
                    </Col>
                    <Col xs={12} lg={isSmallDesktop ? 4 : 2} md={6} className="px-1">
                        {dateToField()}
                    </Col>
                </>
            );
        }

        const labelTitleDate = `${FORM_ID}.mobile.date.label`;
        return (
            <>
                <Col xs={12} className="px-4">
                    <Row className="justify-content-left">
                        <I18n id={labelTitleDate} />
                    </Row>
                </Col>

                <Col xs={12} className="px-2">
                    <Col xs={6} className="pl-0">
                        {dateFromField()}
                    </Col>
                    <Col xs={6} className="pr-0">
                        {dateToField()}
                    </Col>
                </Col>
            </>
        );
    };

    getAmountFilter = () => {
        const { isDesktop, isSmallDesktop } = this.props;

        const amountFromField = () => (
            <Field
                idForm={FORM_ID}
                component={AmountField}
                name="amountFrom"
                formGroupClassName="my-0"
                clearable={false}
                maxLength={17}
                isFocused
                plainValue
                hideCurrency
                hidePlaceholder
                fixedDecimalScale
            />
        );

        const amountToField = () => (
            <Field
                idForm={FORM_ID}
                component={AmountField}
                name="amountTo"
                formGroupClassName="my-0"
                clearable={false}
                maxLength={17}
                isFocused
                plainValue
                hideCurrency
                hidePlaceholder
                fixedDecimalScale
            />
        );

        if (isDesktop) {
            return (
                <>
                    <Col lg={isSmallDesktop ? 4 : 2} md={6} xs={12} className="px-1">
                        {amountFromField()}
                    </Col>
                    <Col lg={isSmallDesktop ? 4 : 2} md={6} xs={12} className="px-1">
                        {amountToField()}
                    </Col>
                </>
            );
        }
        return (
            <>
                <Col xs={6} className="px-2 ">
                    {amountFromField()}
                </Col>
                <Col xs={6} className="px-2">
                    {amountToField()}
                </Col>
            </>
        );
    };

    fetchNextTransactions = (pageNumber) => {
        const {
            dispatch,
            amountFrom,
            amountTo,
            dateFrom,
            dateTo,
            latestTotalPages,
            values: { status },
        } = this.props;
        const typePayment = this.paymentSelected();
        dispatch(
            thirdPaymentActions.loadListThirdRequest({
                dateFrom,
                dateTo,
                amountFrom,
                amountTo,
                status,
                typePayment,
                pageNumber,
                latestTotalPages,
                isFirstFetching: false,
            }),
        );
    };

    fetchMoreTransactions = () => {
        const {
            dispatch,
            amountFrom,
            amountTo,
            dateFrom,
            dateTo,
            status,
            latestTotalPages,
            latestPageNumber,
        } = this.props;
        const typePayment = this.paymentSelected();

        dispatch(
            thirdPaymentActions.loadMoreListThirdRequest({
                dateFrom,
                dateTo,
                amountFrom,
                amountTo,
                status,
                typePayment,
                pageNumber: latestPageNumber + 1,
                latestTotalPages,
            }),
        );
    };

    chargeListByScheme = () => {
        const { activeEnvironment } = this.props;

        if (activeEnvironment?.administrationScheme === "simple") {
            return [...retailStatuses];
        }

        return [...statuses];
    };

    render() {
        const {
            values,
            isDesktop,
            isSmallDesktop,
            handleSubmit,
            latestPageNumber,
            latestTotalPages,
            fetching,
            requests,
            isFirstFetching,
        } = this.props;
        const { isSupplierPayments, showForm } = this.state;
        const defaultOption = { value: null, label: "TODOS" };
        const { hasChanged } = this.state;

        const statusList = this.chargeListByScheme();

        return (
            <>
                <Notification scopeToShow="forms" />
                <div className="admin-detail-head w-100 px-0 mb-0">{this.renderHeader()}</div>
                <Form
                    className="w-100"
                    onSubmit={async (e) => {
                        await handleSubmit(e);
                        this.setState((prev) => ({
                            ...prev,
                            showForm: prev.showForm,
                            showSnackbarFiltered: !prev.showSnackbarFiltered,
                        }));
                    }}>
                    <Container className="container-white py-3 align-items-center">
                        <Col xs={12} className="d-flex justify-content-start align-item-center w-100">
                            <div className="d-flex align-item-center w-100">
                                <Button
                                    className="btn-outline btn-regular chip-selector-btn"
                                    block={false}
                                    image={isSupplierPayments ? "images/check.svg" : ""}
                                    style={{
                                        backgroundColor: isSupplierPayments ? "#f2f8fd" : "",
                                        borderColor: isSupplierPayments ? "#0071ce" : "#ccc",
                                        color: isSupplierPayments ? "#0071ce" : "#666",
                                    }}
                                    onClick={() => this.changeOption("supplier")}
                                    label="payments.payThirdParties.newPayment.supplierPayments.label"
                                />
                                <Button
                                    className="btn-outline btn-regular chip-selector-btn"
                                    block={false}
                                    name="salary"
                                    image={!isSupplierPayments ? "images/check.svg" : ""}
                                    style={{
                                        backgroundColor: !isSupplierPayments ? "#f2f8fd" : "",
                                        borderColor: !isSupplierPayments ? "#0071ce" : "#ccc",
                                        color: !isSupplierPayments ? "#0071ce" : "#666",
                                    }}
                                    onClick={() => this.changeOption("salary")}
                                    label="payments.payThirdParties.newPayment.salaryPayments.label"
                                />
                            </div>
                            {isDesktop && (
                                <div className="hide-filter__btn-content mr-3">
                                    <Button
                                        block
                                        bsStyle="outline"
                                        className="hide-filter__btn m-0"
                                        label={
                                            showForm
                                                ? "global.hide.filter"
                                                : `${hasChanged ? "global.filter.active" : "global.see.filter"}`
                                        }
                                        image={showForm ? "images/eye-off.svg" : "images/eye.svg"}
                                        onClick={() => this.setState((prev) => ({ ...prev, showForm: !prev.showForm }))}
                                    />
                                </div>
                            )}
                        </Col>
                    </Container>
                    {!isDesktop && (
                        <Col xs={12} className="d-flex justify-content-end pr-0 py-3">
                            <div className="hide-filter__btn-content">
                                <Button
                                    block
                                    bsStyle="outline"
                                    className="hide-filter__btn m-0"
                                    label={
                                        showForm
                                            ? "global.hide.filter"
                                            : `${hasChanged ? "global.filter.active" : "global.see.filter"}`
                                    }
                                    image={showForm ? "images/eye-off.svg" : "images/eye.svg"}
                                    onClick={() => this.setState((prev) => ({ ...prev, showForm: !prev.showForm }))}
                                />
                            </div>
                        </Col>
                    )}
                    {showForm && (
                        <Container
                            className={`flex-grow mt-4 ${isDesktop ? "container-white pt-3 mb-0 pb-0 px-3" : "px-2"}`}
                            gridClassName="form-content">
                            <Col xs={12} md={12} lg={isSmallDesktop ? 4 : 2} className="px-1">
                                <Field
                                    component={Selector}
                                    options={[defaultOption, ...statusList]}
                                    idForm={FORM_ID}
                                    name="status"
                                    isRequired
                                    value={values?.status || defaultOption}
                                    style={{ paddingTop: "12px", paddingBottom: "12px" }}
                                    className="ellipsis-text"
                                    searchable={isDesktop}
                                />
                            </Col>

                            {this.getDateFilter()}

                            {this.getAmountFilter()}

                            <Col
                                lg={isSmallDesktop ? 4 : 2}
                                md={6}
                                xs={12}
                                className="px-1 align-self-flex-start mt-28">
                                <div className="form-group mb-2">
                                    <Button
                                        label={
                                            isDesktop
                                                ? "payments.payThirdParties.button.search"
                                                : "payments.payThirdParties.button.filter"
                                        }
                                        bsStyle="primary"
                                        type="submit"
                                        loading={fetching}
                                    />
                                </div>
                            </Col>
                        </Container>
                    )}
                </Form>

                {isDesktop ? (
                    <PageLoading className="line-loader" loading={fetching}>
                        <MainContainer
                            className={`debin w-100 ${isDesktop && "overflow-hidden"}`}
                            showLoader={false}
                            showChildrenWithLoader>
                            <div>
                                <TransactionThirdPaymentTable
                                    fetching={fetching}
                                    requests={requests}
                                    isDesktop={isDesktop}
                                    option={isSupplierPayments}
                                />
                                <div className="d-flex w-100 justify-content-end ">
                                    <Pagination
                                        totalPages={latestTotalPages}
                                        pageNumber={latestPageNumber}
                                        action={this.fetchNextTransactions}
                                    />
                                </div>
                            </div>
                        </MainContainer>
                    </PageLoading>
                ) : (
                    <MainContainer showLoader={isFirstFetching} showChildrenWithLoader>
                        <div className="above-the-fold">
                            <TransactionThirdPaymentTable
                                requests={requests}
                                latestPageNumber={latestPageNumber}
                                latestTotalPages={latestTotalPages}
                                isDesktop={isDesktop}
                                option={isSupplierPayments}
                                fetchMoreTransactions={this.fetchMoreTransactions}
                            />
                        </div>
                    </MainContainer>
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    loggedUser: sessionSelectors.getUser(state),
    transaction: thirdPaymentSelector.getTransaction(state),
    accounts: accountsSelectors.getAccounts(state),
    typeLabel: thirdPaymentSelector.getTypeSelect(state),
    requests: thirdPaymentSelector.getTransactionLinesStatus(state),
    latestPageNumber: thirdPaymentSelector.getLatestPageNumber(state),
    latestTotalPages: thirdPaymentSelector.getLatestTotalPages(state),
    fetching: thirdPaymentSelector.getFetching(state),
    isFirstFetching: thirdPaymentSelector.isFirstFetching(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    fetchingDownload: thirdPaymentSelector.getFetchingDownload(state),
    filtersBack: thirdPaymentSelector.getFiltersBack(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: true,
        validateOnBlur: false,
        mapPropsToValues: (props) => ({
            dateFrom: props.isDesktop ? null : moment().subtract(6, "months"),
            dateTo: props.isDesktop ? null : moment(new Date()),
        }),
        validationSchema: () =>
            Yup.lazy((values) =>
                Yup.object().shape({
                    dateFrom: values.dateTo
                        ? Yup.date()
                              .nullable()
                              .max(values.dateTo, i18n.get(`${FORM_ID}.dateFrom.error`))
                        : Yup.date().nullable(),
                    dateTo: values.dateFrom
                        ? Yup.date()
                              .nullable()
                              .min(values.dateFrom, i18n.get(`${FORM_ID}.dateTo.error`))
                        : Yup.date().nullable(),
                    amountFrom: values.amountTo
                        ? Yup.number()
                              .typeError(i18n.get(`${FORM_ID}.amount.amountFrom.required`))
                              .required(i18n.get(`${FORM_ID}.amount.amountFrom.required`))
                              .max(values.amountTo, i18n.get(`${FORM_ID}.amount.amountFrom.error`))
                        : Yup.string().nullable(),
                    amountTo: values.amountFrom
                        ? Yup.number()
                              .typeError(i18n.get(`${FORM_ID}.amount.amountTo.required`))
                              .required(i18n.get(`${FORM_ID}.amount.amountTo.required`))
                              .min(values.amountFrom, i18n.get(`${FORM_ID}.amount.amountTo.error`))
                        : Yup.string().nullable(),
                }),
            ),
        handleSubmit: (values, formikBag) => {
            const { dispatch, typeLabel } = formikBag.props;
            let typeL;
            const { amountFrom, amountTo, dateFrom, dateTo, status } = values;
            if (typeLabel) {
                typeL = "PP";
            } else {
                typeL = "PH";
            }

            const valuesFiltersBack = { values, typeL };

            dispatch(thirdPaymentActions.setFiltersBack(valuesFiltersBack));

            dispatch(
                thirdPaymentActions.loadListThirdRequest({
                    dateFrom,
                    dateTo,
                    amountFrom,
                    amountTo,
                    status,
                    typePayment: typeL,
                    latestPageNumber: "1",
                    latestTotalPages: "1",
                }),
            );
        },
    }),
)(PayThirdPartiesList);
