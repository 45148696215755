import React, { useEffect, useState } from "react";
import { oneOfType, array, string, func, bool, shape } from "prop-types";
import classNames from "classnames";
import Yup from "yup";
import { compose } from "redux";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { withFormik, Field } from "formik";
import isEmpty from "lodash/isEmpty";
import { Col } from "react-bootstrap";
import { isIOS } from "react-device-detect";
import moment from "moment";

import { actions as cedipActions, selectors as cedipSelectors } from "reducers/cedip";
import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";
import { selectors as sessionSelectors } from "reducers/session";

import DrawerModal from "pages/_components/drawer/DrawerModal";
import Productselector from "pages/forms/_components/_fields/Productselector";
import Head from "pages/_components/Head";
import Notification from "pages/_components/Notification";
import PageLoading from "pages/_components/PageLoading";
import CedipCard from "pages/cedip/_components/CedipCard";
import GeneralMsg from "pages/_components/GeneralMsg";
import Container from "pages/_components/Container";
import Button from "pages/_components/Button";
import TabletSelectors from "pages/_components/TabletSelectors";
import I18n from "pages/_components/I18n";
import Hint from "pages/_components/hints/Hint";

import * as i18n from "util/i18n";
import * as configUtil from "util/config";
import { getLimitDate } from "util/date";
import CedipFilters from "./_components/CedipFilters";

const FORM_ID = "deposits.cedip";
/* eslint-disable no-unused-vars */
const CEDIP_PROPIO = "owned.cedips";
const CEDIP_RECIBIDO = "received.cedips";
/* eslint-disable no-unused-vars */
function CedipList({
    accounts,
    user,
    dispatch,
    match,
    filters,
    cedips,
    fetching,
    fetchingDownload,
    isDesktop,
    isSmallDesktop,
    values,
    recievedCedipSelected,
    ownCedipSelected,
    setValues,
    showForm,
    setErrors,
    activeEnvironment,
}) {
    const [cedipState, setCedipState] = useState({
        filterOrder: {
            column: "",
            direction: "",
        },
        cleanFilter: false,
        showCleanFilterButton: false,
        showDrawer: false,
        cedipToAccept: {},
        cedips: {},
        cedipsList: {},
    });

    const maxBoxDisplay = configUtil.getInteger("product.list.maxBoxDisplay", 5);
    const productAsCard = cedips.length < maxBoxDisplay;
    const rightContentResult = isEmpty(cedips) && !activeEnvironment.permissions.cedip ? <div /> : null;
    const cedipInformationBox = configUtil.get("frontend.cedip.information.text");
    const setShowForm = (showFormProp) => dispatch(cedipActions.setShowForm(showFormProp));
    const [buttonClicked, setButtonClicked] = useState(false);

    useEffect(() => {
        if (!fetching && isIOS) {
            window.scrollTo(0, 1);
        }
    }, [fetching]);

    useEffect(() => {
        let newFilters = filters;
        if (match?.params?.cedipId) {
            newFilters = {
                ...newFilters,
                idCedip: match?.params?.cedipId,
                orderColumName: filters.orderColumName,
                orderDirection: filters.orderDirection,
                status: filters.status,
            };
        }

        const cedipFilters = { ...values, pageNumber: 1, ...newFilters };

        setValues({ ...values, ...newFilters });
        dispatch(accountsActions.listAccounts(user.userId));
        dispatch(cedipActions.listCedip(cedipFilters));
        dispatch(cedipActions.setFilters(cedipFilters));

        if (isDesktop) {
            setShowForm(false);
        }
    }, [match, dispatch, isDesktop]);

    const keysInCommon = Object.keys(values).filter((key) => key in filters);
    const changedFilters = !keysInCommon.every((key) => values[key] === filters[key]);
    const { showCleanFilterButton } = cedipState;

    useEffect(() => {
        if (changedFilters && !showCleanFilterButton) {
            setCedipState({
                ...cedipState,
                ...filters.orderColumName,
                ...filters.orderDirection,
                showCleanFilterButton: true,
            });
        }
    }, [showCleanFilterButton, filters, values, keysInCommon, changedFilters]);

    useEffect(() => {
        if (buttonClicked) {
            const newStatus = values.status;
            let newFiltersTolist = filters;
            newFiltersTolist = {
                ...newFiltersTolist,
                status: newStatus,
            };
            const cedipFilters = { ...values, pageNumber: 1, ...newFiltersTolist };
            dispatch(cedipActions.listCedip(cedipFilters));
            setButtonClicked(false);
        }
    }, [buttonClicked, dispatch]);

    const handleChangeButton = (button) => {
        setErrors({});
        dispatch(cedipActions.cleanCedips());
        if (button === "myCedip") {
            dispatch(cedipActions.setOwnCedipSelected());
            setValues({ ...values, status: "ACTIVO" });
        } else {
            dispatch(cedipActions.setRecievedCedipSelected());
            setValues({ ...values, status: "ACTIVO-PENDIENTE" });
        }
        handleChangeDirection({ newColumn: "descendent" });
        setButtonClicked(true);
    };

    const handleChangeOrderBy = ({ newDirection: orderBy }) => {
        setCedipState((prevState) => ({
            ...prevState,
            filterOrder: {
                ...prevState.filterOrder,
                column: orderBy,
            },
            filterSelected: orderBy,
        }));
        dispatch(
            cedipActions.setFilters({
                ...filters,
                orderColumName: orderBy,
            }),
        );
    };

    const handleChangeDirection = ({ newColumn: direction }) => {
        setCedipState((prevState) => ({
            ...prevState,
            filterOrder: {
                ...prevState.filterOrder,
                direction,
            },
            directionFilter: direction,
        }));
        dispatch(
            cedipActions.setFilters({
                ...filters,
                orderDirection: direction,
            }),
        );
    };

    const handleExport = () => {
        const { orderColumName, orderDirection } = filters;
        const creationDateFromFormatted = moment(values.creationDateFrom.toString().replace(".000Z", "")).format(
            "YYYY-MM-DD",
        );
        const creationDateToFormatted = moment(values.creationDateTo.toString().replace(".000Z", "")).format(
            "YYYY-MM-DD",
        );
        const dueDateFromFormatted = moment(values.dueDateFrom.toString().replace(".000Z", "")).format("YYYY-MM-DD");
        const dueDateToFormatted = moment(values.dueDateTo.toString().replace(".000Z", "")).format("YYYY-MM-DD");

        const filter = {
            ...values,
            orderColumName,
            orderDirection,
            status: values.status,
            pageNumber: 1,
            creationDateFrom: creationDateFromFormatted,
            creationDateTo: creationDateToFormatted,
            dueDateFrom: dueDateFromFormatted,
            dueDateTo: dueDateToFormatted,
            format: "pdf",
        };

        dispatch(cedipActions.downloadList(filter));
    };

    const handleBack = () => dispatch(push("/desktop/"));

    const cleanFilters = () => {
        const newValues = {
            status: "ACTIVO-PENDIENTE",
            idCedip: "",
            creationDate: true,
            creationDateFrom: getLimitDate(-7),
            creationDateTo: getLimitDate(0),
            dueDate: true,
            dueDateFrom: getLimitDate(0),
            dueDateTo: getLimitDate(7),
            orderColumName: "fecha_vencimiento",
            orderDirection: "descendent",
            pageNumber: 1,
        };
        setCedipState((prevState) => ({
            ...prevState,
            filterOrder: {
                ...prevState.filterOrder,
                direction: "descendent",
            },
            cleanFilter: false,
            showCleanFilterButton: false,
            directionFilter: "descendent",
        }));
        setValues(newValues);
        dispatch(cedipActions.setFilters(newValues));
        dispatch(cedipActions.listCedip(newValues));
    };

    const closeDrawer = () => {
        setCedipState({ ...cedipState, showDrawer: false });
    };

    const handleClickAccept = (cbu) => {
        const { cedipToAccept } = cedipState;
        dispatch(cedipActions.setSelectedCedip(cedipToAccept));
        dispatch(cedipActions.setAccepting(cbu));
        dispatch(push(`/cedip/accept`));
    };

    const renderHeader = () => (
        <div className="admin-detail-head px-0">
            {isDesktop && <Head onBack={handleBack} />}
            <Head
                title="menu.investments.manageCedip"
                exportList={!isEmpty(cedips)}
                handleClick={handleExport}
                isFetchingExport={fetchingDownload}
                onBack={!isDesktop ? handleBack : null}
                handleClickMessage="deposits.list.download"
                downloadImageWhite={!isDesktop}
                addButtonImageWhite={!isDesktop}
                titleClassName={!isDesktop ? "header-mobile-title-background-blue" : ""}
                headerClassName={!isDesktop && "blue-main-header-mobile blue-main-title-mobile"}
                hideMobileMenu={!isDesktop}
                rightContent={rightContentResult && (() => rightContentResult)}
                imageStyle="mr-2"
                messageTooltip={isDesktop ? cedipInformationBox : undefined}
                hintText={isDesktop ? cedipInformationBox : undefined}
                alwaysShowMessage={isDesktop}
            />
        </div>
    );

    const renderDrawer = () => {
        const { cedipToAccept } = cedipState;
        const cedipCurrency = cedipToAccept?.codigoMoneda === "032" ? "0" : "2";
        const title = i18n.get(`${FORM_ID}.accept.modal.title`);

        const accountOptions = accounts
            .filter((item) => item.currency !== cedipCurrency)
            .sort((a, b) => b.favorite - a.favorite);

        const destinationAccount = values.account
            ? accountOptions.find(({ idProduct }) => idProduct === values.account)
            : "";

        const destinationCbuLabel = destinationAccount?.cbu
            ? i18n.get("deposits.cedip.accept.modal.destinationCbu", null, {
                  cbu: destinationAccount.cbu,
              })
            : "";

        return (
            <div className="mx-80 h-100">
                {isDesktop && <h1 className="text-align-center margin-top-80">{title}</h1>}
                <div className="margin-top-80">
                    <Field
                        name="account"
                        component={Productselector}
                        idField="account"
                        data={{
                            emptyOptionLabel: "",
                            options: accountOptions,
                        }}
                        disabled={accounts.length === 0}
                        isRequired
                        mode="edit"
                        idForm={FORM_ID}
                        isDesktop={isDesktop}
                        customPlaceholder={i18n.get("transfers.productSelector.placeholder")}
                        controlLabelClassName="input__required"
                    />
                </div>

                {destinationAccount && <span>{destinationCbuLabel}</span>}
                <Col className="confirmation__content-button margin-top-80" lg={12} md={12} sm={12}>
                    <div className="admin-content-center">
                        <Button
                            image="images/arrowRight.svg"
                            imageStyle={isDesktop ? "mr-2" : "mr-1"}
                            bsStyle="primary"
                            label="global.continue"
                            disabled={!destinationAccount.cbu}
                            onClick={() => handleClickAccept(destinationAccount?.cbu)}
                        />
                    </div>
                </Col>
            </div>
        );
    };

    return (
        <>
            <Notification scopeToShow="cedip" notificationClassname="navbar-fixed-top" />
            {!isDesktop && renderHeader()}
            {recievedCedipSelected && cedipState.showDrawer && (
                <DrawerModal
                    content={renderDrawer()}
                    width={isDesktop ? "560px" : "100vw"}
                    showDrawerRemote={cedipState.showDrawer}
                    isDesktop={isDesktop}
                    title={`${isDesktop ? "" : "deposits.cedip.accept.modal.header"}`}
                    headerContent={
                        !isDesktop && (
                            <div className="toolbar-item view-title align-self-center mx-0">
                                <I18n
                                    id="deposits.cedip.accept.modal.header"
                                    component="h1"
                                    componentProps={{
                                        className: "title-content header-mobile-title-background-blue mb-0",
                                    }}
                                />
                            </div>
                        )
                    }
                    drawerClassName="cedip-admit-drawer"
                    closeRemotely={() => closeDrawer()}
                />
            )}
            <PageLoading loading={fetching} className="screen-loader">
                {!fetching && isDesktop && renderHeader()}
                <div className="cedip__dashboard">
                    <Container
                        className={classNames("align-items-center", { "py-3": isDesktop })}
                        rowClassName="align-items-center">
                        <Col xs={12} md={8} lg={10} className={classNames("container-white", { "py-3": isDesktop })}>
                            <div className={!isDesktop && "d-flex flex-column"}>
                                <TabletSelectors
                                    allDisabled={fetching && !isDesktop}
                                    isDesktop={isDesktop}
                                    possibleOptions={[
                                        { value: "myCedip", label: `${FORM_ID}.myCedip` },
                                        { value: "recieved", label: `${FORM_ID}.recieved` },
                                    ]}
                                    changeOption={handleChangeButton}
                                    optionSelected={recievedCedipSelected ? "recieved" : "myCedip"}
                                    containerClass={classNames({
                                        "my-3": !isDesktop,
                                        "echeq-tablet-selectors": isDesktop,
                                    })}
                                    buttonContainerClass={classNames({
                                        "mx-0": !isDesktop,
                                        "m-0": isDesktop,
                                    })}
                                    buttonClass="align-self-flex-end m-0 px-45"
                                    leftButtons
                                />
                            </div>
                        </Col>
                        {!isDesktop ? (
                            <Col xs={12} className="margin-left-auto align-items-end flex-column">
                                <Hint
                                    classNameMessage=""
                                    classNameGroup="justify-content-center d-flex my-3"
                                    classNameImage="my-0 ml-2 justify-content-center align-self-center"
                                    classNameHintP="aling-items-center my-2"
                                    alwaysShowMessage
                                    nodeMessage={
                                        <I18n
                                            defaultValue={cedipInformationBox}
                                            component="p"
                                            componentProps={{ className: "hint-text my-0" }}
                                        />
                                    }
                                />
                            </Col>
                        ) : (
                            undefined
                        )}
                        <Col
                            xs={12}
                            md={4}
                            lg={2}
                            className={classNames("margin-left-auto align-items-end flex-column", {
                                "container-white py-4": isDesktop,
                            })}>
                            <Button
                                block
                                bsStyle="outline"
                                className="px-25 py-15 m-0 font-light right width-3 min-height-auto text-transform-none"
                                label={
                                    showForm
                                        ? "global.hide.filter"
                                        : `${changedFilters ? "global.filter.active" : "global.see.filter"}`
                                }
                                onClick={() => setShowForm(!showForm)}
                                image={showForm ? "images/eye-off.svg" : "images/eye.svg"}
                            />
                            {changedFilters && (
                                <Button
                                    label="global.clean.filters"
                                    className={classNames(
                                        "clean-filters-label font-light p-absolute top-35 mt-0 pt-1 min-height-auto",
                                        { "top-25": !isDesktop },
                                        { "mr-3": isDesktop },
                                    )}
                                    onClick={cleanFilters}
                                    image="images/cross.svg"
                                    imageStyle="circle-cross"
                                />
                            )}
                        </Col>
                    </Container>
                    {showForm && (
                        <CedipFilters
                            isDesktop={isDesktop}
                            isSmallDesktop={isSmallDesktop}
                            values={values}
                            fetching={fetching}
                            recievedCedipSelected={recievedCedipSelected}
                            showForm={showForm}
                            handleChangeFilterOrder={handleChangeDirection}
                            handleChangeOrder={handleChangeOrderBy}
                            setCleanFilter={(val) => setCedipState({ ...cedipState, cleanFilter: val })}
                            {...cedipState}
                            cleanFilter={cedipState.cleanFilter}
                        />
                    )}
                    <div>
                        {cedips.length ? (
                            <Container className="align-items-center flex-grow">
                                <div className="table table--products">
                                    <div
                                        role="menu"
                                        className={classNames("table-body", {
                                            "table-body--grid": productAsCard,
                                            "mt-5": !isDesktop,
                                        })}>
                                        {cedips &&
                                            Object.entries(cedips).map(([id, cedip]) => (
                                                <CedipCard
                                                    dispatch={dispatch}
                                                    cedip={cedip}
                                                    key={`${id} - ${cedip.numeroCertificado}`}
                                                    productAsCard={productAsCard}
                                                    isDesktop={isDesktop}
                                                    isSmallDesktop={isSmallDesktop}
                                                    openDrawer={() =>
                                                        setCedipState({
                                                            ...cedipState,
                                                            showDrawer: true,
                                                            cedipToAccept: cedip,
                                                        })
                                                    }
                                                    ownCedipSelected={ownCedipSelected}
                                                />
                                            ))}
                                    </div>
                                </div>
                            </Container>
                        ) : (
                            <GeneralMsg
                                imagePath="images/coloredIcons/find-in-page.svg"
                                description={i18n.get("deposits.list.empty")}
                                isDesktop={isDesktop}
                            />
                        )}
                    </div>
                </div>
            </PageLoading>
        </>
    );
}

CedipList.propTypes = {
    accounts: shape([]).isRequired,
    user: shape({
        userId: string.isRequired,
    }).isRequired,
    dispatch: func.isRequired,
    fetching: oneOfType([string, bool]),
    cedips: oneOfType([array]),
    values: shape({}).isRequired,
    isDesktop: bool.isRequired,
    isSmallDesktop: bool.isRequired,
    fetchingDownload: bool.isRequired,
    activeEnvironment: shape({}).isRequired,
    showForm: bool.isRequired,
    setValues: func.isRequired,
    setErrors: func.isRequired,
    recievedCedipSelected: bool.isRequired,
    ownCedipSelected: bool.isRequired,
    match: shape.isRequired,
    filters: shape({
        creationDate: bool.isRequired,
        createdFrom: string.isRequired,
        createdTo: string.isRequired,
        dueDate: bool.isRequired,
        dueDateFrom: string.isRequired,
        dueDateTo: string.isRequired,
        status: shape({ value: string.isRequired, label: string.isRequired }).isRequired,
        idCedip: string.isRequired,
        orderColumName: string.isRequired,
        orderDirection: string.isRequired,
    }).isRequired,
};

CedipList.defaultProps = {
    fetching: false,
    cedips: [],
};

const mapStateToProps = (state) => ({
    accounts: accountsSelectors.getAccounts(state),
    user: sessionSelectors.getUser(state),
    cedips: cedipSelectors.getCedipList(state),
    fetching: cedipSelectors.getFetching(state),
    fetchingDownload: cedipSelectors.getFetchingDownload(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
    ownCedipSelected: cedipSelectors.ownCedipSelected(state),
    recievedCedipSelected: cedipSelectors.recievedCedipSelected(state),
    showForm: cedipSelectors.getShowForm(state),
    filters: cedipSelectors.getFilters(state),
    setId: cedipSelectors.setId(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: false,
        validateOnBlur: false,
        mapPropsToValues: () => ({
            status: "ACTIVO-PENDIENTE",
            idCedip: "",
            creationDate: true,
            creationDateFrom: getLimitDate(-30),
            creationDateTo: getLimitDate(0),
            dueDate: true,
            dueDateFrom: getLimitDate(0),
            dueDateTo: getLimitDate(30),
        }),
        validationSchema: () =>
            Yup.lazy((values) =>
                Yup.object().shape({
                    dueDateFrom: values.dueDateTo
                        ? Yup.date()
                              .nullable()
                              .max(values.dueDateTo, i18n.get(`${FORM_ID}.dateFrom.error`))
                        : Yup.date().nullable(),
                    dueDateTo: values.dueDateFrom
                        ? Yup.date()
                              .nullable()
                              .min(values.dueDateFrom, i18n.get(`${FORM_ID}.dateTo.error`))
                        : Yup.date().nullable(),
                    creationDateFrom: values.creationDateTo
                        ? Yup.date()
                              .nullable()
                              .max(values.creationDateTo, i18n.get(`${FORM_ID}.dateFrom.error`))
                        : Yup.date().nullable(),
                    creationDateTo: values.creationDateFrom
                        ? Yup.date()
                              .nullable()
                              .min(values.creationDateFrom, i18n.get(`${FORM_ID}.dateTo.error`))
                        : Yup.date().nullable(),
                }),
            ),
        handleSubmit: (formFilters, formikBag) => {
            const { dispatch, filters } = formikBag.props;
            const { orderColumName, orderDirection } = filters;
            const creationDateFromFormatted = moment(
                formFilters.creationDateFrom.toString().replace(".000Z", ""),
            ).format("YYYY-MM-DD");
            const creationDateToFormatted = moment(formFilters.creationDateTo.toString().replace(".000Z", "")).format(
                "YYYY-MM-DD",
            );
            const dueDateFromFormatted = moment(formFilters.dueDateFrom.toString().replace(".000Z", "")).format(
                "YYYY-MM-DD",
            );
            const dueDateToFormatted = moment(formFilters.dueDateTo.toString().replace(".000Z", "")).format(
                "YYYY-MM-DD",
            );

            const filter = {
                ...formFilters,
                orderColumName,
                orderDirection,
                status: formFilters.status,
                pageNumber: 1,
                creationDateFrom: creationDateFromFormatted,
                creationDateTo: creationDateToFormatted,
                dueDateFrom: dueDateFromFormatted,
                dueDateTo: dueDateToFormatted,
            };
            dispatch(cedipActions.setFilters(filter));
            dispatch(cedipActions.listCedip(filter));
        },
    }),
)(CedipList);
