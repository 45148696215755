/* eslint-disable import/no-unresolved */
import { routerActions } from "react-router-redux";
import { call, put, select } from "redux-saga/effects";

import * as accountsMiddleware from "middleware/accounts";
import { bankedBeneficiary } from "middleware/checks";
import * as debinMiddleware from "middleware/debin";
import { actions as accountsActions } from "reducers/accounts";
import { actions as checksActions } from "reducers/checks";
import { actions as cedipActions } from "reducers/cedip";
import { actions as debinActions } from "reducers/debin";
import { selectors as formSelectors } from "reducers/form";
import { actions as fundcorpActions } from "reducers/fundcorp";
import { actions as multipleTransferActions } from "reducers/multiple-transfers";
import { actions as notificationActions } from "reducers/notification";
import { actions as servicePaymentsActions } from "reducers/servicePayments";
import { actions as thirdPaymentActions } from "reducers/thirdPayment";
import { actions as transferActions } from "reducers/transfer";
import { validateMessageCancellation } from "util/fundcorp";
import * as i18n from "util/i18n";

export function* redirectCancelled(idActivity, transaction) {
    const creatorFullName = transaction.userCreatorLastName.concat(" ", transaction.userCreatorFirstName);
    const userEditorFullName = transaction.userEditorLastName.concat(" ", transaction.userEditorFirstName);
    const { creationDateTime, signatures, idTransactionStatus, modificationDateTime, pendingDispatch } = transaction;

    switch (idActivity) {
        case "change.status.debin.recurrency": {
            yield call(actionRecurrencyDebin, idActivity, transaction);
            yield put(routerActions.push("/payments/recurrent/action/transaction"));
            break;
        }
        case "endorse.echeq.activity": {
            yield call(createEcheqTransactionData, idActivity, transaction);
            yield put(routerActions.push("/echeqs/cancelled/endorse"));
            break;
        }
        case "negotiate.echeq.activity": {
            yield call(createEcheqTransactionData, idActivity, transaction);
            yield put(routerActions.push("/echeqs/cancelled/endorse"));
            break;
        }
        case "load.debin.recurrency": {
            yield call(loadRecurrencyDebin, idActivity, transaction);
            yield put(routerActions.push("/payments/recurrent/load/transaction"));
            break;
        }
        case "account.checks": {
            yield put(
                checksActions.checksRequestData({
                    ...transaction.data,
                    creatorFullName,
                    signatures,
                    idTransaction: transaction.idTransaction,
                    creationDate: creationDateTime,
                    idActivity,
                    pendingDispatch,
                    userEditorFullName,
                    idTransactionStatus,
                    modificationDateTime,
                }),
            );
            yield put(routerActions.push("/checks/transaction"));
            break;
        }
        case "accounts.opening": {
            yield put(
                accountsActions.accountOpeningData({
                    ...transaction.data,
                    creatorFullName,
                    userEditorFullName,
                    idTransactionStatus,
                    modificationDateTime,
                    idTransaction: transaction.idTransaction,
                    creationDate: creationDateTime,
                    idActivity,
                }),
            );
            yield put(routerActions.push("/accountOpening/transaction"));
            break;
        }
        case "transfers.internal.send": {
            yield put(
                transferActions.loadTransfer({
                    ...transaction.data,
                    currency: transaction.data.amount.currency,
                    amount: transaction.data.amount.quantity,
                    creatorFullName,
                    userEditorFullName,
                    idTransactionStatus,
                    modificationDateTime,
                    creationDate: creationDateTime,
                    idTransaction: transaction.idTransaction,
                    idActivity,
                }),
            );
            yield put(routerActions.push("/transfer/cancelled"));
            break;
        }
        case "transfers.internal.tad.send": {
            yield put(
                transferActions.loadTransfer({
                    ...transaction.data,
                    currency: transaction.data.amount.currency,
                    amount: transaction.data.amount.quantity,
                    creatorFullName,
                    userEditorFullName,
                    idTransactionStatus,
                    modificationDateTime,
                    creationDate: creationDateTime,
                    idTransaction: transaction.idTransaction,
                    idActivity,
                }),
            );
            yield put(routerActions.push("/transfer/cancelled"));
            break;
        }
        case "transfers.thirdParties.send": {
            yield put(
                transferActions.loadExternalTransfer({
                    ...transaction.data,
                    currency: transaction.data.amount.currency,
                    amount: transaction.data.amount.quantity,
                    creatorFullName,
                    userEditorFullName,
                    idTransactionStatus,
                    modificationDateTime,
                    creationDate: creationDateTime,
                    idTransaction: transaction.idTransaction,
                    idActivity,
                }),
            );
            yield put(routerActions.push("/transfer/cancelled"));
            break;
        }
        case "transfers.thirdParties.tad.send": {
            yield put(
                transferActions.loadExternalTransfer({
                    ...transaction.data,
                    currency: transaction.data.amount.currency,
                    amount: transaction.data.amount.quantity,
                    creatorFullName,
                    userEditorFullName,
                    idTransactionStatus,
                    modificationDateTime,
                    creationDate: creationDateTime,
                    idTransaction: transaction.idTransaction,
                    idActivity,
                }),
            );
            yield put(routerActions.push("/transfer/cancelled"));
            break;
        }
        case "credin.sentRequest.send": {
            yield put(
                debinActions.loadConfirmCredin({
                    ...transaction.data,
                    currency: transaction.data.currency,
                    amount: transaction.data.amount,
                    creatorFullName,
                    userEditorFullName,
                    idTransactionStatus,
                    modificationDateTime,
                    creationDate: creationDateTime,
                    idTransaction: transaction.idTransaction,
                    idActivity,
                }),
            );
            yield put(routerActions.push("/confirmCredin/transaction"));
            break;
        }
        case "debin.incoming.accept.send": {
            yield put(
                debinActions.loadConfirmIncomingDebin({
                    ...transaction.data,
                    currency: transaction.data.currency,
                    amount: transaction.data.amount,
                    creatorFullName,
                    userEditorFullName,
                    idTransactionStatus,
                    modificationDateTime,
                    creationDate: creationDateTime,
                    idTransaction: transaction.idTransaction,
                    idActivity,
                }),
            );
            yield put(routerActions.replace("/confirmIncomingDebin/transaction"));
            break;
        }
        case "payments.makePayment": {
            yield put(
                servicePaymentsActions.loadConfirmServicePayments({
                    ...transaction.data,
                    summaryInfo: transaction.data.summaryInfo,
                    creatorFullName,
                    userEditorFullName,
                    idTransactionStatus,
                    modificationDateTime,
                    creationDate: creationDateTime,
                    idTransaction: transaction.idTransaction,
                    idActivity,
                }),
            );
            yield put(routerActions.push("/newPayment/cancelled"));
            break;
        }
        case "pay.multiline.send": {
            const isCancelling = yield select(formSelectors.getIsCancellingTransaction);
            const { cancelledFromMenu } = transaction.data;
            if (isCancelling && cancelledFromMenu) {
                yield put(
                    notificationActions.showNotification(i18n.get("thirdpayment.send.cancelled.message"), "success", [
                        "forms",
                    ]),
                );
                yield put(routerActions.push("/payThirdParties/list"));
            } else {
                yield put(
                    thirdPaymentActions.loadConfirmThirdPayment({
                        ...transaction.data,
                        currency: transaction.data.currency,
                        amount: transaction.data.amount,
                        creatorFullName,
                        userEditorFullName,
                        idTransactionStatus,
                        modificationDateTime,
                        creationDate: creationDateTime,
                        idTransaction: transaction.idTransaction,
                        idActivity,
                        pendingLines: transaction?.data?.pendingLines,
                        processedFileData: transaction?.data?.processedFileData,
                        idUserCreator: transaction?.idUserCreator,
                    }),
                );
                yield put(routerActions.push("/payThirdParties/cancelled"));
            }
            break;
        }
        case "emit.echeq.activity": {
            yield call(emitEcheqTransactionData, idActivity, transaction);
            yield put(routerActions.push("/echeqs/cancelled/emit"));
            break;
        }
        case "cesion.echeq.activity": {
            yield call(createEcheqTransactionData, idActivity, transaction);
            yield put(routerActions.push("/echeqs/cancelled/cesion"));
            break;
        }
        case "accept.return.echeqs": {
            yield call(echeqAcceptReturnActions, idActivity, transaction);
            yield put(routerActions.push("/echeqs/return/accept/transaction"));
            break;
        }
        case "scheduled.transactions.cancel": {
            yield call(loadCanceledSchedule, idActivity, transaction);
            const { type } = transaction.data;
            if (type && type === "subscribe") {
                yield put(routerActions.replace("/subscribe/confirmation"));
            } else if (type && type === "rescue") {
                yield put(routerActions.replace("/fundcorpOperations/rescue/confirmation"));
            } else {
                yield put(routerActions.replace("/transfer/confirmation"));
            }
            break;
        }
        case "fundcorp.register.rescue.request": {
            yield call(fundcorpRescueTransactionActions, idActivity, transaction);
            yield put(routerActions.push("/fundcorpOperations/rescue/cancelled"));
            break;
        }
        case "fundcorp.register.suscription.request": {
            yield call(fundcorpSubscribeTransactionActions, idActivity, transaction);
            yield put(routerActions.push("/subscribe/cancelled"));
            break;
        }
        case "emit.massive.echeqs.send": {
            yield call(echeqMassiveTransactionActions, idActivity, transaction);
            yield put(routerActions.push("/echeqs/emitMassive/cancelled"));
            break;
        }
        case "transfer.multiple.send": {
            yield put(
                multipleTransferActions.loadMultipleTransfer({
                    ...transaction.data,
                    debitAccount: transaction.data.account,
                    creatorFullName,
                    signatures,
                    creationDate: creationDateTime,
                    idTransaction: transaction.idTransaction,
                    idActivity,
                    pendingDispatch,
                    userEditorFullName,
                    idTransactionStatus,
                    modificationDateTime,
                    idUserCreator: transaction?.idUserCreator,
                }),
            );
            yield put(routerActions.replace("/transfer/multiple/cancelled"));
            break;
        }
        default: {
            break;
        }
    }
}
export function* redirectPending(idActivity, transaction) {
    const creatorFullName = transaction.userCreatorLastName.concat(" ", transaction.userCreatorFirstName);
    const userEditorFullName = transaction.userEditorLastName.concat(" ", transaction.userEditorFirstName);
    const { creationDateTime, signatures, idTransactionStatus, modificationDateTime, pendingDispatch } = transaction;

    switch (idActivity) {
        case "change.status.debin.recurrency": {
            yield call(actionRecurrencyDebin, idActivity, transaction);
            yield put(routerActions.push("/payments/recurrent/action/transaction"));
            break;
        }
        case "endorse.echeq.activity": {
            yield call(createEcheqTransactionData, idActivity, transaction);
            yield put(routerActions.push("/echeqs/pending/endorse"));
            break;
        }
        case "negotiate.echeq.activity": {
            yield call(createEcheqTransactionData, idActivity, transaction);
            yield put(routerActions.push("/echeqs/pending/endorse"));
            break;
        }
        case "load.debin.recurrency": {
            yield call(loadRecurrencyDebin, idActivity, transaction);
            yield put(routerActions.push("/payments/recurrent/load/transaction"));
            break;
        }
        case "accounts.opening": {
            yield put(
                accountsActions.accountOpeningData({
                    ...transaction.data,
                    creatorFullName,
                    signatures,
                    idTransaction: transaction.idTransaction,
                    creationDate: creationDateTime,
                    idActivity,
                    pendingDispatch,
                    userEditorFullName,
                    idTransactionStatus,
                    modificationDateTime,
                }),
            );
            yield put(routerActions.push("/accountOpening/transaction"));
            break;
        }
        case "account.checks": {
            yield put(
                checksActions.checksRequestData({
                    ...transaction.data,
                    creatorFullName,
                    signatures,
                    idTransaction: transaction.idTransaction,
                    creationDate: creationDateTime,
                    idActivity,
                    pendingDispatch,
                    userEditorFullName,
                    idTransactionStatus,
                    modificationDateTime,
                }),
            );
            yield put(routerActions.push("/checks/transaction"));
            break;
        }
        case "transfers.internal.send": {
            yield put(
                transferActions.loadTransfer({
                    ...transaction.data,
                    currency: transaction.data.amount.currency,
                    amount: transaction.data.amount.quantity,
                    creatorFullName,
                    signatures,
                    creationDate: creationDateTime,
                    idTransaction: transaction.idTransaction,
                    idActivity,
                    pendingDispatch,
                    userEditorFullName,
                    idTransactionStatus,
                    modificationDateTime,
                }),
            );
            yield put(routerActions.replace("/transfer/pending"));
            break;
        }
        case "transfers.internal.tad.send": {
            yield put(
                transferActions.loadTransfer({
                    ...transaction.data,
                    currency: transaction.data.amount.currency,
                    amount: transaction.data.amount.quantity,
                    creatorFullName,
                    signatures,
                    creationDate: creationDateTime,
                    idTransaction: transaction.idTransaction,
                    idActivity,
                    pendingDispatch,
                    userEditorFullName,
                    idTransactionStatus,
                    modificationDateTime,
                }),
            );
            yield put(routerActions.replace("/transfer/pending"));
            break;
        }
        case "transfers.thirdParties.send": {
            yield put(
                transferActions.loadExternalTransfer({
                    ...transaction.data,
                    currency: transaction.data.amount.currency,
                    amount: transaction.data.amount.quantity,
                    creatorFullName,
                    signatures,
                    creationDate: creationDateTime,
                    idTransaction: transaction.idTransaction,
                    idActivity,
                    pendingDispatch,
                    userEditorFullName,
                    idTransactionStatus,
                    modificationDateTime,
                }),
            );
            yield put(routerActions.replace("/transfer/pending"));
            break;
        }
        case "transfers.thirdParties.tad.send": {
            yield put(
                transferActions.loadExternalTransfer({
                    ...transaction.data,
                    currency: transaction.data.amount.currency,
                    amount: transaction.data.amount.quantity,
                    creatorFullName,
                    signatures,
                    creationDate: creationDateTime,
                    idTransaction: transaction.idTransaction,
                    idActivity,
                    pendingDispatch,
                    userEditorFullName,
                    idTransactionStatus,
                    modificationDateTime,
                }),
            );
            yield put(routerActions.replace("/transfer/pending"));
            break;
        }
        case "scheduled.transactions.cancel": {
            yield call(loadCanceledSchedule, idActivity, transaction);
            const { type } = transaction.data;
            if (type && type === "subscribe") {
                yield put(routerActions.replace("/subscribe/pending"));
            } else if (type && type === "rescue") {
                yield put(routerActions.replace("/fundcorpOperations/rescue/pending"));
            } else {
                yield put(routerActions.replace("/transfer/pending"));
            }
            break;
        }
        case "credin.sentRequest.send": {
            yield put(
                debinActions.loadConfirmCredin({
                    ...transaction.data,
                    currency: transaction.data.currency,
                    amount: transaction.data.amount,
                    creatorFullName,
                    signatures,
                    userEditorFullName,
                    idTransactionStatus,
                    modificationDateTime,
                    pendingDispatch,
                    creationDate: creationDateTime,
                    idTransaction: transaction.idTransaction,
                    idActivity,
                }),
            );
            yield put(routerActions.push("/confirmCredin/transaction"));
            break;
        }
        case "debin.incoming.accept.send": {
            yield put(
                debinActions.loadConfirmIncomingDebin({
                    ...transaction.data,
                    currency: transaction.data.currency,
                    amount: transaction.data.amount,
                    creatorFullName,
                    signatures,
                    creationDate: creationDateTime,
                    idTransaction: transaction.idTransaction,
                    idActivity,
                    pendingDispatch,
                    userEditorFullName,
                    idTransactionStatus,
                    modificationDateTime,
                }),
            );
            yield put(routerActions.push("/confirmIncomingDebin/transaction"));
            break;
        }
        case "pay.multiline.send": {
            yield put(
                thirdPaymentActions.loadConfirmThirdPayment({
                    ...transaction.data,
                    currency: transaction.data.currency,
                    amount: transaction.data.amount,
                    creatorFullName,
                    userEditorFullName,
                    idTransactionStatus,
                    modificationDateTime,
                    creationDate: creationDateTime,
                    idTransaction: transaction.idTransaction,
                    idActivity,
                    signatures,
                    pendingLines: transaction?.data?.pendingLines,
                    processedFileData: transaction?.data?.processedFileData,
                    idUserCreator: transaction?.idUserCreator,
                }),
            );
            yield put(routerActions.replace("/payThirdParties/pending"));
            break;
        }
        case "payments.makePayment": {
            yield put(
                servicePaymentsActions.loadConfirmServicePayments({
                    ...transaction.data,
                    summaryInfo: transaction.data.summaryInfo,
                    creatorFullName,
                    signatures,
                    creationDate: creationDateTime,
                    idTransaction: transaction.idTransaction,
                    idActivity,
                    pendingDispatch,
                    userEditorFullName,
                    idTransactionStatus,
                    modificationDateTime,
                }),
            );
            yield put(routerActions.replace("/newPayment/pending"));
            break;
        }
        case "emit.echeq.activity": {
            yield call(emitEcheqTransactionData, idActivity, transaction);
            yield put(routerActions.push("/echeqs/pending/emit"));
            break;
        }
        case "cesion.echeq.activity": {
            yield call(createEcheqTransactionData, idActivity, transaction);
            yield put(routerActions.push("/echeqs/pending/cesion"));
            break;
        }
        case "accept.return.echeqs": {
            yield call(echeqAcceptReturnActions, idActivity, transaction);
            yield put(routerActions.push("/echeqs/return/accept/transaction"));
            break;
        }
        case "emit.massive.echeqs.send": {
            yield call(echeqMassiveTransactionActions, idActivity, transaction);
            yield put(routerActions.push("/echeqs/emitMassive/pending"));
            break;
        }
        case "transfer.multiple.send": {
            yield put(
                multipleTransferActions.loadMultipleTransfer({
                    ...transaction.data,
                    debitAccount: transaction.data.account,
                    creatorFullName,
                    signatures,
                    creationDate: creationDateTime,
                    idTransaction: transaction.idTransaction,
                    idActivity,
                    pendingDispatch,
                    userEditorFullName,
                    idTransactionStatus,
                    modificationDateTime,
                    idUserCreator: transaction?.idUserCreator,
                }),
            );
            yield put(routerActions.replace("/transfer/multiple/pending"));
            break;
        }
        default: {
            break;
        }
    }
}

export function* redirectDraft(idActivity, transaction) {
    const creatorFullName = transaction.userCreatorLastName.concat(" ", transaction.userCreatorFirstName);
    const userEditorFullName = transaction.userEditorLastName.concat(" ", transaction.userEditorFirstName);
    const { creationDateTime, idTransactionStatus, modificationDateTime } = transaction;

    switch (idActivity) {
        case "account.checks": {
            yield put(
                checksActions.checksRequestData({
                    ...transaction.data,
                    creatorFullName,
                    userEditorFullName,
                    idTransactionStatus,
                    modificationDateTime,
                    idTransaction: transaction.idTransaction,
                    creationDate: creationDateTime,
                    idActivity,
                    returnToPending: true,
                }),
            );
            yield put(routerActions.push("/checks/modify"));
            break;
        }

        case "accounts.opening": {
            yield put(
                accountsActions.accountOpeningData({
                    ...transaction.data,
                    creatorFullName,
                    userEditorFullName,
                    idTransactionStatus,
                    modificationDateTime,
                    idTransaction: transaction.idTransaction,
                    creationDate: creationDateTime,
                    idActivity,
                    returnToPending: true,
                }),
            );
            yield put(routerActions.push("/accountOpening/modify"));
            break;
        }
        case "transfers.internal.send": {
            yield put(
                transferActions.loadTransfer({
                    ...transaction.data,
                    currency: transaction.data.amount.currency,
                    amount: transaction.data.amount.quantity,
                    creatorFullName,
                    userEditorFullName,
                    idTransactionStatus,
                    modificationDateTime,
                    creationDate: creationDateTime,
                    idTransaction: transaction.idTransaction,
                    idActivity,
                    returnToPending: true,
                }),
            );
            yield put(routerActions.push("/transfer/modify"));
            break;
        }
        case "transfers.internal.tad.send": {
            yield put(
                transferActions.loadTransfer({
                    ...transaction.data,
                    currency: transaction.data.amount.currency,
                    amount: transaction.data.amount.quantity,
                    creatorFullName,
                    userEditorFullName,
                    idTransactionStatus,
                    modificationDateTime,
                    creationDate: creationDateTime,
                    idTransaction: transaction.idTransaction,
                    idActivity,
                    returnToPending: true,
                }),
            );
            yield put(routerActions.push("/transfer/modify"));
            break;
        }

        case "transfers.thirdParties.send": {
            yield put(
                transferActions.loadExternalTransfer({
                    ...transaction.data,
                    currency: transaction.data.amount.currency,
                    amount: transaction.data.amount.quantity,
                    creatorFullName,
                    creationDate: creationDateTime,
                    idTransaction: transaction.idTransaction,
                    idActivity,
                    returnToPending: true,
                }),
            );
            yield put(routerActions.push("/transfer/modify"));
            break;
        }
        case "transfers.thirdParties.tad.send": {
            yield put(
                transferActions.loadExternalTransfer({
                    ...transaction.data,
                    currency: transaction.data.amount.currency,
                    amount: transaction.data.amount.quantity,
                    creatorFullName,
                    creationDate: creationDateTime,
                    idTransaction: transaction.idTransaction,
                    idActivity,
                    returnToPending: true,
                }),
            );
            yield put(routerActions.push("/transfer/modify"));
            break;
        }
        case "credin.sentRequest.send": {
            const cbuList = transaction?.data?.cbuList || [];
            yield put(
                debinActions.setDebinSeller(
                    {
                        ...transaction.data,
                        idTransaction: transaction.idTransaction,
                    },
                    cbuList,
                ),
            );
            yield put(debinActions.setIsBackFromSummary("/requestCredin"));
            break;
        }

        default: {
            break;
        }
    }
}
export function* redirectFinished(idActivity, transaction, newTransaction) {
    const creatorFullName = transaction.userCreatorLastName.concat(" ", transaction.userCreatorFirstName);
    const userEditorFullName = transaction.userEditorLastName.concat(" ", transaction.userEditorFirstName);
    const { creationDateTime, signatures, pendingDispatch, idTransactionStatus, modificationDateTime } = transaction;

    switch (idActivity) {
        case "change.status.debin.recurrency": {
            yield call(actionRecurrencyDebin, idActivity, transaction);
            yield put(routerActions.push("/payments/recurrent/action/transaction"));
            break;
        }
        case "endorse.echeq.activity": {
            yield call(createEcheqTransactionData, idActivity, transaction);
            yield put(routerActions.push("/echeqs/confirm/endorse"));
            break;
        }
        case "negotiate.echeq.activity": {
            yield call(createEcheqTransactionData, idActivity, transaction);
            yield put(routerActions.push("/echeqs/confirm/endorse"));
            break;
        }
        case "load.debin.recurrency": {
            yield call(loadRecurrencyDebin, idActivity, transaction);
            yield put(routerActions.push("/payments/recurrent/load/transaction"));
            break;
        }
        case "accounts.opening": {
            yield put(
                accountsActions.accountOpeningData({
                    ...transaction.data,
                    signatures,
                    creatorFullName,
                    idTransaction: transaction.idTransaction,
                    creationDate: creationDateTime,
                    idActivity,
                    pendingDispatch,
                    modificationDateTime,
                    userEditorFullName,
                    idTransactionStatus,
                }),
            );
            yield put(routerActions.push("/accountOpening/transaction"));
            break;
        }
        case "account.checks": {
            yield put(
                checksActions.checksRequestData({
                    ...transaction.data,
                    creatorFullName,
                    signatures,
                    idTransaction: transaction.idTransaction,
                    creationDate: creationDateTime,
                    idActivity,
                    pendingDispatch,
                    userEditorFullName,
                    idTransactionStatus,
                    modificationDateTime,
                }),
            );
            yield put(routerActions.push("/checks/transaction"));
            break;
        }
        case "transfers.internal.send": {
            yield put(
                transferActions.loadTransfer({
                    ...transaction.data,
                    currency: transaction.data.amount.currency,
                    amount: transaction.data.amount.quantity,
                    creatorFullName,
                    signatures,
                    creationDate: creationDateTime,
                    idTransaction: transaction.idTransaction,
                    idActivity,
                    pendingDispatch,
                    userEditorFullName,
                    idTransactionStatus,
                    modificationDateTime,
                }),
            );
            yield put(routerActions.replace("/transfer/confirmation"));
            break;
        }
        case "transfers.internal.tad.send": {
            yield put(
                transferActions.loadTransfer({
                    ...transaction.data,
                    currency: transaction.data.amount.currency,
                    amount: transaction.data.amount.quantity,
                    creatorFullName,
                    signatures,
                    creationDate: creationDateTime,
                    idTransaction: transaction.idTransaction,
                    idActivity,
                    pendingDispatch,
                    userEditorFullName,
                    idTransactionStatus,
                    modificationDateTime,
                }),
            );
            yield put(routerActions.replace("/transfer/confirmation"));
            break;
        }

        case "transfers.thirdParties.send": {
            yield put(
                transferActions.loadExternalTransfer({
                    ...transaction.data,
                    currency: transaction.data.amount.currency,
                    amount: transaction.data.amount.quantity,
                    creatorFullName,
                    signatures,
                    creationDate: creationDateTime,
                    idTransaction: transaction.idTransaction,
                    idActivity,
                    pendingDispatch,
                    userEditorFullName,
                    idTransactionStatus,
                    modificationDateTime,
                }),
            );
            yield put(routerActions.replace("/transfer/confirmation"));
            break;
        }
        case "transfers.thirdParties.tad.send": {
            yield put(
                transferActions.loadExternalTransfer({
                    ...transaction.data,
                    currency: transaction.data.amount.currency,
                    amount: transaction.data.amount.quantity,
                    creatorFullName,
                    signatures,
                    creationDate: creationDateTime,
                    idTransaction: transaction.idTransaction,
                    idActivity,
                    pendingDispatch,
                    userEditorFullName,
                    idTransactionStatus,
                    modificationDateTime,
                }),
            );
            yield put(routerActions.replace("/transfer/confirmation"));
            break;
        }
        case "scheduled.transactions.cancel": {
            yield call(loadCanceledSchedule, idActivity, transaction);
            const { type } = transaction.data;
            if (type && type === "subscribe") {
                yield put(routerActions.replace("/subscribe/confirmation"));
            } else if (type && type === "rescue") {
                yield put(routerActions.replace("/fundcorpOperations/rescue/confirmation"));
            } else {
                yield put(routerActions.replace("/transfer/confirmation"));
            }
            break;
        }
        case "credin.sentRequest.send": {
            yield put(
                debinActions.loadConfirmCredin({
                    ...transaction.data,
                    currency: transaction.data.currency,
                    amount: transaction.data.amount,
                    creatorFullName,
                    signatures,
                    userEditorFullName,
                    idTransactionStatus,
                    modificationDateTime,
                    creationDate: creationDateTime,
                    idTransaction: transaction.idTransaction,
                    idActivity,
                }),
            );
            yield put(routerActions.push("/confirmCredin/transaction"));
            break;
        }
        case "debin.incoming.accept.send": {
            yield put(
                debinActions.loadConfirmIncomingDebin({
                    ...transaction.data,
                    currency: transaction.data.amount.currency,
                    amount: transaction.data.amount.quantity,
                    creatorFullName,
                    signatures,
                    userEditorFullName,
                    idTransactionStatus,
                    modificationDateTime,
                    creationDate: creationDateTime,
                    idTransaction: transaction.idTransaction,
                    idActivity,
                }),
            );
            yield put(routerActions.push("/confirmIncomingDebin/transaction"));
            break;
        }
        case "pay.multiline.send": {
            yield put(
                thirdPaymentActions.loadConfirmThirdPayment({
                    ...transaction.data,
                    currency: transaction.data.currency,
                    amount: transaction.data.amount,
                    creatorFullName,
                    userEditorFullName,
                    idTransactionStatus,
                    modificationDateTime,
                    creationDate: creationDateTime,
                    idTransaction: transaction.idTransaction,
                    idActivity,
                    pendingLines: transaction?.data?.pendingLines,
                    processedFileData: transaction?.data?.processedFileData,
                    idUserCreator: transaction?.idUserCreator,
                }),
            );
            if (newTransaction) {
                yield put(
                    notificationActions.showNotification(i18n.get("thirdpayment.send.success.message"), "success", [
                        "forms",
                    ]),
                );
            }
            yield put(routerActions.push("/payThirdParties/confirmation"));
            break;
        }
        case "echeq.admit.action": {
            yield call(echeqTransactionActions, idActivity, transaction);
            yield put(routerActions.push("/echeqs/transaction/action"));
            break;
        }
        case "echeq.anulate-cesion.action": {
            yield call(echeqTransactionActions, idActivity, transaction);
            yield put(routerActions.push("/echeqs/transaction/action"));
            break;
        }
        case "echeq.anulate-endorse.action": {
            yield call(echeqTransactionActions, idActivity, transaction);
            yield put(routerActions.push("/echeqs/transaction/action"));
            break;
        }
        case "echeq.anulate-echeq.action": {
            yield call(echeqTransactionActions, idActivity, transaction);
            yield put(routerActions.push("/echeqs/transaction/action"));
            break;
        }
        case "echeq.repudiate.action": {
            yield call(echeqTransactionActions, idActivity, transaction);
            yield put(routerActions.push("/echeqs/transaction/action"));
            break;
        }
        case "echeq.deposit.action": {
            yield call(echeqTransactionActions, idActivity, transaction);
            yield put(routerActions.push("/echeqs/transaction/action"));
            break;
        }
        case "echeq.custody.action": {
            yield call(echeqTransactionActions, idActivity, transaction);
            yield put(routerActions.push("/echeqs/transaction/action"));
            break;
        }
        case "echeq.request-return.action": {
            yield call(echeqTransactionActions, idActivity, transaction);
            yield put(routerActions.push("/echeqs/transaction/action"));
            break;
        }

        case "echeq.rescue.action": {
            yield call(echeqTransactionActions, idActivity, transaction);
            yield put(routerActions.push("/echeqs/transaction/action"));
            break;
        }
        case "echeq.repudiate-cesion.action": {
            yield call(echeqTransactionActions, idActivity, transaction);
            yield put(routerActions.push("/echeqs/transaction/action"));
            break;
        }
        case "echeq.admit-cesion.action": {
            yield call(echeqTransactionActions, idActivity, transaction);
            yield put(routerActions.push("/echeqs/transaction/action"));
            break;
        }
        case "payments.makePayment": {
            yield put(
                servicePaymentsActions.loadConfirmServicePayments({
                    ...transaction.data,
                    summaryInfo: transaction.data.summaryInfo,
                    creatorFullName,
                    signatures,
                    userEditorFullName,
                    idTransactionStatus,
                    modificationDateTime,
                    creationDate: creationDateTime,
                    idTransaction: transaction.idTransaction,
                    idActivity,
                }),
            );
            yield put(routerActions.replace("/newPayment/confirmation"));
            break;
        }
        case "emit.echeq.activity": {
            yield call(emitEcheqTransactionData, idActivity, transaction);
            yield put(routerActions.push("/echeqs/confirm/emit"));
            break;
        }
        case "cesion.echeq.activity": {
            yield call(createEcheqTransactionData, idActivity, transaction);
            yield put(routerActions.push("/echeqs/confirm/cesion"));
            break;
        }
        case "echeq.anulate-return.action": {
            yield call(echeqTransactionActions, idActivity, transaction);
            yield put(routerActions.push("/echeqs/transaction/action"));
            break;
        }
        case "echeq.repudiate-return.action": {
            yield call(echeqTransactionActions, idActivity, transaction);
            yield put(routerActions.push("/echeqs/transaction/action"));
            break;
        }
        case "accept.return.echeqs": {
            yield call(echeqAcceptReturnActions, idActivity, transaction);
            yield put(routerActions.push("/echeqs/return/accept/transaction"));
            break;
        }
        case " echeq.request-certificate.action": {
            yield call(echeqTransactionActions, idActivity, transaction);
            yield put(routerActions.push("/echeqs/transaction/action"));
            break;
        }

        case "fundcorp.register.rescue.request": {
            yield call(fundcorpRescueTransactionActions, idActivity, transaction);
            yield put(routerActions.push("/fundcorpOperations/rescue/confirmation"));
            break;
        }

        case "fundcorp.register.suscription.request": {
            yield call(fundcorpSubscribeTransactionActions, idActivity, transaction);
            yield put(routerActions.push("/subscribe/confirmation"));
            break;
        }
        case "emit.massive.echeqs.send": {
            yield call(echeqMassiveTransactionActions, idActivity, transaction);
            yield put(routerActions.push("/echeqs/emitMassive/confirmation"));
            break;
        }
        case "transfer.multiple.send": {
            yield put(
                multipleTransferActions.loadMultipleTransfer({
                    ...transaction.data,
                    debitAccount: transaction.data.account,
                    creatorFullName,
                    signatures,
                    creationDate: creationDateTime,
                    idTransaction: transaction.idTransaction,
                    idActivity,
                    pendingDispatch,
                    userEditorFullName,
                    idTransactionStatus,
                    modificationDateTime,
                    idUserCreator: transaction?.idUserCreator,
                }),
            );
            yield put(routerActions.replace("/transfer/multiple/confirmation"));
            break;
        }
        case "cedip.reject": {
            yield put(
                cedipActions.loadTransactionData({
                    ...transaction.data,
                    creationDate: creationDateTime,
                    idTransaction: transaction.idTransaction,
                    idActivity,
                    idTransactionStatus,
                    modificationDateTime,
                }),
            );
            yield put(routerActions.replace("/cedip/ticket"));
            break;
        }
        default: {
            break;
        }
    }
}

export function* redirectFailed(idActivity, transaction, newTransaction) {
    const creatorFullName = transaction.userCreatorLastName.concat(" ", transaction.userCreatorFirstName);
    const userEditorFullName = transaction.userEditorLastName.concat(" ", transaction.userEditorFirstName);
    const { creationDateTime, signatures, pendingDispatch, idTransactionStatus, modificationDateTime } = transaction;

    switch (idActivity) {
        case "change.status.debin.recurrency": {
            yield call(actionRecurrencyDebin, idActivity, transaction);
            yield put(routerActions.push("/payments/recurrent/action/transaction"));
            break;
        }
        case "echeq.admit.action": {
            yield call(echeqTransactionActions, idActivity, transaction);
            yield put(routerActions.push("/echeqs/transaction/action"));
            break;
        }
        case "accounts.opening": {
            yield put(
                accountsActions.accountOpeningData({
                    ...transaction.data,
                    creatorFullName,
                    userEditorFullName,
                    idTransactionStatus,
                    modificationDateTime,
                    idTransaction: transaction.idTransaction,
                    creationDate: creationDateTime,
                    idActivity,
                    returnToPending: true,
                }),
            );
            yield put(routerActions.push("/accountOpening/transaction"));
            break;
        }
        case "echeq.anulate-cesion.action": {
            yield call(echeqTransactionActions, idActivity, transaction);
            yield put(routerActions.push("/echeqs/transaction/action"));
            break;
        }
        case "load.debin.recurrency": {
            yield call(loadRecurrencyDebin, idActivity, transaction);
            yield put(routerActions.push("/payments/recurrent/load/transaction"));
            break;
        }
        case "echeq.anulate-echeq.action": {
            yield call(echeqTransactionActions, idActivity, transaction);
            yield put(routerActions.push("/echeqs/transaction/action"));
            break;
        }
        case "echeq.anulate-endorse.action": {
            yield call(echeqTransactionActions, idActivity, transaction);
            yield put(routerActions.push("/echeqs/transaction/action"));
            break;
        }
        case "echeq.repudiate.action": {
            yield call(echeqTransactionActions, idActivity, transaction);
            yield put(routerActions.push("/echeqs/transaction/action"));
            break;
        }
        case "echeq.deposit.action": {
            yield call(echeqTransactionActions, idActivity, transaction);
            yield put(routerActions.push("/echeqs/transaction/action"));
            break;
        }
        case "echeq.custody.action": {
            yield call(echeqTransactionActions, idActivity, transaction);
            yield put(routerActions.push("/echeqs/transaction/action"));
            break;
        }
        case "echeq.rescue.action": {
            yield call(echeqTransactionActions, idActivity, transaction);
            yield put(routerActions.push("/echeqs/transaction/action"));
            break;
        }
        case "emit.echeq.activity": {
            yield call(emitEcheqTransactionData, idActivity, transaction);
            yield put(routerActions.push("/echeqs/failed/emit"));
            break;
        }
        case "endorse.echeq.activity": {
            yield call(createEcheqTransactionData, idActivity, transaction);
            yield put(routerActions.push("/echeqs/failed/endorse"));
            break;
        }
        case "negotiate.echeq.activity": {
            yield call(createEcheqTransactionData, idActivity, transaction);
            yield put(routerActions.push("/echeqs/failed/endorse"));
            break;
        }
        case "echeq.repudiate-cesion.action": {
            yield call(echeqTransactionActions, idActivity, transaction);
            yield put(routerActions.push("/echeqs/transaction/action"));
            break;
        }
        case "echeq.admit-cesion.action": {
            yield call(echeqTransactionActions, idActivity, transaction);
            yield put(routerActions.push("/echeqs/transaction/action"));
            break;
        }
        case "cesion.echeq.activity": {
            yield call(createEcheqTransactionData, idActivity, transaction);
            yield put(routerActions.push("/echeqs/failed/cesion"));
            break;
        }
        case "echeq.request-return.action": {
            yield call(echeqTransactionActions, idActivity, transaction);
            yield put(routerActions.push("/echeqs/transaction/action"));
            break;
        }
        case "echeq.anulate-return.action": {
            yield call(echeqTransactionActions, idActivity, transaction);
            yield put(routerActions.push("/echeqs/transaction/action"));
            break;
        }
        case "echeq.repudiate-return.action": {
            yield call(echeqTransactionActions, idActivity, transaction);
            yield put(routerActions.push("/echeqs/transaction/action"));
            break;
        }
        case "accept.return.echeqs": {
            yield call(echeqAcceptReturnActions, idActivity, transaction);
            yield put(routerActions.push("/echeqs/return/accept/transaction"));
            break;
        }
        case "echeq.request-certificate.action": {
            yield call(echeqTransactionActions, idActivity, transaction);
            yield put(routerActions.push("/echeqs/transaction/action"));
            break;
        }
        case "payments.makePayment": {
            yield call(servicePaymentsTransactionActions, idActivity, transaction);
            yield put(routerActions.push("/newPayment/confirmation"));
            break;
        }
        case "credin.sentRequest.send": {
            yield put(
                debinActions.loadConfirmCredin({
                    ...transaction.data,
                    currency: transaction.data.currency,
                    amount: transaction.data.amount,
                    creatorFullName,
                    signatures,
                    userEditorFullName,
                    idTransactionStatus,
                    modificationDateTime,
                    pendingDispatch,
                    creationDate: creationDateTime,
                    idTransaction: transaction.idTransaction,
                    idActivity,
                }),
            );
            yield put(routerActions.push("/confirmCredin/transaction"));
            break;
        }
        case "debin.incoming.accept.send": {
            yield put(
                debinActions.loadConfirmIncomingDebin({
                    ...transaction.data,
                    currency: transaction.data.amount.currency,
                    amount: transaction.data.amount.quantity,
                    creatorFullName,
                    signatures,
                    userEditorFullName,
                    idTransactionStatus,
                    modificationDateTime,
                    creationDate: creationDateTime,
                    idTransaction: transaction.idTransaction,
                    idActivity,
                }),
            );
            yield put(routerActions.push("/confirmIncomingDebin/transaction"));
            break;
        }
        case "account.checks": {
            yield put(
                checksActions.checksRequestData({
                    ...transaction.data,
                    creatorFullName,
                    signatures,
                    idTransaction: transaction.idTransaction,
                    creationDate: creationDateTime,
                    idActivity,
                    pendingDispatch,
                    userEditorFullName,
                    idTransactionStatus,
                    modificationDateTime,
                }),
            );
            yield put(routerActions.push("/checks/transaction"));
            break;
        }
        case "transfers.internal.send": {
            yield put(
                transferActions.loadTransfer({
                    ...transaction.data,
                    currency: transaction.data.amount.currency,
                    amount: transaction.data.amount.quantity,
                    creatorFullName,
                    signatures,
                    creationDate: creationDateTime,
                    idTransaction: transaction.idTransaction,
                    idActivity,
                    pendingDispatch,
                    userEditorFullName,
                    idTransactionStatus,
                    modificationDateTime,
                }),
            );
            yield put(routerActions.replace("/transfer/failed"));
            break;
        }
        case "transfers.internal.tad.send": {
            yield put(
                transferActions.loadTransfer({
                    ...transaction.data,
                    currency: transaction.data.amount.currency,
                    amount: transaction.data.amount.quantity,
                    creatorFullName,
                    signatures,
                    creationDate: creationDateTime,
                    idTransaction: transaction.idTransaction,
                    idActivity,
                    pendingDispatch,
                    userEditorFullName,
                    idTransactionStatus,
                    modificationDateTime,
                }),
            );
            yield put(routerActions.replace("/transfer/failed"));
            break;
        }
        case "transfers.thirdParties.send": {
            yield put(
                transferActions.loadExternalTransfer({
                    ...transaction.data,
                    currency: transaction.data.amount.currency,
                    amount: transaction.data.amount.quantity,
                    creatorFullName,
                    signatures,
                    creationDate: creationDateTime,
                    idTransaction: transaction.idTransaction,
                    idActivity,
                    pendingDispatch,
                    userEditorFullName,
                    idTransactionStatus,
                    modificationDateTime,
                }),
            );
            yield put(routerActions.replace("/transfer/failed"));
            break;
        }
        case "transfers.thirdParties.tad.send": {
            yield put(
                transferActions.loadExternalTransfer({
                    ...transaction.data,
                    currency: transaction.data.amount.currency,
                    amount: transaction.data.amount.quantity,
                    creatorFullName,
                    signatures,
                    creationDate: creationDateTime,
                    idTransaction: transaction.idTransaction,
                    idActivity,
                    pendingDispatch,
                    userEditorFullName,
                    idTransactionStatus,
                    modificationDateTime,
                }),
            );
            yield put(routerActions.replace("/transfer/failed"));
            break;
        }
        case "pay.multiline.send": {
            const isCancelling = yield select(formSelectors.getIsCancellingTransaction);
            if (isCancelling) {
                yield put(
                    notificationActions.showNotification(i18n.get("thirdpayment.send.cancelled.message"), "success", [
                        "form",
                    ]),
                );
                yield put(routerActions.push("/payThirdParties/list"));
            } else {
                yield put(
                    thirdPaymentActions.loadConfirmThirdPayment({
                        ...transaction.data,
                        currency: transaction.data.currency,
                        amount: transaction.data.amount,
                        creatorFullName,
                        userEditorFullName,
                        idTransactionStatus,
                        modificationDateTime,
                        creationDate: creationDateTime,
                        idTransaction: transaction.idTransaction,
                        reference: transaction.data.reference,
                        idActivity,
                        pendingLines: transaction?.data?.pendingLines,
                        processedFileData: transaction?.data?.processedFileData,
                        idUserCreator: transaction?.idUserCreator,
                    }),
                );
                if (newTransaction) {
                    yield put(
                        notificationActions.showNotification(i18n.get("thirdpayment.send.success.message"), "success", [
                            "forms",
                        ]),
                    );
                }
                yield put(routerActions.push("/payThirdParties/confirmation"));
            }
            break;
        }
        case "fundcorp.register.rescue.request": {
            yield call(fundcorpRescueTransactionActions, idActivity, transaction);
            yield put(routerActions.push("/fundcorpOperations/rescue/failed"));
            break;
        }

        case "fundcorp.register.suscription.request": {
            yield call(fundcorpSubscribeTransactionActions, idActivity, transaction);
            yield put(routerActions.push("/subscribe/failed"));
            break;
        }
        case "emit.massive.echeqs.send": {
            yield call(echeqMassiveTransactionActions, idActivity, transaction);
            yield put(routerActions.push("/echeqs/emitMassive/failed"));
            break;
        }
        case "transfer.multiple.send": {
            yield put(
                multipleTransferActions.loadMultipleTransfer({
                    ...transaction.data,
                    debitAccount: transaction.data.account,
                    creatorFullName,
                    signatures,
                    creationDate: creationDateTime,
                    idTransaction: transaction.idTransaction,
                    idActivity,
                    pendingDispatch,
                    userEditorFullName,
                    idTransactionStatus,
                    modificationDateTime,
                    idUserCreator: transaction?.idUserCreator,
                }),
            );
            yield put(routerActions.replace("/transfer/multiple/failed"));
            break;
        }
        default: {
            break;
        }
    }
}

export function* redirectScheduler(idActivity, transaction) {
    const creatorFullName = transaction.userCreatorLastName.concat(" ", transaction.userCreatorFirstName);
    const userEditorFullName = transaction.userEditorLastName.concat(" ", transaction.userEditorFirstName);
    const { creationDateTime, signatures, pendingDispatch, idTransactionStatus, modificationDateTime } = transaction;

    switch (idActivity) {
        case "transfers.internal.send": {
            yield put(
                transferActions.loadTransfer({
                    ...transaction.data,
                    currency: transaction.data.amount.currency,
                    amount: transaction.data.amount.quantity,
                    creatorFullName,
                    signatures,
                    creationDate: creationDateTime,
                    idTransaction: transaction.idTransaction,
                    idActivity,
                    pendingDispatch,
                    userEditorFullName,
                    idTransactionStatus,
                    modificationDateTime,
                }),
            );
            yield put(routerActions.replace("/transfer/scheduled"));
            break;
        }
        case "transfers.internal.tad.send": {
            yield put(
                transferActions.loadTransfer({
                    ...transaction.data,
                    currency: transaction.data.amount.currency,
                    amount: transaction.data.amount.quantity,
                    creatorFullName,
                    signatures,
                    creationDate: creationDateTime,
                    idTransaction: transaction.idTransaction,
                    idActivity,
                    pendingDispatch,
                    userEditorFullName,
                    idTransactionStatus,
                    modificationDateTime,
                }),
            );
            yield put(routerActions.replace("/transfer/scheduled"));
            break;
        }
        case "transfers.thirdParties.send": {
            yield put(
                transferActions.loadExternalTransfer({
                    ...transaction.data,
                    currency: transaction.data.amount.currency,
                    amount: transaction.data.amount.quantity,
                    creatorFullName,
                    signatures,
                    creationDate: creationDateTime,
                    idTransaction: transaction.idTransaction,
                    idActivity,
                    pendingDispatch,
                    userEditorFullName,
                    idTransactionStatus,
                    modificationDateTime,
                }),
            );
            yield put(routerActions.replace("/transfer/scheduled"));
            break;
        }
        case "transfers.thirdParties.tad.send": {
            yield put(
                transferActions.loadExternalTransfer({
                    ...transaction.data,
                    currency: transaction.data.amount.currency,
                    amount: transaction.data.amount.quantity,
                    creatorFullName,
                    signatures,
                    creationDate: creationDateTime,
                    idTransaction: transaction.idTransaction,
                    idActivity,
                    pendingDispatch,
                    userEditorFullName,
                    idTransactionStatus,
                    modificationDateTime,
                }),
            );
            yield put(routerActions.replace("/transfer/scheduled"));
            break;
        }
        case "fundcorp.register.rescue.request": {
            yield call(fundcorpRescueTransactionActions, idActivity, transaction);
            yield put(routerActions.push("/fundcorpOperations/rescue/scheduled"));
            break;
        }
        case "fundcorp.register.suscription.request": {
            yield call(fundcorpRescueTransactionActions, idActivity, transaction);
            yield put(routerActions.push("/subscribe/scheduled"));
            break;
        }
        default: {
            break;
        }
    }
}

function* echeqAcceptReturnActions(idActivity, transaction) {
    const creatorFullName = transaction.userCreatorLastName.concat(" ", transaction.userCreatorFirstName);
    const {
        creationDateTime,
        pendingDispatch,
        idTransactionStatus,
        modificationDateTime,
        submitDateTimeAsString,
    } = transaction;
    const userEditorFullName = transaction.userEditorLastName.concat(" ", transaction.userEditorFirstName);

    const response = yield call(bankedBeneficiary, {
        document: transaction.data.echeqList[0].cuit_solic_devol,
        type: "CUIT",
    });

    yield put(
        checksActions.transactionEcheq({
            ...transaction.data,
            userEditorFullName,
            creatorFullName,
            echeq: transaction.data.echeqList[0],
            idTransaction: transaction.idTransaction,
            creationDate: creationDateTime,
            idActivity,
            pendingDispatch,
            modificationDateTime,
            idTransactionStatus,
            solicitantName: response.data.data.beneficiary.beneficiario_razon_social,
            submitDateTimeAsString,
        }),
    );
}
function* echeqTransactionActions(idActivity, transaction) {
    const creatorFullName = transaction.userCreatorLastName.concat(" ", transaction.userCreatorFirstName);
    const userEditorFullName = transaction.userEditorLastName.concat(" ", transaction.userEditorFirstName);
    const {
        creationDateTime,
        pendingDispatch,
        idTransactionStatus,
        modificationDateTime,
        submitDateTimeAsString,
    } = transaction;

    yield put(
        checksActions.transactionEcheq({
            ...transaction.data,
            userEditorFullName,
            creatorFullName,
            idTransaction: transaction.idTransaction,
            creationDate: creationDateTime,
            idActivity,
            pendingDispatch,
            modificationDateTime,
            idTransactionStatus,
            submitDateTimeAsString,
        }),
    );
}

function* servicePaymentsTransactionActions(idActivity, transaction) {
    const creatorFullName = transaction.userCreatorLastName.concat(" ", transaction.userCreatorFirstName);
    const userEditorFullName = transaction.userEditorLastName.concat(" ", transaction.userEditorFirstName);
    const { creationDateTime, idTransactionStatus, modificationDateTime } = transaction;

    yield put(
        servicePaymentsActions.loadConfirmServicePayments({
            ...transaction.data,
            summaryInfo: transaction.data.summaryInfo,
            creatorFullName,
            userEditorFullName,
            idTransactionStatus,
            modificationDateTime,
            creationDate: creationDateTime,
            idTransaction: transaction.idTransaction,
            idActivity,
        }),
    );
}

function* fundcorpRescueTransactionActions(idActivity, transaction) {
    const creatorFullName = transaction.userCreatorLastName.concat(" ", transaction.userCreatorFirstName);
    const userEditorFullName = transaction.userEditorLastName.concat(" ", transaction.userEditorFirstName);
    const { creationDateTime, idTransactionStatus, modificationDateTime } = transaction;

    yield put(
        fundcorpActions.loadConfirmRescue({
            ...transaction.data,
            summary: transaction.data.summary,
            creatorFullName,
            userEditorFullName,
            idTransactionStatus,
            modificationDateTime,
            creationDate: creationDateTime,
            idTransaction: transaction.idTransaction,
            idActivity,
        }),
    );
}

function* fundcorpSubscribeTransactionActions(idActivity, transaction) {
    const creatorFullName = transaction.userCreatorLastName.concat(" ", transaction.userCreatorFirstName);
    const userEditorFullName = transaction.userEditorLastName.concat(" ", transaction.userEditorFirstName);
    const { creationDateTime, idTransactionStatus, modificationDateTime } = transaction;

    const messageCancellation =
        idTransactionStatus &&
        idTransactionStatus === "FINISHED" &&
        validateMessageCancellation(transaction.data?.numFund, transaction.data?.fundName);

    yield put(
        fundcorpActions.loadConfirmSubscribe({
            ...transaction.data,
            summary: transaction.data.summary,
            creatorFullName,
            userEditorFullName,
            idTransactionStatus,
            modificationDateTime,
            creationDate: creationDateTime,
            idTransaction: transaction.idTransaction,
            idActivity,
            messageCancellation,
        }),
    );
}

function* createEcheqTransactionData(idActivity, transaction) {
    const {
        creationDateTime,
        signatures,
        pendingDispatch,
        idTransactionStatus,
        modificationDateTime,
        submitDateTimeAsString,
    } = transaction;
    const creatorFullName = transaction.userCreatorLastName.concat(" ", transaction.userCreatorFirstName);
    const userEditorFullName = transaction.userEditorLastName.concat(" ", transaction.userEditorFirstName);
    const { beneficiary } = transaction.data;

    const beneficiaryString = beneficiary?.split(" ");

    let document = {};

    if (beneficiaryString && beneficiaryString.length > 1) {
        document = {
            document: beneficiary.split(" ")[1],
            type: beneficiary.split(" ")[0],
        };
    } else {
        document = {
            document: beneficiary.split(" ")[0],
            type: "CUIT",
        };
    }

    const response = yield call(bankedBeneficiary, document);

    const beneficiaryData = response.data.data.beneficiary || {
        beneficiario_documento_tipo: document.type,
        beneficiario_documento: document.document,
        beneficiario_razon_social: "No Encontrado",
    };

    const beneficiaryInfo = {
        document: beneficiaryData.beneficiario_documento_tipo
            ? beneficiaryData.beneficiario_documento_tipo.concat(" ", beneficiaryData.beneficiario_documento)
            : "",
        reference: beneficiaryData.beneficiario_razon_social || "",
    };
    yield put(
        checksActions.transactionEcheq({
            ...transaction.data,
            userEditorFullName,
            beneficiary: beneficiaryInfo,
            echeq: transaction.data.echeqList[0],
            signatures,
            creatorFullName,
            idTransaction: transaction.idTransaction,
            creationDate: creationDateTime,
            idActivity,
            pendingDispatch,
            modificationDateTime,
            idTransactionStatus,
            submitDateTimeAsString,
        }),
    );
}

function* emitEcheqTransactionData(idActivity, transaction) {
    const {
        creationDateTime,
        signatures,
        pendingDispatch,
        idTransactionStatus,
        modificationDateTime,
        submitDateTimeAsString,
    } = transaction;
    const creatorFullName = transaction.userCreatorLastName.concat(" ", transaction.userCreatorFirstName);
    const userEditorFullName = transaction.userEditorLastName.concat(" ", transaction.userEditorFirstName);
    const { beneficiary } = transaction.data;

    const document = {
        document: beneficiary.split(" ")[1],
        type: beneficiary.split(" ")[0],
    };
    const response = yield call(bankedBeneficiary, document);

    const accountResponse = yield call(accountsMiddleware.getAccountInfo, transaction.data.emision);

    const { account } = accountResponse.data.data;

    const accountFull = account.number.concat(
        " ",
        account.productType,
        " ",
        i18n.get(`currency.label.${account.currency}`),
    );
    const beneficiaryData = response.data.data.beneficiary || {
        beneficiario_documento_tipo: document.type,
        beneficiario_documento: document.document,
        beneficiario_razon_social: "No Encontrado",
    };

    const beneficiaryInfo = {
        document: beneficiaryData.beneficiario_documento_tipo
            ? beneficiaryData.beneficiario_documento_tipo.concat(" ", beneficiaryData.beneficiario_documento)
            : "",
        reference: beneficiaryData.beneficiario_razon_social || "",
    };
    yield put(
        checksActions.transactionEcheq({
            userEditorFullName,
            ...transaction.data,
            beneficiary: beneficiaryInfo,
            signatures,
            accountFull,
            creatorFullName,
            idTransaction: transaction.idTransaction,
            creationDate: creationDateTime,
            idActivity,
            pendingDispatch,
            modificationDateTime,
            idTransactionStatus,
            submitDateTimeAsString,
        }),
    );
}

function* loadRecurrencyDebin(idActivity, transaction) {
    const { creationDateTime, signatures, idTransactionStatus, modificationDateTime } = transaction;
    const creatorFullName = transaction.userCreatorLastName.concat(" ", transaction.userCreatorFirstName);
    const userEditorFullName = transaction.userEditorLastName.concat(" ", transaction.userEditorFirstName);

    const accountResponse = yield call(accountsMiddleware.getAccountInfo, transaction.data.cbu);
    const sellerResponse = yield call(debinMiddleware.getSeller, transaction.data.seller);
    const { account } = accountResponse.data.data;

    const accountFull = account.number.concat(
        " ",
        account.productType,
        " ",
        i18n.get(`currency.label.${account.currency}`),
    );

    yield put(
        debinActions.setdebinPrestacionData({
            userEditorFullName,
            ...transaction.data,
            signatures,
            sellerInfo: sellerResponse?.data?.data?.seller && JSON.parse(sellerResponse?.data?.data?.seller),
            accountFull,
            creatorFullName,
            idTransaction: transaction.idTransaction,
            creationDate: creationDateTime,
            idActivity,
            modificationDateTime,
            idTransactionStatus,
        }),
    );
}

function* actionRecurrencyDebin(idActivity, transaction) {
    const { creationDateTime, signatures, idTransactionStatus, modificationDateTime } = transaction;
    const creatorFullName = transaction.userCreatorLastName.concat(" ", transaction.userCreatorFirstName);
    const userEditorFullName = transaction.userEditorLastName.concat(" ", transaction.userEditorFirstName);

    const accountResponse = yield call(accountsMiddleware.getAccountInfo, transaction.data.cbu);
    const sellerResponse = yield call(debinMiddleware.getSeller, transaction.data.seller);
    const { account } = accountResponse.data.data;

    const accountFull = account?.number?.concat(
        " ",
        account?.productType,
        " ",
        i18n.get(`currency.label.${account?.currency}`),
    );

    yield put(
        debinActions.setRecurrencyDebinAction({
            userEditorFullName,
            ...transaction.data,
            signatures,
            sellerInfo: sellerResponse?.data?.data?.seller && JSON.parse(sellerResponse?.data?.data?.seller),
            accountFull,
            creatorFullName,
            idTransaction: transaction.idTransaction,
            creationDate: creationDateTime,
            idActivity,
            modificationDateTime,
            idTransactionStatus,
        }),
    );
}

function* loadCanceledSchedule(idActivity, transaction) {
    const creatorFullName = transaction.userCreatorLastName.concat(" ", transaction.userCreatorFirstName);
    const userEditorFullName = transaction.userEditorLastName.concat(" ", transaction.userEditorFirstName);
    const { creationDateTime, signatures, pendingDispatch, idTransactionStatus, modificationDateTime } = transaction;
    const { type } = transaction.data;
    if (type && type === "subscribe") {
        yield call(fundcorpSubscribeTransactionActions, idActivity, transaction);
    } else if (type && type === "rescue") {
        yield call(fundcorpRescueTransactionActions, idActivity, transaction);
    } else {
        yield put(
            transferActions.loadScheduledTransactionCancel({
                ...transaction.data,
                creatorFullName,
                signatures,
                creationDate: creationDateTime,
                idTransaction: transaction.idTransaction,
                idActivity,
                pendingDispatch,
                userEditorFullName,
                idTransactionStatus,
                modificationDateTime,
            }),
        );
    }
}

export function* echeqMassiveTransactionActions(idActivity, transaction) {
    const creatorFullName = transaction.userCreatorLastName.concat(" ", transaction.userCreatorFirstName);
    const userEditorFullName = transaction.userEditorLastName.concat(" ", transaction.userEditorFirstName);
    const {
        creationDateTime,
        signatures,
        idTransactionStatus,
        modificationDateTime,
        pendingDispatch,
        submitDateTimeAsString,
    } = transaction;

    yield put(
        checksActions.transactionEcheq({
            ...transaction.data,
            summary: transaction.data.summary,
            creatorFullName,
            userEditorFullName,
            idTransactionStatus,
            modificationDateTime,
            signatures,
            pendingDispatch,
            creationDate: creationDateTime,
            idTransaction: transaction.idTransaction,
            idActivity,
            submitDateTimeAsString,
        }),
    );
}
