/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { shape, string, func, bool } from "prop-types";
import { connect } from "react-redux";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as transactionsActions } from "reducers/transactions";
import I18n from "pages/_components/I18n";
import FormattedDate from "pages/_components/FormattedDate";
import FormattedAmount from "pages/_components/FormattedAmount";

import Card from "pages/_components/Card/Card";
import Image from "pages/_components/Image";
import ContextMenu from "pages/_components/ContextMenu";

class TransactionsItem extends Component {
    static propTypes = {
        transaction: shape({
            cancelEnabled: string.isRequired,
            transaction: shape({
                creationDateTime: string,
                submitDateTime: string,
                activityName: string,
                idTransactionStatus: string,
            }),
            transactionAmounts: shape(),
        }).isRequired,
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
    };

    renderContexMenu = ({ idTransaction }) => {
        const { dispatch, isDesktop } = this.props;
        const items = [
            {
                label: "transactions.list.actions.delete",
                onClick: () => {
                    dispatch(transactionsActions.deleteDraftRequest(idTransaction));
                },
            },
        ];
        return <ContextMenu imageStyle="pr-0" items={items} isDesktop={isDesktop} />;
    };

    render() {
        const { transaction: propsTransaction, isDesktop } = this.props;
        const { transaction, transactionAmounts } = propsTransaction;
        const { creationDateTime, submitDateTime, activityName, idTransactionStatus, draftTransaction, data } = transaction;
        const currency = 
            activityName === "CREDIN" && draftTransaction && data.cbuList?.length ? 
                data.cbuList[0].amount.currency : 
                Object.keys(transactionAmounts)[0];
        const amount = 
            activityName === "CREDIN" && draftTransaction ? 
                data.cbuList?.reduce((acc, val) => val.amount.quantity + acc, 0) :
                transactionAmounts[currency]
        const showAmount = amount !== 0;

        return (
            <>
                {isDesktop ? (
                    <Card
                        className="card__transaction"
                        iconWithText={
                            <div className="d-flex flex-column min-width-3">
                                <Image src={`images/transfer-${idTransactionStatus}.svg`} />
                                <I18n
                                    componentProps={{ className: `transaction-status-text-${idTransactionStatus}` }}
                                    id={`transaction.status.${idTransactionStatus}`}
                                />
                            </div>
                        }
                        rightArrow={idTransactionStatus !== "DRAFT"}
                        title={
                            <div className="card__transaction-data-title">
                                <div className="d-flex">
                                    <I18n
                                        componentProps={{ className: "card__transaction-data-title-left" }}
                                        id="transactions.list.item.PENDING"
                                    />
                                    <FormattedDate
                                        className="card__transaction-data-time-left"
                                        showTime
                                        date={submitDateTime}
                                    />
                                    <I18n
                                        componentProps={{ className: "card__transaction-data-title-right" }}
                                        id="transactions.list.header.creationDate"
                                    />
                                    <FormattedDate
                                        className="card__transaction-data-time-rigth"
                                        showTime
                                        date={creationDateTime}
                                    />
                                </div>
                            </div>
                        }
                        content={
                            <div className="card__transaction-data-description">
                                {activityName === "Descontar ECHEQs" ? (
                                    <I18n id="echeqs.transaction.negotiate.label" />
                                ) : null}
                                {activityName === "Emitir Cesión" && activityName !== "Descontar ECHEQs" ? (
                                    <I18n id="echeqs.transaction.cesion.label" />
                                ) : null}
                                {activityName !== "Descontar ECHEQs" && activityName !== "Emitir Cesión" ? (
                                    <span>{activityName}</span>
                                ) : null}
                            </div>
                        }
                        rightContent={
                            <div className="card__component-item-currency d-flex align-items-center">
                                {showAmount && <FormattedAmount currency={currency} quantity={amount} />}
                                {idTransactionStatus === "DRAFT" && (
                                    <button
                                        type="button"
                                        className="btn btn-link p-0 m-0"
                                        onClick={(e) => e.stopPropagation()}>
                                        {this.renderContexMenu(transaction)}
                                    </button>
                                )}
                            </div>
                        }
                    />
                ) : (
                    <Card
                        className="card__transaction"
                        rightArrow={idTransactionStatus !== "DRAFT"}
                        iconWithText={
                            <div className="d-flex flex-column">
                                <div className="d-flex">
                                    {idTransactionStatus ? (
                                        <Image src={`images/transfer-${idTransactionStatus}.svg`} />
                                    ) : null}
                                    <I18n
                                        componentProps={{ className: `transaction-status-text-${idTransactionStatus}` }}
                                        id={`transaction.status.${idTransactionStatus}`}
                                    />
                                </div>
                                <I18n
                                    componentProps={{ className: "card__transaction-data-title-left mt-2" }}
                                    id="transactions.list.item.PENDING"
                                />
                                <I18n
                                    componentProps={{ className: "card__transaction-data-title-right" }}
                                    id="transactions.list.header.creationDate"
                                />
                            </div>
                        }
                        content={
                            <div className="card__transaction-data-description">
                                <div className="d-flex flex-column">
                                    <div>
                                        <span>{activityName}</span>
                                        {idTransactionStatus === "DRAFT" && (
                                            <button
                                                type="button"
                                                className="btn btn-link p-0 m-0"
                                                onClick={(e) => e.stopPropagation()}>
                                                {this.renderContexMenu(transaction)}
                                            </button>
                                        )}
                                    </div>
                                    <div className="d-flex flex-column align-items-end mt-2">
                                        <FormattedDate
                                            className="card__transaction-data-time-left"
                                            showTime
                                            date={submitDateTime}
                                        />
                                        <FormattedDate
                                            className="card__transaction-data-time-rigth"
                                            showTime
                                            date={creationDateTime}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        rightContent={
                            <div className="card__component-item-currency">
                                {showAmount && <FormattedAmount currency={currency} quantity={amount} />}
                            </div>
                        }
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default connect(mapStateToProps)(TransactionsItem);
