/* eslint-disable import/no-unresolved */
import moment from "moment";
import { arrayOf, bool, func, number, shape, string } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { goBack, push } from "react-router-redux";

import { actions as accountsActions, selectors as accountsSelectors } from "reducers/accounts";
import { actions as fileActions } from "reducers/files";
import {
    selectors as selectorsTransactionLines,
    actions as transactionLinesActions,
} from "reducers/form/transactionLines";
import { selectors as multilineFileSelectors } from "reducers/formFields/multilineFile";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as thirdPaymentActions, selectors as thirdPaymentSelectors } from "reducers/thirdPayment";
import { exportGenericTicketPdf, getGeneralTicketItem } from "util/transaction";

import ContextMenu from "pages/_components/ContextMenu";
import FormattedDate from "pages/_components/FormattedDate";
import SummaryData, { TYPE_DETAIL } from "pages/_components/SummaryData";
import TransactionTicket from "pages/_components/TransactionTicket";

import * as accountUtils from "util/accounts";
import * as configUtils from "util/config";
import * as i18nUtils from "util/i18n";

import TicketMultilineFiles from "./_components/TicketMultilineFiles";

class ThirdPaymentTicket extends Component {
    static propTypes = {
        isDesktop: bool.isRequired,
        isSmallDesktop: bool.isRequired,
        dispatch: func.isRequired,
        summary: shape({}).isRequired,
        loggedUser: shape({
            accessToken: string,
            defaultAvatarId: string,
            email: string,
            previousLoginInfo: arrayOf(
                shape({
                    city: string,
                    country: string,
                    date: string,
                    idUser: string,
                    ip: string,
                    latitude: number,
                    longitude: number,
                }),
            ),
        }).isRequired,
        accounts: shape({}).isRequired,
        processedFileData: shape({}).isRequired,
        history: shape({}).isRequired,
        itemsDetail: arrayOf(shape({})).isRequired,
    };

    state = {
        showDrawerMobile: false,
        processDate: null,
        limitDate: null,
        fetchingDownload: false,
        fetching: false,
    };

    componentDidMount() {
        const { summary, dispatch, loggedUser } = this.props;
        dispatch(thirdPaymentActions.setNotRememberFormData());
        if (!summary.idTransactionStatus) {
            dispatch(push(`/pendingTransaction/list`));
        } else {
            const tempDate = this.setHour(summary.dateProcess);

            const processDate = moment(summary.dateProcess).isAfter(tempDate) ? tempDate.add(1, "days") : tempDate;

            this.setState({ limitDate: tempDate, processDate });

            const countFiles = summary?.file?.length;
            const fileId =
                summary?.processedFileData?.idFile || (countFiles > 0 && summary.file[countFiles - 1].fileId);

            if (fileId) {
                dispatch(fileActions.getFileContentsRequest(fileId, false, summary?.idUserCreator));
                dispatch(transactionLinesActions.initializeEditedLines());
                dispatch(transactionLinesActions.setPageNumber(1));

                dispatch(accountsActions.listAccounts(loggedUser.userId));
            }
        }
    }

    setHour = (date) => {
        const hours = configUtils.get("payment.process.hour", "16:00:00").split(":");
        return date && hours.length === 3
            ? moment(date).set({ hour: hours[0], minute: hours[1], second: hours[2] })
            : moment(date);
    };

    rightContentTransactionScheme = (isFinished) => {
        const { isDesktop, summary } = this.props;

        if (isDesktop || isFinished) {
            return <></>;
        }
        return (
            <>
                {summary?.idTransactionStatus !== "CANCELLED" && (
                    <ContextMenu
                        isDesktop={isDesktop}
                        buttonClassName="align-self-center account-dropdown-header-font font-black-alpha ml-2"
                        items={[
                            {
                                label: "pendingTransaction.info.scheme",
                                onClick: () => this.setState({ showDrawerMobile: true }),
                            },
                        ]}
                    />
                )}
            </>
        );
    };

    centerContentMobile = () => (
        <div className="title-account-header-multiline-background-blue">
            <h1 style={{ margin: "0.2rem" }}>{i18nUtils.get("payments.payThirdParties.newPayment.title")}</h1>
        </div>
    );

    listenHistory = () => {
        const { history, dispatch } = this.props;

        const pattern1 = /\/transactions\//;

        const unlisten = history.listen((location) => {
            const nextPath = location?.pathname;

            if (pattern1.test(nextPath)) {
                dispatch(goBack());
            }
            unlisten();
        });
    };

    getAccountName = (account) => {
        const { productAlias, number: accountNumber, productType, currency } = account;
        return `${productAlias} ${accountUtils.maskedAccountNumber(accountNumber)} ${productType} ${i18nUtils.get(
            `currency.label.${currency}`,
        )}`;
    };

    buttonsDetail = () => {
        const { dispatch } = this.props;

        return [
            {
                label: "payments.payThirdParties.confirm.button",
                bsStyle: "primary",
                onClick: () => dispatch(push(`/payThirdParties/list`)),
            },
        ];
    };

    exportPdf = async () => {
        this.setState({ fetchingDownload: true });
        const ticketData = {
            ticketName: i18nUtils.get("payments.payThirdParties.ticket.title"),
            ticketBody: [...this.generateTicketBody()],
        };

        await exportGenericTicketPdf(ticketData);

        this.setState({ fetchingDownload: false });
    };

    generateTicketBody = () => {
        const { summary, accounts, processedFileData } = this.props;
        const { reference, typePayment } = summary;
        const fileData = summary.processedFileData || processedFileData;
        const { totalAmount, validLines, filesMetadata } = fileData;
        const { processDate, limitDate } = this.state;

        let accountName = null;
        let numberAccount = null;

        if (summary) {
            if (summary.debitAccount) {
                numberAccount = summary.debitAccount;
            } else {
                numberAccount = summary.account?.idProduct;
            }

            if (accounts.length > 0) {
                const loggedUserAccount = accounts.find(
                    (account) => numberAccount && numberAccount.includes(account?.idProduct),
                );
                accountName = accountUtils.getIncomingDebinAccountName(loggedUserAccount);
            }
        }

        const typePaymentOptions = [
            {
                value: `PH`,
                label: i18nUtils.get("payments.payThirdParties.newPayment.salaryPayments.label"),
            },
            {
                value: `PP`,
                label: i18nUtils.get("payments.payThirdParties.newPayment.supplierPayments.label"),
            },
        ];

        const typePaymentOption = typePaymentOptions && typePaymentOptions.find(({ value }) => value === typePayment);

        const ticketBody = [
            getGeneralTicketItem(`negotiate.transaction.date.label`, summary?.creationDate, "datefulltime"),
            getGeneralTicketItem(
                `echeq.transaction.voucher`,
                summary?.idTransaction?.substring(0, 8).toUpperCase(),
                "string",
            ),
            getGeneralTicketItem(
                `payments.payThirdParties.newPayment.type.label`,
                typePaymentOption?.label || "",
                "string",
            ),
            getGeneralTicketItem(`payments.payThirdParties.newPayment.account.label`, accountName || "", "string"),
            getGeneralTicketItem(`payments.payThirdParties.newPayment.description.label`, reference || "", "string"),
            getGeneralTicketItem(`payments.payThirdParties.newPayment.date.label`, processDate || "", "date"),
        ];

        if (filesMetadata?.fileName) {
            ticketBody.push(
                getGeneralTicketItem(
                    `forms.inputFile.massivePayments.fileName`,
                    filesMetadata.fileName || "",
                    "string",
                ),
            );
        }

        ticketBody.push(
            getGeneralTicketItem(`forms.inputFile.massivePayments.lines`, validLines || "0", "string"),
            getGeneralTicketItem(
                `forms.inputFile.massivePayments.totalAmount`,
                { ...totalAmount } || "",
                "amountFrequency",
            ),
        );

        ticketBody.push(
            getGeneralTicketItem(`payments.thirdPayment.limit.date.label`, limitDate || "", "datefulltime"),
        );

        return ticketBody;
    };

    getItemsDetail = () => {
        const { summary, accounts } = this.props;
        const { reference, typePayment } = summary;
        const { processDate } = this.state;

        let accountName = null;
        let numberAccount = null;
        if (summary) {
            if (summary.debitAccount) {
                numberAccount = summary.debitAccount;
            } else {
                numberAccount = summary.account?.idProduct;
            }

            if (accounts.length > 0) {
                const loggedUserAccount = accounts.find(
                    (account) => numberAccount && numberAccount.includes(account?.idProduct),
                );
                accountName = accountUtils.getIncomingDebinAccountName(loggedUserAccount);
            }
        }

        const typePaymentOptions = [
            {
                value: `PH`,
                label: i18nUtils.get("payments.payThirdParties.newPayment.salaryPayments.label"),
            },
            {
                value: `PP`,
                label: i18nUtils.get("payments.payThirdParties.newPayment.supplierPayments.label"),
            },
        ];
        const typePaymentOption = typePaymentOptions && typePaymentOptions.find(({ value }) => value === typePayment);

        const detail = [
            {
                label: "negotiate.transaction.date.label",
                data: <FormattedDate date={summary?.creationDate} dateFormat="dd/MM/yyyy" showTime />,
            },
            {
                label: "echeq.transaction.voucher",
                data: summary?.idTransaction?.substring(0, 8).toUpperCase(),
            },
            {
                label: "payments.payThirdParties.newPayment.type.label",
                data: typePaymentOption?.label,
            },
            {
                label: "payments.payThirdParties.newPayment.account.label",
                data: accountName !== undefined && accountName,
            },
            {
                label: "payments.payThirdParties.newPayment.description.label",
                data: reference,
            },
            {
                label: "payments.payThirdParties.newPayment.date.label",
                data: <FormattedDate date={processDate} dateFormat="dd/MM/yyyy" />,
            },
        ];

        if (summary?.idTransactionStatus !== "FINISHED") {
            detail.shift();
            return detail;
        }

        return detail;
    };

    closeMobileDrawer = () => {
        this.setState({ showDrawerMobile: false });
    };

    render() {
        const { isDesktop, summary, accounts, isSmallDesktop, itemsDetail } = this.props;
        const { showDrawerMobile, fetchingDownload, limitDate, fetching } = this.state;

        const isFinished = summary.idTransactionStatus === "FINISHED";

        return (
            <>
                <TransactionTicket
                    notificationScope="forms"
                    handleClick={this.exportPdf}
                    generateSignFunction
                    cancelButton={summary?.idTransactionStatus === "PENDING"}
                    emptyRightOption={false}
                    uniqueDownload={isFinished && isDesktop}
                    rightContent={() => this.rightContentTransactionScheme(isFinished)}
                    handleClickMessage="global.download"
                    headerClassName={!isDesktop ? "blue-main-header-mobile blue-main-title-mobile" : ""}
                    centerElement={isDesktop ? undefined : this.centerContentMobile}
                    hasInlineButtons
                    hideMobileMenu={!isDesktop}
                    summary={summary}
                    accounts={accounts}
                    buttonsDetail={this.buttonsDetail()}
                    fetching={fetching}
                    isDesktop={isDesktop}
                    isSmallDesktop={isSmallDesktop}
                    summaryTitle="payments.payThirdParties.ticket.title"
                    showDrawerMobile={showDrawerMobile}
                    closeMobileDrawer={this.closeMobileDrawer}
                    isFetchingExport={fetchingDownload}>
                    <SummaryData
                        isDesktop={isDesktop}
                        itemsDetail={this.getItemsDetail()}
                        typeDetail={isDesktop ? TYPE_DETAIL.INLINE : TYPE_DETAIL.INLINE_WITH_SPACE}
                        containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                    />
                    <TicketMultilineFiles
                        isDesktop={isDesktop}
                        summary={summary}
                        itemsDetail={itemsDetail}
                        limitDate={limitDate}
                    />
                </TransactionTicket>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    summary: thirdPaymentSelectors.getSummary(state),
    fetching: thirdPaymentSelectors.getFetching(state),
    loggedUser: sessionSelectors.getUser(state),
    accounts: accountsSelectors.getAccounts(state),
    processedFileData: multilineFileSelectors.getProcessedFileData(state),
    itemsDetail: selectorsTransactionLines.getTransactionLines(state),
});

export default connect(mapStateToProps)(ThirdPaymentTicket);
