import React, { Component } from "react";
import { connect } from "react-redux";
import { func, shape, bool, string, arrayOf, number } from "prop-types";
import { push } from "react-router-redux";

import Notification from "pages/_components/Notification";
import { accountDescription } from "util/checks";
import { actions as checkActions, selectors as checkSelector } from "reducers/checks";
import Button from "pages/_components/Button";
import * as i18n from "util/i18n";
import ChecksTransactionData from "pages/checks/ChecksTransactionData";
import ChecksSummaryData from "pages/checks/ChecksSummaryData";
import { actions as formActions } from "reducers/form";
import ModifyPendingTransactionModal from "pages/transactions/_components/ModifyPendingTransactionModal";
import { selectors as sessionSelectors } from "reducers/session";

import ContextMenu from "pages/_components/ContextMenu";
import TransactionTicket from "pages/_components/TransactionTicket";

class CheckTransaction extends Component {
    static propTypes = {
        checksRequestData: shape({}).isRequired,
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        isSmallDesktop: bool.isRequired,
        fullName: string.isRequired,
        fetchingDownload: bool.isRequired,
        loggedUser: shape({
            accessToken: string,
            defaultAvatarId: string,
            email: string,
            previousLoginInfo: arrayOf(
                shape({
                    city: string,
                    country: string,
                    date: string,
                    idUser: string,
                    ip: string,
                    latitude: number,
                    longitude: number,
                }),
            ),
        }).isRequired,
    };

    state = {
        isModalDisplayed: false,
        showDrawerMobile: false,
    };

    componentDidMount() {
        const { dispatch, checksRequestData } = this.props;
        if (!checksRequestData?.idTransaction) {
            dispatch(push("/transactions/list"));
        }
    }

    centerContentMobile = () => (
        <div className="title-account-header-multiline-background-blue">
            <h1 style={{ width: "100%", marginTop: "2px" }}>{i18n.get("new.check.request")}</h1>
        </div>
    );

    signTransaction = () => {
        const { dispatch, checksRequestData, isDesktop, fullName } = this.props;

        const signTransactionData = (
            <ChecksSummaryData
                checksRequestData={checksRequestData}
                FORM_ID="new.check"
                signature
                isDesktop={isDesktop}
                fullName={fullName}
            />
        );

        dispatch(push("/transactions/sign"));
        dispatch(
            formActions.signTransactionData({
                idTransaction: checksRequestData?.idTransaction,
                idActivity: checksRequestData.idActivity,
                idForm: "",
                content: signTransactionData,
                title: "new.check.request",
                pendingDispatch: checksRequestData?.pendingDispatch,
            }),
        );

        dispatch(
            formActions.signTransactionPreview({
                idActivity: checksRequestData.idActivity,
                idTransaction: checksRequestData.idTransaction,
                idForm: "",
                ticketData: checksRequestData,
            }),
        );
        dispatch(checkActions.checksRequestData({ ...checksRequestData, action: "sign" }));
    };

    handleDismiss = () => {
        this.setState({ isModalDisplayed: false });
    };

    rightContentTransactionScheme = (isFinished, isFailed) => {
        const { isDesktop } = this.props;

        if (isDesktop || isFinished || isFailed) {
            return <></>;
        }
        return (
            <>
                <ContextMenu
                    isDesktop={isDesktop}
                    buttonClassName="align-self-center account-dropdown-header-font font-black-alpha ml-2"
                    items={[
                        {
                            label: "pendingTransaction.info.scheme",
                            onClick: () => this.setState({ showDrawerMobile: true }),
                        },
                    ]}
                />
            </>
        );
    };

    getAccountDescription = () => {
        const { checksRequestData } = this.props;

        if (checksRequestData?.account?.number !== undefined) {
            return accountDescription(
                checksRequestData?.account.productAlias,
                checksRequestData?.account.number,
                checksRequestData?.account.currency,
                checksRequestData?.account.productType,
            );
        }
        return "";
    };

    exportPdf = () => {
        const { dispatch, checksRequestData } = this.props;
        const isElectronicChecksTypes = !checksRequestData.checksTypes?.showsDeliveryPoint;
        dispatch(
            checkActions.downloadChecksRequestTicket(
                checksRequestData?.creationDate,
                checksRequestData?.idTransaction.substring(0, 8).toUpperCase(),
                this.getAccountDescription(),
                checksRequestData?.checksTypes?.label,
                checksRequestData?.checkBookChecksAmount,
                checksRequestData?.checkBooksAmount,
                isElectronicChecksTypes ? "" : checksRequestData?.documentType.concat(" ", checksRequestData?.document),
                isElectronicChecksTypes ? "" : checksRequestData?.name,
                isElectronicChecksTypes ? "" : checksRequestData?.surname,
                isElectronicChecksTypes ? "" : i18n.get("new.check.delivery.point.description"),
                checksRequestData?.responseIBS,
            ),
        );
    };

    checkSign = () => {
        const { checksRequestData, loggedUser } = this.props;

        const { signatures } = checksRequestData;
        return signatures?.find((user) => user.idUser === loggedUser?.userId);
    };

    renderButton = () => {
        const { dispatch } = this.props;
        const signedTransaction = this.checkSign();

        return signedTransaction ? (
            <Button bsStyle="primary" onClick={() => dispatch(push(`/desktop`))} label="global.goToHome" />
        ) : (
            <Button bsStyle="primary" onClick={this.signTransaction} label="forms.signTransaction.link" />
        );
    };

    buttonsDetail = () => {
        const { dispatch, checksRequestData } = this.props;

        if (checksRequestData) {
            const { idTransactionStatus } = checksRequestData;
            if (idTransactionStatus !== "PENDING") {
                return [
                    {
                        label: "global.goToHome",
                        bsStyle: "primary",
                        onClick: () => dispatch(push("/desktop")),
                    },
                ];
            }
        }
        return [
            {
                label: "global.modify",
                bsStyle: "outline",
                onClick: () => this.setState({ isModalDisplayed: true }),
            },
        ];
    };

    render() {
        const { checksRequestData, isDesktop, dispatch, isSmallDesktop, fetchingDownload, fetching } = this.props;
        const { isModalDisplayed, showDrawerMobile } = this.state;
        const isFinished = checksRequestData?.idTransactionStatus === "FINISHED";
        const isFailed = checksRequestData?.idTransactionStatus === "FAILED";

        return (
            <>
                <Notification scopeToShow="checksConfirmation" />
                {checksRequestData?.idTransaction && (
                    <TransactionTicket
                        handleClick={this.exportPdf}
                        handleClickMessage="global.download"
                        uniqueDownload={isFinished}
                        rightContent={() => this.rightContentTransactionScheme(isFinished, isFailed)}
                        downloadImageWhite={!isDesktop}
                        headerClassName={!isDesktop ? "blue-main-header-mobile blue-main-title-mobile" : ""}
                        centerElement={isDesktop ? undefined : this.centerContentMobile}
                        hasInlineButtons
                        hideMobileMenu={!isDesktop}
                        summary={checksRequestData}
                        isFetchingExport={fetchingDownload}
                        fetching={fetching}
                        buttonsDetail={this.buttonsDetail()}
                        isDesktop={isDesktop}
                        isSmallDesktop={isSmallDesktop}
                        showDrawerMobile={showDrawerMobile}
                        closeMobileDrawer={() => this.setState({ showDrawerMobile: false })}
                        onSignTicket={this.signTransaction}
                        ignoreHomeButton
                        summaryTitle="new.check.transaction.success.label">
                        <ModifyPendingTransactionModal
                            isDesktop={isDesktop}
                            isDisplayed={isModalDisplayed}
                            handleDismiss={this.handleDismiss}
                            dispatch={dispatch}
                            idTransaction={checksRequestData.idTransaction}
                        />
                        <ChecksTransactionData isDesktop={isDesktop} />
                    </TransactionTicket>
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    checksRequestData: checkSelector.getChecksRequestData(state),
    fullName: sessionSelectors.getUser(state).userFullName,
    fetchingDownload: checkSelector.getFetchingDownload(state),
    fetching: checkSelector.getFetching(state),
    loggedUser: sessionSelectors.getUser(state),
});

export default connect(mapStateToProps)(CheckTransaction);
