import React, { Component } from "react";

import { Field, Form, withFormik } from "formik";
import Button from "pages/_components/Button";
import Credential from "pages/_components/fields/credentials/Credential";
import I18n from "pages/_components/I18n";
import Notification from "pages/_components/Notification";
import { arrayOf, bool, date, func, number, shape, string } from "prop-types";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { goBack, push } from "react-router-redux";
import { selectors as accountsSelectors } from "reducers/accounts";
import { actions as fileActions } from "reducers/files";
import {
    selectors as selectorsTransactionLines,
    actions as transactionLinesActions,
} from "reducers/form/transactionLines";
import { compose } from "redux";
import { maskedAccountNumber } from "util/accounts";
import * as i18nUtils from "util/i18n";
import Yup from "yup";

import Container from "pages/_components/Container";
import MainContainer from "pages/_components/MainContainer";
import { actions as thirdPaymentActions, selectors as thirdPaymentSelectors } from "reducers/thirdPayment";

import moment from "moment";
import FormattedDate from "pages/_components/FormattedDate";
import Head from "pages/_components/Head";
import Heading from "pages/_components/Heading";
import SummaryData, { TYPE_DETAIL } from "pages/_components/SummaryData";
import TransactionLinesList from "pages/forms/_components/_fields/_transactionlines/List";
import Multiline from "pages/forms/_components/_fields/Multilinefile";
import { Modal } from "react-bootstrap";
import { selectors as multilineFileSelectors } from "reducers/formFields/multilineFile";
import { selectors as multilineFileProcessSelectors } from "reducers/formFields/multilineFileProcess";
import { selectors as sessionSelectors } from "reducers/session";
import * as configUtils from "util/config";
import isTokenActive from "util/token";

const FORM_ID = "newPaymentSummary";

class NewPaymentSummary extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        itemsDetail: arrayOf({}).isRequired,
        summary: shape({
            account: string.isRequired,
            amount: number.isRequired,
            currency: string.isRequired,
            description: string,
            typePayment: string,
            file: arrayOf(
                shape({
                    fileId: number,
                    fileName: string,
                    fileSize: number,
                }),
            ),
            dateProcess: date,
        }).isRequired,
        processedFileData: shape({}).isRequired,
        isSubmitting: bool,
        loggedUser: shape({
            accessToken: string,
            defaultAvatarId: string,
            email: string,
            previousLoginInfo: arrayOf(
                shape({
                    city: string,
                    country: string,
                    date: string,
                    idUser: string,
                    ip: string,
                    latitude: number,
                    longitude: number,
                }),
            ),
        }).isRequired,
    };

    static defaultProps = {
        isSubmitting: false,
    };

    state = {
        showModal: false,
        limitDate: null,
    };

    componentDidMount() {
        const { dispatch, summary, processedFileData, loggedUser } = this.props;
        const countFiles = summary?.file?.length;
        const fileId = processedFileData?.idFile || (countFiles > 0 && summary.file[countFiles - 1].fileId);

        dispatch(thirdPaymentActions.setRememberFormData());

        if (fileId) {
            dispatch(fileActions.getFileContentsRequest(fileId, false, loggedUser.userId));
            dispatch(transactionLinesActions.initializeEditedLines());
            dispatch(transactionLinesActions.setPageNumber(1));
        }

        if (summary) {
            const tempDate = this.setHour(summary.dateProcess);

            this.setState({ limitDate: tempDate });
        }
    }

    setHour = (paymentDate) => {
        const hours = configUtils.get("payment.process.hour", "16:00:00").split(":");
        return paymentDate && hours.length === 3
            ? moment(paymentDate).set({ hour: hours[0], minute: hours[1], second: hours[2] })
            : moment(paymentDate);
    };

    getAccountDescription({ numberAccount, productType, currency }) {
        const { dispatch } = this.props;
        if (!numberAccount) {
            dispatch(push("/transfer"));
            return "";
        }
        return `${maskedAccountNumber(numberAccount)} ${productType} ${i18nUtils.get(`currency.label.${currency}`)}`;
    }

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    continueButton = () => {
        const { isSubmitting, isDesktop } = this.props;
        return (
            <Col className="confirmation__content-button" lg={12} md={12} sm={12}>
                <div className="admin-content-center">
                    <Button
                        image="images/arrowRight.svg"
                        imageStyle={isDesktop ? "mr-2" : "mr-1"}
                        type="submit"
                        bsStyle="primary"
                        label="global.confirm"
                        loading={isSubmitting}
                        className="text-capitalize"
                    />
                </div>
            </Col>
        );
    };

    credentials = () => {
        const { isDesktop } = this.props;
        return (
            <>
                {!isTokenActive(!isDesktop) ? (
                    <div className="admin-content-center">
                        <I18n id="transfer.summary.confirm.label" component="p" />
                    </div>
                ) : (
                    <div className="admin-content-center pb-3">
                        <I18n
                            id="confirmation.withoutotp.text"
                            component="p"
                            componentProps={{ className: "confirmation__text-without-otp" }}
                        />
                    </div>
                )}
                <div className="admin-content-center">
                    <Field
                        placeholderText={i18nUtils.get(`confirmation.otp.placeholder`)}
                        name="otp"
                        type="otp"
                        idForm={FORM_ID}
                        component={Credential}
                    />
                </div>
            </>
        );
    };

    getAccountDescriptionDetail = (account) => {
        const { productType, currency } = account;

        return `${maskedAccountNumber(account.number)} ${productType} ${i18nUtils.get(`currency.label.${currency}`)}`;
    };

    getItemsDetail = () => {
        const {
            summary: { typePayment, account, description, dateProcess },
        } = this.props;

        const typePaymentOptions = [
            {
                value: `PH`,
                label: i18nUtils.get("payments.payThirdParties.newPayment.salaryPayments.label"),
            },
            {
                value: `PP`,
                label: i18nUtils.get("payments.payThirdParties.newPayment.supplierPayments.label"),
            },
        ];
        const typePaymentOption = typePaymentOptions && typePaymentOptions.find(({ value }) => value === typePayment);

        return [
            {
                label: "payments.payThirdParties.newPayment.type.label",
                data: typePaymentOption?.label,
            },
            {
                label: "payments.payThirdParties.newPayment.account.label",
                data: account !== undefined && this.getAccountDescriptionDetail(account),
            },
            {
                label: "payments.payThirdParties.newPayment.description.label",
                data: description,
            },
            {
                label: "payments.payThirdParties.newPayment.date.label",
                data: <FormattedDate date={dateProcess} dateFormat="dd/MM/yyyy" />,
            },
        ];
    };

    renderLimitDate = () => {
        const { limitDate } = this.state;
        return (
            <Col xs={12} className="px-0">
                <Heading.DataGroup
                    containerClassName="transaction-ticket-detail data-wrapper-flex space-between"
                    labelClassName="data-label-medium pr-2"
                    dataClassName="data-desc"
                    label="payments.thirdPayment.limit.date.label"
                    data={<FormattedDate date={limitDate} dateFormat="dd/MM/yyyy" showTime timeWithoutSeconds />}
                />
            </Col>
        );
    };

    showModal = () => {
        this.setState({ showModal: true });
    };

    closeModal = () => {
        this.setState({ showModal: false });
    };

    centerContentMobile = () => (
        <div className="title-account-header-multiline-background-blue">
            <h1 className="w-100 m-0">{i18nUtils.get("payments.payThirdParties.newPayment.title")}</h1>
        </div>
    );

    render() {
        const { isDesktop, itemsDetail } = this.props;
        const { showModal } = this.state;

        return (
            <>
                <Notification scopeToShow="third" />
                <Head
                    onBack={this.handleBack}
                    headerClassName={!isDesktop ? "blue-main-header-mobile" : "h-auto"}
                    centerElement={!isDesktop ? this.centerContentMobile : undefined}
                    navStyle="default pl-0"
                />
                {isDesktop && (
                    <h1 className="m-0 mb-3">{i18nUtils.get("payments.payThirdParties.newPayment.title")}</h1>
                )}

                <MainContainer>
                    <Modal show={showModal} className="payment-modal">
                        <Modal.Body bsClass="modal-body flex-column">
                            <TransactionLinesList detailPreview onClose={this.closeModal} lines={itemsDetail} />
                        </Modal.Body>
                    </Modal>
                    <div>
                        <Form autoComplete="off" className="confirmation__container">
                            <Container
                                className={!isDesktop && "flex-grow align-items-center cmf-container-white mt-4"}
                                rowClassName={!isDesktop && "justify-content-center"}
                                gridClassName={isDesktop && "confirmation__form confirmation__box"}>
                                <Col
                                    sm={12}
                                    md={5}
                                    lg={12}
                                    className={!isDesktop && "cmf-container-white px-2 new-payment-summary-token"}>
                                    <SummaryData
                                        isDesktop={isDesktop}
                                        title="payments.payThirdParties.summary.checkInfo.label"
                                        itemsDetail={this.getItemsDetail()}
                                        typeDetail={isDesktop ? TYPE_DETAIL.INLINE : TYPE_DETAIL.INLINE_WITH_SPACE}
                                    />
                                    <Field
                                        name="file"
                                        component={Multiline}
                                        type="multilinefile"
                                        mode="preview"
                                        formVersion="1"
                                        sub_type="salaryPayment"
                                        formTitle="salaryPayment"
                                        allowMultiple="false"
                                        acceptedFileTypes="text/plain"
                                        useForTotalAmount="true"
                                        maxFileSizeMB="4"
                                        idField="file"
                                        idForm="salaryPayment"
                                        isRequired
                                        isDesktop={isDesktop}
                                        detailButtonLabel="payments.payThirdParties.summary.buttonDetail.label"
                                        openModal={this.showModal}
                                        additionalFields={this.renderLimitDate()}
                                    />
                                </Col>
                                {isDesktop && (
                                    <>
                                        <Col md={5} lg={12}>
                                            {this.credentials()}
                                        </Col>
                                        <Container
                                            className="flex-grow align-items-center w-100"
                                            gridClassName="form-content">
                                            <Col lg={12} md={12} sm={12} className="px-0">
                                                {this.continueButton()}
                                            </Col>
                                        </Container>
                                    </>
                                )}
                            </Container>

                            {!isDesktop && (
                                <Container
                                    className="container--layaut flex-grow align-items-center cmf-container-white without-margin-top mx-1"
                                    gridClassName="form-content"
                                    style={!isDesktop && { width: "100%" }}>
                                    <Col className="px-2" lg={4} md={5} sm={12}>
                                        <Col lg={4} md={5} sm={12}>
                                            {this.credentials()}
                                        </Col>
                                        {this.continueButton()}
                                    </Col>
                                </Container>
                            )}
                        </Form>
                    </div>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    accounts: accountsSelectors.getAccounts(state),
    summary: thirdPaymentSelectors.getSummary(state),
    itemsDetail: selectorsTransactionLines.getTransactionLines(state),
    processedFileData: multilineFileSelectors.getProcessedFileData(state),
    pendingLines: multilineFileProcessSelectors.getPendingLines(state),
    fetching: thirdPaymentSelectors.getFetching(state),
    loggedUser: sessionSelectors.getUser(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: () => ({ otp: "" }),
        validationSchema: () =>
            Yup.object().shape({
                otp: Yup.string().required(i18nUtils.get(`${FORM_ID}.otp.error.required`)),
            }),
        handleSubmit: ({ otp }, formikBag) => {
            const { dispatch, summary, processedFileData } = formikBag.props;
            const { validLines: pendingLines } = processedFileData;

            dispatch(
                thirdPaymentActions.validateThirdPay({ ...summary, formikBag, processedFileData, pendingLines }, otp),
            );
        },
    }),
)(NewPaymentSummary);
