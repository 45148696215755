/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import { connect } from "react-redux";
import { func, shape, bool } from "prop-types";
import { selectors } from "reducers/checks";
import * as i18n from "util/i18n";
import { push, goBack } from "react-router-redux";
import EmitEcheqTransactionData from "pages/checks/Echeqs/EcheqTransactions/EmitEcheqTransactionData";
import { actions as formActions } from "reducers/form";
import ModifyPendingTransactionModal from "pages/transactions/_components/ModifyPendingTransactionModal";
import { exportGenericTicketPdf, getGeneralTicketItem } from "util/transaction";
import ContextMenu from "pages/_components/ContextMenu";
import TransactionTicket from "pages/_components/TransactionTicket";
import { echeqConceptLabel } from "util/checks";

const FORM_ID = "echeq.transaction";

class EmitEcheqTransaction extends Component {
    static propTypes = {
        transactionData: shape({}).isRequired,
        dispatch: func.isRequired,
        isDesktop: bool.isRequired,
        isSmallDesktop: bool.isRequired,
        history: shape({
            listen: func.isRequired,
        }).isRequired,
        goBackEcheqs: bool.isRequired,
    };

    state = {
        isModalDisplayed: false,
        showDrawerMobile: false,
        fetchingDownload: false,
    };

    componentDidMount() {
        const { dispatch, transactionData } = this.props;
        if (!transactionData.idTransaction) {
            dispatch(push("/transactions/list"));
        }
    }

    centerContentMobile = () => (
        <div>
            <h1 className="m-0">{i18n.get("echeq.emit")}</h1>
        </div>
    );

    summaryDataComponent = () => <EmitEcheqTransactionData />;

    signTransaction = () => {
        const { dispatch, transactionData } = this.props;

        const signTransactionData = this.summaryDataComponent();

        dispatch(push("/transactions/sign"));
        dispatch(
            formActions.signTransactionData({
                idTransaction: transactionData?.idTransaction,
                idActivity: transactionData?.idActivity,
                idForm: "",
                content: signTransactionData,
                title: "emit.echeq.activity",
                pendingDispatch: transactionData?.pendingDispatch,
            }),
        );

        dispatch(
            formActions.signTransactionPreview({
                idActivity: transactionData?.idActivity,
                idTransaction: transactionData?.idTransaction,
                idForm: "",
                ticketData: transactionData,
            }),
        );
    };

    rightContentTransactionScheme = () => {
        const { isDesktop, transactionData } = this.props;

        if (transactionData.idTransactionStatus === "FINISHED") {
            return (
                <ContextMenu
                    isDesktop={isDesktop}
                    buttonClassName="align-self-center account-dropdown-header-font font-black-alpha ml-2"
                    items={[
                        {
                            label: "global.download",
                            onClick: this.exportPdf,
                        },
                    ]}
                />
            );
        }

        return (
            <>
                {transactionData?.idTransactionStatus !== "CANCELLED" && (
                    <ContextMenu
                        isDesktop={isDesktop}
                        buttonClassName="align-self-center account-dropdown-header-font font-black-alpha ml-2"
                        items={[
                            {
                                label: "pendingTransaction.info.scheme",
                                onClick: () => this.setState({ showDrawerMobile: true }),
                            },
                        ]}
                    />
                )}
            </>
        );
    };

    exportPdf = async () => {
        this.setState({ fetchingDownload: true });
        const { transactionData } = this.props;

        let checksEmited = [];

        const echeqsList = JSON.parse(transactionData?.echeqNumeration);
        for (let i = 0; i < echeqsList?.length; i++) {
            checksEmited = [
                ...checksEmited,
                getGeneralTicketItem(`${FORM_ID}.checkbook`, echeqsList[i]?.chequera, "string"),
                getGeneralTicketItem(`${FORM_ID}.echeq.number`, echeqsList[i]?.cheque_numero, "string"),
                getGeneralTicketItem(
                    `${FORM_ID}.check.amount.label`,
                    transactionData.echeqsToEmit[i]?.amount,
                    "amountARS",
                ),
                getGeneralTicketItem(
                    `${FORM_ID}.echeq.id`,
                    transactionData.emittedEcheqs[echeqsList[i]?.cheque_numero],
                    "string",
                ),
                ...(transactionData?.modes === "MULT"
                    ? [
                          getGeneralTicketItem(
                              `${FORM_ID}.echeq.agrupador_id`,
                              transactionData?.emittedEcheqs?.echeqExtraData
                                  ? transactionData.emittedEcheqs?.echeqExtraData[echeqsList[i]?.cheque_numero]
                                        ?.agrupador_id
                                  : "",
                              "string",
                          ),
                      ]
                    : []),
                getGeneralTicketItem(`${FORM_ID}.echeq.cmc7`, echeqsList[i]?.cmc7, "string"),
            ];
        }
        const ticketData = {
            ticketName: i18n.get("echeqs.transaction.emit.label"),
            ticketBody: [
                getGeneralTicketItem(`${FORM_ID}.date.time`, transactionData?.creationDate, "datefulltime"),
                getGeneralTicketItem(
                    `${FORM_ID}.transaction`,
                    transactionData?.idTransaction.substring(0, 8).toUpperCase(),
                    "string",
                ),
                getGeneralTicketItem("echeqs.emit.emision.label", transactionData?.accountFull, "string"),
                getGeneralTicketItem("echeq.transaction.environment.cuit", transactionData?.environmentCuit, "string"),
                getGeneralTicketItem(
                    `${FORM_ID}.beneficiary`,
                    transactionData?.beneficiary?.reference?.replaceAll("#", "Ñ"),
                    "string",
                ),
                getGeneralTicketItem(
                    `${FORM_ID}.beneficiary.document`,
                    transactionData?.beneficiary?.document,
                    "string",
                ),
                getGeneralTicketItem(`${FORM_ID}.echeq.character`, transactionData?.character, "string"),
                getGeneralTicketItem(`${FORM_ID}.emision.date`, transactionData?.creationDate, "date"),
                getGeneralTicketItem(`${FORM_ID}.payed`, transactionData?.echeqsToEmit[0].date, "date"),
                getGeneralTicketItem(`${FORM_ID}.mode`, transactionData?.mode, "string"),
                getGeneralTicketItem(`${FORM_ID}.concept`, echeqConceptLabel(transactionData?.concept, ""), "string"),
                getGeneralTicketItem(`${FORM_ID}.motive`, transactionData?.motive, "string"),

                getGeneralTicketItem(
                    `${FORM_ID}.echeq.type`,
                    i18n.get(`echeqs.emit.${transactionData?.echeqsToEmit[0]?.type.toLowerCase()}.label`),
                    "string",
                ),
                getGeneralTicketItem(`${FORM_ID}.reference`, transactionData?.echeqsToEmit[0]?.reference, "string"),
                getGeneralTicketItem(
                    `${FORM_ID}.value.reference`,
                    transactionData?.echeqsToEmit[0]?.referencevalue,
                    "string",
                ),
                getGeneralTicketItem(`${FORM_ID}.echeq.quantity`, transactionData?.echeqsToEmit.length, "string"),

                getGeneralTicketItem(
                    `${FORM_ID}.amount`,
                    transactionData?.echeqsToEmit[0]?.amount * transactionData?.echeqsToEmit.length,
                    "amountARS",
                ),
                ...checksEmited,
                getGeneralTicketItem(`${FORM_ID}.coelsa.response`, transactionData?.coelsaResponse, "string"),
                getGeneralTicketItem(`${FORM_ID}.coelsa.code`, transactionData?.coelsaCode, "string"),
            ],
        };

        await exportGenericTicketPdf(ticketData);

        this.setState({ fetchingDownload: false });
    };

    buttonsDetail = () => {
        const { dispatch, transactionData } = this.props;

        if (transactionData) {
            const { idTransactionStatus } = transactionData;
            if (idTransactionStatus !== "PENDING") {
                return [
                    {
                        label: "global.goToHome",
                        bsStyle: "primary",
                        onClick: () => dispatch(push("/desktop")),
                    },
                ];
            }
        }
        return [
            {
                label: "global.goToHome",
                bsStyle: "outline",
                onClick: () => dispatch(push("/desktop")),
            },
        ];
    };

    haveTransactionPage = (path) => {
        const pattern1 = /.*\/transaction\/[A-Za-z0-9]*/;
        return pattern1.test(path);
    };

    fixPath = async () => {
        const { history, dispatch } = this.props;

        const unlisten = await history?.listen((location) => {
            const nextPath = location?.pathname;

            if (this.haveTransactionPage(nextPath)) {
                dispatch(goBack());
            }
            unlisten();
        });
    };

    handleBack = () => {
        const { dispatch, goBackEcheqs } = this.props;

        if (goBackEcheqs) {
            dispatch(push("/echeqs"));
        } else {
            this.fixPath();
            dispatch(goBack());
        }
    };

    render() {
        const { transactionData, isDesktop, dispatch, isSmallDesktop, fetching } = this.props;
        const { isModalDisplayed, showDrawerMobile, fetchingDownload } = this.state;

        return (
            <>
                {transactionData?.idTransaction && (
                    <TransactionTicket
                        notificationScope="echeqs"
                        handleClick={this.exportPdf}
                        handleBack={this.handleBack}
                        handleClickMessage="global.download"
                        uniqueDownload={transactionData?.idTransactionStatus === "FINISHED"}
                        rightContent={
                            transactionData?.idTransactionStatus === "PENDING" && this.rightContentTransactionScheme
                        }
                        emptyRightOption={
                            (isDesktop && transactionData?.idTransactionStatus !== "FINISHED") ||
                            (!isDesktop &&
                                transactionData?.idTransactionStatus !== "PENDING" &&
                                transactionData?.idTransactionStatus !== "FINISHED")
                        }
                        downloadImageWhite={!isDesktop}
                        headerClassName={!isDesktop ? "blue-main-header-mobile blue-main-title-mobile" : ""}
                        centerElement={isDesktop ? undefined : this.centerContentMobile}
                        hasInlineButtons
                        hideMobileMenu={!isDesktop}
                        fetching={fetching}
                        summary={transactionData}
                        buttonsDetail={this.buttonsDetail()}
                        isDesktop={isDesktop}
                        isSmallDesktop={isSmallDesktop}
                        onSignTicket={this.signTransaction}
                        ignoreHomeButton
                        showDrawerMobile={showDrawerMobile}
                        closeMobileDrawer={() => this.setState({ showDrawerMobile: false })}
                        summaryTitle="echeqs.transaction.emit.label"
                        isFetchingExport={fetchingDownload}>
                        <ModifyPendingTransactionModal
                            isDesktop={isDesktop}
                            isDisplayed={isModalDisplayed}
                            handleDismiss={this.handleDismiss}
                            dispatch={dispatch}
                            idTransaction={transactionData.idTransaction}
                        />
                        <EmitEcheqTransactionData isDesktop={isDesktop} />
                    </TransactionTicket>
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    transactionData: selectors.getTransactionEcheqData(state),
    fetching: selectors.getFetching(state),
    goBackEcheqs: selectors.isGoBackEcheqs(state),
});

export default connect(mapStateToProps)(EmitEcheqTransaction);
