import React, { Component } from "react";
import { connect } from "react-redux";
import { goBack } from "react-router-redux";
import Head from "pages/_components/Head";
import MainContainer from "pages/_components/MainContainer";
import { func, arrayOf, string, bool, shape } from "prop-types";
import Notification from "pages/_components/Notification";
import { selectors, actions } from "reducers/checks";
import { echeqShape } from "util/checks";
import * as configUtils from "util/config";
import * as i18n from "util/i18n";
import Col from "react-bootstrap/lib/Col";
import Container from "pages/_components/Container";
import Button from "pages/_components/Button";
import { Field, Form, withFormik } from "formik";
import I18n from "pages/_components/I18n";
import { compose } from "redux";
import Yup from "yup";
import Credential from "pages/_components/fields/credentials/Credential";
import { selectors as sessionSelectors } from "reducers/session";
import EndorseSummaryData from "pages/checks/Echeqs/EcheqTransactions/EndorseSummaryData";
import SelectedAccountsAmount from "pages/charges/_components/SelectedAccountsAmount";
import NegotiateSummaryData from "pages/checks/Echeqs/EcheqTransactions/NegotiateSummaryData";
import Hint from "pages/_components/hints/Hint";
import isTokenActive from "util/token";

const FORM_ID = "echeqs";

class ConfirmEndorseEcheqAction extends Component {
    static propTypes = {
        dispatch: func.isRequired,
        isSubmitting: bool.isRequired,
        echeqAction: shape({
            action: string.isRequired,
            echeqList: arrayOf(echeqShape),
            specialAttribute: string.isRequired,
        }).isRequired,
        endorseData: shape({
            beneficiary: shape({}),
        }).isRequired,
        isDesktop: bool.isRequired,
        isAutomatedDiscount: bool.isRequired,
    };

    componentDidMount() {
        const { dispatch, echeqAction } = this.props;

        if (echeqAction?.action === "NEGOTIATE") {
            this.checkAutomatedDiscount();
            dispatch(
                actions.beneficiaryEcheq(
                    { document: "CUIT 30576614299", reference: "BANCO CMF S.A." },
                    "checks/BENEFICIARY_ENDORSE_ECHEQ_DATA",
                ),
            );
        }
    }

    componentWillMount = () => {
        const { echeqAction } = this.props;

        if (!echeqAction.action) {
            this.handleBack();
        }
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(goBack());
    };

    totalEcheqs = () => {
        const { echeqAction } = this.props;

        return <SelectedAccountsAmount list={echeqAction?.echeqList} summaryView />;
    };

    checkAutomatedDiscount = () => {
        const { echeqAction, dispatch } = this.props;
        const isAutomatedDiscount = echeqAction?.echeqList?.some((item) => item.estado !== "ACTIVO");
        dispatch(actions.setIsAutomatedDiscount(isAutomatedDiscount));
    };

    renderHeader = () => {
        const { echeqAction, isDesktop } = this.props;
        const echeqList = echeqAction?.echeqList?.length > 1;
        const title = echeqList
            ? `${FORM_ID}.${echeqAction?.action?.toLowerCase()}.title.multi`
            : `${FORM_ID}.${echeqAction?.action?.toLowerCase()}.title`;

        const subtitle = echeqList ? "echeqs.negotiate.subtitle" : "echeqs.negotiate.subtitle.simple";

        if (isDesktop) {
            return (
                <>
                    <div className="admin-detail-head px-0">
                        <Head onBack={this.handleBack} />
                        <Container className="flex-grow align-items-center" gridClassName="form-content">
                            <Col
                                xs={12}
                                md={echeqList ? 8 : 12}
                                className="px-0 justify-content-start"
                                style={{ alignItems: "center", height: !isDesktop && "4.5rem" }}>
                                <Head
                                    title={title}
                                    titleClassName="align-font-fix-04"
                                    handleClickMessage="global.download"
                                    centerContentClassName="p-0"
                                    hasInlineButtons>
                                    {echeqAction?.action === "NEGOTIATE" && (
                                        <Hint
                                            idMessage={`${FORM_ID}.${echeqAction?.action?.toLowerCase()}.information`}
                                            styleText={{
                                                maxWidth: "35rem",
                                            }}
                                            styleDiv={{ marginLeft: "0.5rem" }}
                                            styleImage={{ alignSelf: "center", margin: !isDesktop && "2rem 0 1.5rem" }}
                                            classNameHintP="hint-font-size-small font-weight-normal my-0"
                                            alwaysShowMessage
                                        />
                                    )}
                                </Head>
                                <I18n
                                    id={echeqAction?.action === "NEGOTIATE" ? `${subtitle}` : "echeqs.action.subtitle"}
                                    component="h4"
                                    componentProps={{ className: "my-0" }}
                                />
                            </Col>
                            {echeqList && (
                                <Col md={4} className="echeq__totals justify-content-end d-flex">
                                    {this.totalEcheqs()}
                                </Col>
                            )}
                        </Container>
                    </div>
                </>
            );
        }

        return (
            <Head
                headerClassName="blue-main-header-mobile"
                titleClassName="w-100"
                onBack={this.handleBack}
                title={title}
                emptyRightOption
            />
        );
    };

    render() {
        const { echeqAction, endorseData, isSubmitting, isDesktop, isAutomatedDiscount } = this.props;
        return (
            <>
                <Notification scopeToShow="echeqs" />
                {this.renderHeader()}
                <MainContainer className={`confirm-negotiate-echeq-body ${!isDesktop && "container-white"}`}>
                    {!isDesktop && (
                        <>
                            <Col xs={12} className="echeq__totals justify-content-center d-flex">
                                <I18n
                                    id={
                                        echeqAction?.action === "NEGOTIATE"
                                            ? "echeqs.negotiate.subtitle"
                                            : "echeqs.action.subtitle"
                                    }
                                    component="h4"
                                    componentProps={{ className: `my-0 ${!isDesktop && "text-center"}` }}
                                />
                            </Col>
                            {echeqAction.echeqList?.length > 1 && (
                                <Col xs={12} className="echeq__totals justify-content-end d-flex">
                                    {this.totalEcheqs()}
                                </Col>
                            )}
                        </>
                    )}
                    <div className="above-the-fold">
                        {echeqAction?.action === "NEGOTIATE" ? (
                            <NegotiateSummaryData
                                endorseData={endorseData}
                                echeqAction={echeqAction}
                                isDesktop={isDesktop}
                            />
                        ) : (
                            <EndorseSummaryData
                                endorseData={endorseData}
                                echeqAction={echeqAction}
                                isDesktop={isDesktop}
                            />
                        )}
                        <Container className="container-white mt-0">
                            <Form className="above-the-fold">
                                <Container
                                    className={`flex-grow align-items-center py-2 mt-2 ${!isDesktop && "mt-0"}`}
                                    gridClassName="form-content">
                                    {echeqAction?.action === "NEGOTIATE" && isAutomatedDiscount && (
                                        <Hint
                                            defaultValue={configUtils.get("frontend.Echeqs.AdmitDiscount.Text")}
                                            classNameGroup="d-flex align-items-center"
                                            classNameHintP="m-0 ml-2"
                                            alwaysShowMessage
                                        />
                                    )}
                                </Container>
                                {!isTokenActive() && (
                                    <Container className="flex-grow align-items-center">
                                        <Col className="col-12 col-xl-4" lg={4} md={9} sm={12}>
                                            <div className="admin-content-center">
                                                <I18n
                                                    id="transfer.summary.confirm.label"
                                                    componentProps={{ className: "data-desc" }}
                                                />
                                            </div>
                                            <div className="admin-content-center">
                                                <Field
                                                    name="otp"
                                                    type="otp"
                                                    idForm={FORM_ID}
                                                    component={Credential}
                                                    loading={isSubmitting}
                                                    placeholderText={i18n.get(`confirmation.otp.placeholder`)}
                                                />
                                            </div>
                                        </Col>
                                    </Container>
                                )}
                                {isTokenActive() && (
                                    <Container className="flex-grow align-items-center">
                                        <Col className="col-12 col-xl-4" lg={4} md={9} sm={12}>
                                            <div className="admin-content-center">
                                                <I18n
                                                    id="confirmation.withoutotp.text"
                                                    component="p"
                                                    componentProps={{ className: "confirmation__text-without-otp" }}
                                                />
                                            </div>
                                            <div className="admin-content-center">
                                                <Field
                                                    name="otp"
                                                    type="otp"
                                                    idForm={FORM_ID}
                                                    component={Credential}
                                                    loading={isSubmitting}
                                                    placeholderText={i18n.get(`confirmation.otp.placeholder`)}
                                                />
                                            </div>
                                        </Col>
                                    </Container>
                                )}
                                <Container className="flex-grow align-items-center" gridClassName="form-content">
                                    <Col className="col-12 col-xl-4" lg={4} md={9} sm={12}>
                                        <Button
                                            type="submit"
                                            bsStyle="primary"
                                            label="global.confirm"
                                            loading={isSubmitting}
                                            image="images/arrowRight.svg"
                                            imageStyle="mr-2"
                                            className="text-capitalize"
                                        />
                                    </Col>
                                </Container>
                            </Form>
                        </Container>
                    </div>
                </MainContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    option: selectors.getEcheqOption(state),
    echeqAction: selectors.getEcheqsToDoActions(state),
    endorseData: selectors.getEcheqsEndorseData(state),
    userId: sessionSelectors.getUser(state).userId,
    isAutomatedDiscount: selectors.getIsAutomatedDiscount(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        mapPropsToValues: () => ({ otp: "" }),
        validationSchema: () =>
            Yup.object().shape({
                otp: Yup.string().required(i18n.get("accounts.new.account.otp.error.required")),
            }),
        handleSubmit: (form, formikBag) => {
            const { setSubmitting, setErrors } = formikBag;
            const { dispatch, endorseData, echeqAction, userId, isAutomatedDiscount } = formikBag.props;

            setSubmitting(true);
            dispatch(actions.setGoBackEcheqs(true));
            if (echeqAction?.action !== "NEGOTIATE") {
                if (echeqAction.echeqList?.length > 1) {
                    dispatch(
                        actions.endorseMultipleEcheq(
                            form.otp,
                            setSubmitting,
                            setErrors,
                            endorseData,
                            echeqAction,
                            userId,
                        ),
                    );
                } else {
                    dispatch(
                        actions.endorseEcheq(form.otp, setSubmitting, setErrors, endorseData, echeqAction, userId),
                    );
                }
            } else if (echeqAction.echeqList?.length > 1) {
                dispatch(
                    actions.negotiateMultipleEcheq(
                        form.otp,
                        setSubmitting,
                        setErrors,
                        endorseData,
                        echeqAction,
                        userId,
                    ),
                );
            } else {
                dispatch(
                    actions.negotiateEcheq(
                        form.otp,
                        setSubmitting,
                        setErrors,
                        endorseData,
                        echeqAction,
                        userId,
                        isAutomatedDiscount,
                    ),
                );
            }
        },
    }),
)(ConfirmEndorseEcheqAction);
