/* eslint-disable camelcase */
import React from "react";
import { bool, shape } from "prop-types";
import { Col } from "react-bootstrap";
import { connect } from "react-redux";
import { compose } from "redux";

import FormattedAmount from "pages/_components/FormattedAmount";
import FormattedDate from "pages/_components/FormattedDate";
import FormattedRate from "pages/_components/FormattedRate";
import I18n from "pages/_components/I18n";

import Container from "pages/_components/Container";
import Heading from "pages/_components/Heading";
import { resizableRoute } from "pages/_components/Resizable";
import { selectors as cedipSelectors } from "reducers/cedip";

function CedipDetailInformation({ cedip, isDesktop }) {
    const {
        tipoMoneda,
        montoACobrar,
        fechaVencimiento,
        tipoCertificado,
        tna,
        fechaEmision,
        montoDepositado,
        montoIntereses,
        tipoRenovacion,
        montoRetencion,
        plazo,
    } = cedip;
    const className = "align-items-center flex-grow container-white with-margin py-2";
    const containerClassName = `transfer-data data-wrapper-flex ${!isDesktop ? "space-between mb-0" : ""}`;
    const labelClassName = isDesktop ? "mb-0 fw-bolder" : "";

    const getFormattedAmount = (amount) => {
        if (amount || amount === 0) {
            return (
                <FormattedAmount
                    currency={tipoMoneda}
                    quantity={amount}
                    notBold
                    fontClassName={isDesktop ? "f-size-4" : "f-size-65"}
                />
            );
        }

        return <span className="data-text">-</span>;
    };

    const getFormattedDate = (date) => {
        if (date) {
            return (
                <FormattedDate
                    date={date}
                    dateFormat="dd/MM/yyyy"
                    className={isDesktop ? "f-size-4 font-light" : "f-size-65"}
                />
            );
        }

        return <span className="data-text">-</span>;
    };

    if (isDesktop) {
        return (
            <>
                <Container className={className}>
                    <Col sm={12} md={4} lg={4} className="col col-12">
                        <div className="data-wrapper">
                            <I18n componentProps={{ className: "data-label" }} id="deposits.information.type" />
                            <span className="data-text">{tipoCertificado}</span>
                        </div>
                    </Col>
                    <Col sm={12} md={4} lg={4} className="col col-12">
                        <div className="data-wrapper">
                            <I18n componentProps={{ className: "data-label" }} id="deposits.information.dueDate" />
                            {getFormattedDate(fechaVencimiento)}
                        </div>
                    </Col>
                    <Col sm={12} md={4} lg={4} className="col col-12">
                        <div className="data-wrapper">
                            <I18n
                                componentProps={{ className: "data-label" }}
                                id="deposits.information.nextPaymentAmount"
                            />
                            {getFormattedAmount(montoACobrar)}
                        </div>
                    </Col>
                    <Col sm={12} md={4} lg={4} className="col col-12">
                        <div className="data-wrapper">
                            <I18n
                                componentProps={{ className: "data-label" }}
                                id="deposits.information.consolidatedAmount"
                            />
                            {getFormattedAmount(montoDepositado)}
                        </div>
                    </Col>
                    <Col sm={12} md={4} lg={4} className="col col-12">
                        <div className="data-wrapper">
                            <I18n componentProps={{ className: "data-label" }} id="deposits.information.rate" />
                            <FormattedRate rate={tna} symbolToTheLeft={false} />
                        </div>
                    </Col>
                    <Col sm={12} md={4} lg={4} className="col col-12">
                        <div className="data-wrapper">
                            <I18n
                                componentProps={{ className: "data-label" }}
                                id="deposits.information.earnedInterests"
                            />
                            {getFormattedAmount(montoIntereses)}
                        </div>
                    </Col>
                </Container>
                <Container className={className}>
                    <Col sm={12} md={4} lg={4} className="col col-12">
                        <div className="data-wrapper">
                            <I18n componentProps={{ className: "data-label" }} id="deposits.information.openDate" />
                            {getFormattedDate(fechaEmision)}
                        </div>
                    </Col>
                    <Col sm={12} md={4} lg={4} className="col col-12">
                        <div className="data-wrapper">
                            <I18n componentProps={{ className: "data-label" }} id="deposits.information.termInDays" />
                            <span className="data-text">{plazo}</span>
                        </div>
                    </Col>
                    <Col sm={12} md={4} lg={4} className="col col-12">
                        <div className="data-wrapper">
                            <I18n componentProps={{ className: "data-label" }} id="deposits.information.withHoldings" />
                            {getFormattedAmount(montoRetencion)}
                        </div>
                    </Col>

                    <Col sm={12} md={4} lg={4} className="col col-12">
                        <div className="data-wrapper">
                            <I18n
                                componentProps={{ className: "data-label" }}
                                id="deposits.information.automaticRenewal"
                            />
                            <span className="data-text">{tipoRenovacion}</span>
                        </div>
                    </Col>
                    <Col sm={12} md={4} lg={4} className="col col-12">
                        <div className="data-wrapper">
                            <I18n componentProps={{ className: "data-label" }} id="deposits.cedip.cancel.anticipada" />
                            <span className="data-text">NO</span>
                        </div>
                    </Col>

                    <Col sm={12} md={4} lg={4} className="col col-12" />
                </Container>
            </>
        );
    }

    return (
        <Container className={className}>
            <Col sm={12} md={4} lg={4} className="col col-12">
                <Heading.DataGroup
                    containerClassName={containerClassName}
                    labelClassName={labelClassName}
                    label="deposits.information.type"
                    data={<span className="data-text">{tipoCertificado}</span>}
                />
                <Heading.DataGroup
                    containerClassName={containerClassName}
                    labelClassName={labelClassName}
                    label="deposits.information.dueDate"
                    data={getFormattedDate(fechaVencimiento)}
                />
                <Heading.DataGroup
                    containerClassName={containerClassName}
                    labelClassName={labelClassName}
                    label="deposits.information.nextPaymentAmount"
                    data={getFormattedAmount(montoACobrar)}
                />
                <Heading.DataGroup
                    containerClassName={containerClassName}
                    labelClassName={labelClassName}
                    label="deposits.information.consolidatedAmount"
                    data={getFormattedAmount(montoDepositado)}
                />
                <Heading.DataGroup
                    containerClassName={containerClassName}
                    labelClassName={labelClassName}
                    label="deposits.information.rate"
                    data={
                        <FormattedRate
                            rate={tna}
                            symbolToTheLeft={false}
                            className={isDesktop ? "f-size-4" : "f-size-65"}
                        />
                    }
                />
                <Heading.DataGroup
                    containerClassName={containerClassName}
                    labelClassName={labelClassName}
                    label="deposits.information.earnedInterests"
                    data={getFormattedAmount(montoIntereses)}
                />

                <Heading.DataGroup
                    containerClassName={containerClassName}
                    labelClassName={labelClassName}
                    label="deposits.information.openDate"
                    data={getFormattedDate(fechaEmision)}
                />
                <Heading.DataGroup
                    containerClassName={containerClassName}
                    labelClassName={labelClassName}
                    label="deposits.information.termInDays"
                    data={plazo}
                />
                <Heading.DataGroup
                    containerClassName={containerClassName}
                    labelClassName={labelClassName}
                    label="deposits.information.withHoldings"
                    data={getFormattedAmount(montoRetencion)}
                />
                <Heading.DataGroup
                    containerClassName={containerClassName}
                    labelClassName={labelClassName}
                    label="deposits.information.automaticRenewal"
                    data={tipoRenovacion}
                />
            </Col>
        </Container>
    );
}

CedipDetailInformation.propTypes = {
    isDesktop: bool.isRequired,
    cedip: shape({}).isRequired,
};

const mapStateToProps = (state) => ({
    cedip: cedipSelectors.getSelectedCedip(state),
});

export default compose(connect(mapStateToProps), resizableRoute)(CedipDetailInformation);
