import { Field, Form, withFormik } from "formik";
import { arrayOf, bool, func, number, shape, string } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { compose } from "redux";
import Yup from "yup";

import { actions as ecomexActions, selectors as ecomexSelectors } from "reducers/ecomex";

import moment from "moment";
import Button from "pages/_components/Button";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import Notification from "pages/_components/Notification";
import PageLoading from "pages/_components/PageLoading";
import TabletSelectors from "pages/_components/TabletSelectors";
import Date from "pages/_components/fields/DateField";
import TextField from "pages/_components/fields/TextField";
import Selector from "pages/_components/fields/formik/Selector";

import EcomexInformativeRegimeDetail from "pages/ecomex/_components/EcomexInformativeRegimeDetail";
import EcomexInformativeRegimeList from "pages/ecomex/_components/EcomexInformativeRegimeList";

import classNames from "classnames";
import * as i18nUtils from "util/i18n";

const FORM_ID = "ecomex.informativeRegime.list";

class EcomexInformativeRegimeMain extends Component {
    static propTypes = {
        fetching: bool.isRequired,
        isDesktop: bool.isRequired,
        dispatch: func.isRequired,
        fetchingDownload: bool.isRequired,
        ecomexInformativeRegime: arrayOf(shape({})).isRequired,
        filtersData: shape({}).isRequired,
        values: shape({}).isRequired,
        operationsTypes: arrayOf(shape({})).isRequired,
        optionSelected: string.isRequired,
        setFieldValue: func.isRequired,
        selectedInformativeRegime: shape({}).isRequired,
        pageNumber: number.isRequired,
        numberPerPage: number.isRequired,
        ecomexStates: arrayOf(string).isRequired,
    };

    state = {
        selectedPayDateFrom: null,
        selectedDueDateFrom: null,
        selectedOfficialDateFrom: null,
        selectedSettlementDateFrom: null,
        selectedEmbDateFrom: null,
        showForm: false,
    };

    componentDidMount() {
        const { dispatch, optionSelected } = this.props;

        dispatch(
            ecomexActions.listEcomexInformativeRegimePre(optionSelected, this.getDefaultEcomexState(optionSelected)),
        );
    }

    handleChangePayDateFrom = (selectedDate) => {
        this.setState({ selectedPayDateFrom: selectedDate });
    };

    handleChangeDueDateFrom = (selectedDate) => {
        this.setState({ selectedDueDateFrom: selectedDate });
    };

    handleChangeOfficialDateFrom = (selectedDate) => {
        this.setState({ selectedOfficialDateFrom: selectedDate });
    };

    handleChangeSettlementDateFrom = (selectedDate) => {
        this.setState({ selectedSettlementDateFrom: selectedDate });
    };

    handleChangeEmbDateFrom = (selectedDate) => {
        this.setState({ selectedEmbDateFrom: selectedDate });
    };

    handleBack = () => {
        const { dispatch } = this.props;
        dispatch(push("/desktop"));
    };

    handleExport = (format) => {
        const {
            dispatch,
            ecomexInformativeRegime,
            filtersData,
            optionSelected,
            pageNumber,
            numberPerPage,
        } = this.props;

        const ecomexInformativeRegimeToShow = ecomexInformativeRegime.slice(
            numberPerPage * (pageNumber - 1),
            numberPerPage * pageNumber,
        );

        const summary = { ecomexInformativeRegime: ecomexInformativeRegimeToShow, optionSelected, filtersData };
        dispatch(ecomexActions.downloadInformativeRegimeList(summary, format));
    };

    getDefaultEcomexState = (option) => {
        switch (option) {
            case "1":
            case "3":
                return "V";
            case "2":
                return "";
            case "4":
                return "Pendiente";
            default:
                return "";
        }
    };

    changeOption = (option) => {
        const { dispatch, ecomexStates, operationsTypes } = this.props;

        this.clearFilters(option);
        dispatch(
            ecomexActions.saveOptionSelectedForRegime(
                option,
                ecomexStates && this.getDefaultEcomexState(option),
                operationsTypes && operationsTypes[0] && operationsTypes[0].value,
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
            ),
        );
        dispatch(ecomexActions.listEcomexInformativeRegimePre(option));
    };

    clearFilters = (option) => {
        const { setFieldValue, ecomexStates, operationsTypes } = this.props;

        const dateFrom = moment()
            .subtract(30, "days")
            .toDate();
        const dateTo = moment(new Date()).toDate();

        setFieldValue("ecomexState", ecomexStates && this.getDefaultEcomexState(option));
        setFieldValue("operationType", operationsTypes && operationsTypes[0] && operationsTypes[0].value);
        setFieldValue("destination", "");
        setFieldValue("operationNumber", "");
        setFieldValue("payDateFrom", "");
        setFieldValue("payDateTo", "");
        setFieldValue("dueDateFrom", "");
        setFieldValue("dueDateTo", "");
        setFieldValue("officialDateFrom", dateFrom);
        setFieldValue("officialDateTo", dateTo);
        setFieldValue("settlementDateFrom", dateFrom);
        setFieldValue("settlementDateTo", dateTo);
        setFieldValue("embDateFrom", dateFrom);
        setFieldValue("embDateTo", dateTo);
    };

    theFiltersAreChanged = () => {
        const { values, optionSelected } = this.props;

        const {
            ecomexState,
            destination,
            operationNumber,
            payDateFrom,
            payDateTo,
            dueDateFrom,
            dueDateTo,
            officialDateFrom,
            officialDateTo,
            settlementDateFrom,
            settlementDateTo,
            embDateFrom,
            embDateTo,
        } = values;

        const dateFrom = moment()
            .subtract(30, "days")
            .toDate();
        const dateTo = moment(new Date()).toDate();

        const isSetelementDateFrom = settlementDateFrom === dateFrom;
        const isSetelementDateTo = settlementDateTo === dateTo;

        const isOfficialDateFrom = officialDateFrom === dateFrom;
        const isOfficialDateTo = officialDateTo === dateTo;

        const isEmbDateFrom = embDateFrom === dateFrom;
        const isEmbDateTo = embDateTo === dateTo;

        return !(
            (ecomexState === "V" && optionSelected === "1") ||
            (!ecomexState && optionSelected === "2") ||
            (ecomexState === "V" && optionSelected === "3") ||
            (ecomexState === "Pendiente" &&
                optionSelected === "4" &&
                !destination &&
                !operationNumber &&
                !payDateFrom &&
                !payDateTo &&
                !dueDateFrom &&
                !dueDateTo &&
                !isOfficialDateFrom &&
                !isOfficialDateTo &&
                !isSetelementDateFrom &&
                !isSetelementDateTo &&
                !isEmbDateFrom &&
                !isEmbDateTo)
        );
    };

    rowButtons = () => {
        const { isDesktop, optionSelected } = this.props;
        const { showForm } = this.state;

        const possibleOptions = ["1", "2", "3", "4"];

        const options = possibleOptions.map((possibleOption) => ({
            value: possibleOption,
            label:
                possibleOption === "2" && !isDesktop
                    ? `ecomex.informativeRegime.2.mobile.label`
                    : `ecomex.informativeRegime.${possibleOption}.label`,
        }));

        return (
            <div className={isDesktop && "d-flex space-between align-items-center w-100 background-white mb-3 px-3"}>
                <TabletSelectors
                    isDesktop={isDesktop}
                    possibleOptions={options}
                    changeOption={this.changeOption}
                    optionSelected={optionSelected}
                    containerClass={classNames("ecomex-tablet-selector", {
                        "py-3 w-100 flex-5": isDesktop,
                        "my-0 py-3": !isDesktop,
                    })}
                />
                {isDesktop && (
                    <div className="hide-filter__btn-content flex">
                        <div className="d-flex align-items-center flex-column justify-content-center">
                            <Button
                                block
                                bsStyle="outline"
                                className="hide-filter__btn m-0"
                                label={
                                    showForm
                                        ? "global.hide.filter"
                                        : `${
                                              this.theFiltersAreChanged() ? "global.filter.active" : "global.see.filter"
                                          }`
                                }
                                image={showForm ? "images/eye-off.svg" : "images/eye.svg"}
                                onClick={() => this.setState((prev) => ({ ...prev, showForm: !prev.showForm }))}
                            />
                            {this.theFiltersAreChanged() && (
                                <Button
                                    label="echeq.clean.filters"
                                    className="hide-filter__clean-btn"
                                    onClick={() => this.clearFilters(optionSelected)}
                                    image="images/cross.svg"
                                    imageStyle="circle-cross"
                                />
                            )}
                        </div>
                    </div>
                )}
            </div>
        );
    };

    renderList() {
        const {
            fetching,
            values,
            operationsTypes,
            isDesktop,
            optionSelected,
            ecomexStates,
            setFieldValue,
        } = this.props;

        const {
            selectedPayDateFrom,
            selectedDueDateFrom,
            selectedOfficialDateFrom,
            selectedSettlementDateFrom,
            selectedEmbDateFrom,
            showForm,
        } = this.state;

        const {
            payDateFrom,
            payDateTo,
            dueDateFrom,
            dueDateTo,
            officialDateFrom,
            officialDateTo,
            settlementDateFrom,
            settlementDateTo,
            embDateFrom,
            embDateTo,
            ecomexState,
            operationType,
        } = values;

        console.log(ecomexState);

        const ecomexStatesOptions = ecomexStates.map((state) => ({ value: state.value, label: state.description }));

        const operationsTypesOptions = operationsTypes.map((operationT) => ({
            value: operationT.value,
            label: operationT.description,
        }));

        if (ecomexStates.length > 0 && !ecomexState && ecomexState !== "") {
            setFieldValue("ecomexState", this.getDefaultEcomexState(optionSelected));
        }

        if (operationsTypes.length > 0 && !operationType && operationType !== "") {
            setFieldValue("operationType", operationsTypes[0].value);
        }

        const filterButton = (
            <div className={classNames("d-flex align-items-start px-2 p-relative", { "mt-4": isDesktop })}>
                <Button
                    bsStyle="primary"
                    label={`${FORM_ID}.search.button`}
                    loading={fetching}
                    type="submit"
                    className={classNames("filter-button w-100", { "mt-25 mx-0": isDesktop })}
                />
            </div>
        );

        return (
            <PageLoading loading={fetching}>
                {!isDesktop && (
                    <div className="justify-content-end d-flex">
                        <div className="hide-filter__btn-content my-3">
                            <Button
                                bsStyle="outline"
                                className="hide-filter__btn m-0"
                                image={showForm ? "images/eye-off.svg" : "images/eye.svg"}
                                label={
                                    showForm
                                        ? "global.hide.filter"
                                        : `${
                                              this.theFiltersAreChanged() ? "global.filter.active" : "global.see.filter"
                                          }`
                                }
                                onClick={() => this.setState((prev) => ({ ...prev, showForm: !prev.showForm }))}
                            />
                        </div>
                    </div>
                )}
                {showForm && (
                    <Form autoComplete="off">
                        <div className="filtros-ecomex-ri">
                            {optionSelected === "1" && (
                                <>
                                    <div className="d-flex align-items-start px-2">
                                        <Field
                                            component={Selector}
                                            options={ecomexStatesOptions}
                                            idForm={`${FORM_ID}.search`}
                                            name="ecomexState"
                                            isRequired
                                            hidePlaceholder
                                            formGroupClassName={classNames("w-100", { "mb-0": isDesktop })}
                                        />
                                    </div>
                                    <div className="d-flex align-items-start px-2">
                                        <Field
                                            component={TextField}
                                            hidePlaceholder
                                            idForm={`${FORM_ID}.search`}
                                            name={isDesktop ? "operationNumber" : "operationNumberFull"}
                                            type="text"
                                            pattern="[0-9]*"
                                            maxLength={6}
                                            formGroupClassName={classNames("w-100", { "mb-0": isDesktop })}
                                        />
                                    </div>
                                    {!isDesktop && (
                                        <I18n
                                            component="p"
                                            id={`${FORM_ID}.search.payDate.mobile.label`}
                                            componentProps={{ className: "w-100 control-label mb-0 px-2" }}
                                        />
                                    )}
                                    <div
                                        className={classNames("d-flex align-items-start px-2", {
                                            "flex-50": !isDesktop,
                                        })}>
                                        <Field
                                            component={Date}
                                            idForm={`${FORM_ID}.search`}
                                            name="payDateFrom"
                                            hidePlaceholder
                                            customKey={isDesktop ? false : `${FORM_ID}.search.payDateFrom.mobile.label`}
                                            startDate={payDateFrom}
                                            handleChange={this.handleChangePayDateFrom}
                                            endDate={payDateTo}
                                            isRequired
                                            minDate={null}
                                            value={payDateFrom}
                                            formGroupClassName={classNames("w-100", { "mb-0": isDesktop })}
                                        />
                                    </div>

                                    <div
                                        className={classNames("d-flex align-items-start px-2", {
                                            "flex-50": !isDesktop,
                                        })}>
                                        <Field
                                            component={Date}
                                            hidePlaceholder
                                            customKey={isDesktop ? false : `${FORM_ID}.search.payDateTo.mobile.label`}
                                            idForm={`${FORM_ID}.search`}
                                            name="payDateTo"
                                            minDate={selectedPayDateFrom}
                                            isRequired
                                            value={payDateTo}
                                            formGroupClassName={classNames("w-100", { "mb-0": isDesktop })}
                                        />
                                    </div>
                                    {!isDesktop && (
                                        <I18n
                                            component="p"
                                            id={`${FORM_ID}.search.dueDate.mobile.label`}
                                            componentProps={{ className: "w-100 control-label mb-0 px-2" }}
                                        />
                                    )}
                                    <div
                                        className={classNames("d-flex align-items-start px-2", {
                                            "flex-50": !isDesktop,
                                        })}>
                                        <Field
                                            component={Date}
                                            idForm={`${FORM_ID}.search`}
                                            name="dueDateFrom"
                                            customKey={isDesktop ? false : `${FORM_ID}.search.dueDateFrom.mobile.label`}
                                            hidePlaceholder
                                            startDate={dueDateFrom}
                                            handleChange={this.handleChangeDueDateFrom}
                                            endDate={dueDateTo}
                                            isRequired
                                            minDate={null}
                                            value={dueDateFrom}
                                            formGroupClassName={classNames("w-100", { "mb-0": isDesktop })}
                                        />
                                    </div>

                                    <div
                                        className={classNames("d-flex align-items-start px-2", {
                                            "flex-50": !isDesktop,
                                        })}>
                                        <Field
                                            component={Date}
                                            hidePlaceholder
                                            idForm={`${FORM_ID}.search`}
                                            name="dueDateTo"
                                            customKey={isDesktop ? false : `${FORM_ID}.search.dueDateTo.mobile.label`}
                                            minDate={selectedDueDateFrom}
                                            isRequired
                                            value={dueDateTo}
                                            formGroupClassName={classNames("w-100", { "mb-0": isDesktop })}
                                        />
                                    </div>
                                </>
                            )}
                            {optionSelected === "2" && (
                                <>
                                    <div className="d-flex align-items-start px-2">
                                        <Field
                                            component={Selector}
                                            options={operationsTypesOptions}
                                            idForm={`${FORM_ID}.search`}
                                            name="operationType"
                                            isRequired
                                            hidePlaceholder
                                            formGroupClassName={classNames("w-100", { "mb-0": isDesktop })}
                                        />
                                    </div>
                                    {!isDesktop && (
                                        <I18n
                                            component="p"
                                            id={`${FORM_ID}.search.settlementDate.mobile.label`}
                                            componentProps={{ className: "w-100 control-label mb-0 px-2" }}
                                        />
                                    )}
                                    <div
                                        className={classNames("d-flex align-items-start px-2", {
                                            "flex-50": !isDesktop,
                                        })}>
                                        <Field
                                            component={Date}
                                            idForm={`${FORM_ID}.search`}
                                            name="settlementDateFrom"
                                            customKey={
                                                isDesktop ? false : `${FORM_ID}.search.settlementDateFrom.mobile.label`
                                            }
                                            hidePlaceholder
                                            startDate={settlementDateFrom}
                                            handleChange={this.handleChangeSettlementDateFrom}
                                            endDate={settlementDateTo}
                                            isRequired
                                            minDate={null}
                                            formGroupClassName={classNames("w-100", { "mb-0": isDesktop })}
                                        />
                                    </div>

                                    <div
                                        className={classNames("d-flex align-items-start px-2", {
                                            "flex-50": !isDesktop,
                                        })}>
                                        <Field
                                            component={Date}
                                            hidePlaceholder
                                            idForm={`${FORM_ID}.search`}
                                            name="settlementDateTo"
                                            customKey={
                                                isDesktop ? false : `${FORM_ID}.search.settlementDateTo.mobile.label`
                                            }
                                            minDate={selectedSettlementDateFrom}
                                            isRequired
                                            formGroupClassName={classNames("w-100", { "mb-0": isDesktop })}
                                        />
                                    </div>
                                </>
                            )}
                            {optionSelected === "3" && (
                                <>
                                    <div className="d-flex align-items-start px-2">
                                        <Field
                                            component={Selector}
                                            options={ecomexStatesOptions}
                                            idForm={`${FORM_ID}.search`}
                                            name="ecomexState"
                                            isRequired
                                            hidePlaceholder
                                            formGroupClassName={classNames("w-100", { "mb-0": isDesktop })}
                                        />
                                    </div>

                                    <div className="d-flex align-items-start px-2">
                                        <Field
                                            component={Selector}
                                            options={operationsTypesOptions}
                                            idForm={`${FORM_ID}.search`}
                                            name="operationType"
                                            isRequired
                                            hidePlaceholder
                                            formGroupClassName={classNames("w-100", { "mb-0": isDesktop })}
                                        />
                                    </div>
                                    <div className="d-flex align-items-start px-2">
                                        <Field
                                            component={TextField}
                                            hidePlaceholder
                                            idForm={`${FORM_ID}.search`}
                                            name="destination"
                                            formGroupClassName={classNames("w-100", { "mb-0": isDesktop })}
                                            maxLength={16}
                                        />
                                    </div>
                                    {!isDesktop && (
                                        <I18n
                                            component="p"
                                            id={`${FORM_ID}.search.officialDate.mobile.label`}
                                            componentProps={{ className: "w-100 control-label mb-0 px-2" }}
                                        />
                                    )}
                                    <div
                                        className={classNames("d-flex align-items-start px-2", {
                                            "flex-50": !isDesktop,
                                        })}>
                                        <Field
                                            component={Date}
                                            idForm={`${FORM_ID}.search`}
                                            name="officialDateFrom"
                                            customKey={
                                                isDesktop ? false : `${FORM_ID}.search.officialDateFrom.mobile.label`
                                            }
                                            hidePlaceholder
                                            startDate={officialDateFrom}
                                            handleChange={this.handleChangeOfficialDateFrom}
                                            endDate={officialDateTo}
                                            isRequired
                                            minDate={null}
                                            formGroupClassName={classNames("w-100", { "mb-0": isDesktop })}
                                        />
                                    </div>

                                    <div
                                        className={classNames("d-flex align-items-start px-2", {
                                            "flex-50": !isDesktop,
                                        })}>
                                        <Field
                                            component={Date}
                                            hidePlaceholder
                                            idForm={`${FORM_ID}.search`}
                                            name="officialDateTo"
                                            customKey={
                                                isDesktop ? false : `${FORM_ID}.search.officialDateTo.mobile.label`
                                            }
                                            minDate={selectedOfficialDateFrom}
                                            isRequired
                                            formGroupClassName={classNames("w-100", { "mb-0": isDesktop })}
                                        />
                                    </div>
                                </>
                            )}
                            {optionSelected === "4" && (
                                <>
                                    <div className="d-flex align-items-start px-2">
                                        <Field
                                            component={Selector}
                                            options={ecomexStatesOptions}
                                            idForm={`${FORM_ID}.search`}
                                            name="ecomexState"
                                            isRequired
                                            hidePlaceholder
                                            formGroupClassName={classNames("w-100", { "mb-0": isDesktop })}
                                        />
                                    </div>
                                    <div className="d-flex align-items-start px-2">
                                        <Field
                                            component={TextField}
                                            hidePlaceholder
                                            idForm={`${FORM_ID}.search`}
                                            name="destination"
                                            formGroupClassName={classNames("w-100", { "mb-0": isDesktop })}
                                            maxLength={16}
                                        />
                                    </div>
                                    {!isDesktop && (
                                        <I18n
                                            component="p"
                                            id={`${FORM_ID}.search.embDate.mobile.label`}
                                            componentProps={{ className: "w-100 control-label mb-0 px-2" }}
                                        />
                                    )}
                                    <div
                                        className={classNames("d-flex align-items-start px-2", {
                                            "flex-50": !isDesktop,
                                        })}>
                                        <Field
                                            component={Date}
                                            idForm={`${FORM_ID}.search`}
                                            name="embDateFrom"
                                            customKey={isDesktop ? false : `${FORM_ID}.search.embDateFrom.mobile.label`}
                                            hidePlaceholder
                                            startDate={embDateFrom}
                                            handleChange={this.handleChangeEmbDateFrom}
                                            endDate={embDateTo}
                                            isRequired
                                            minDate={null}
                                            formGroupClassName={classNames("w-100", { "mb-0": isDesktop })}
                                        />
                                    </div>

                                    <div
                                        className={classNames("d-flex align-items-start px-2", {
                                            "flex-50": !isDesktop,
                                        })}>
                                        <Field
                                            component={Date}
                                            hidePlaceholder
                                            idForm={`${FORM_ID}.search`}
                                            name="embDateTo"
                                            customKey={isDesktop ? false : `${FORM_ID}.search.embDateTo.mobile.label`}
                                            minDate={selectedEmbDateFrom}
                                            isRequired
                                            formGroupClassName={classNames("w-100", { "mb-0": isDesktop })}
                                        />
                                    </div>
                                    {!isDesktop && (
                                        <I18n
                                            component="p"
                                            id={`${FORM_ID}.search.dueDate.mobile.label`}
                                            componentProps={{ className: "w-100 control-label mb-0 px-2" }}
                                        />
                                    )}
                                    <div
                                        className={classNames("d-flex align-items-start px-2", {
                                            "flex-50": !isDesktop,
                                        })}>
                                        <Field
                                            component={Date}
                                            idForm={`${FORM_ID}.search`}
                                            name="dueDateFrom"
                                            customKey={isDesktop ? false : `${FORM_ID}.search.dueDateFrom.mobile.label`}
                                            hidePlaceholder
                                            startDate={dueDateFrom}
                                            handleChange={this.handleChangeDueDateFrom}
                                            endDate={dueDateTo}
                                            isRequired
                                            minDate={null}
                                            formGroupClassName={classNames("w-100", { "mb-0": isDesktop })}
                                        />
                                    </div>

                                    <div
                                        className={classNames("d-flex align-items-start px-2", {
                                            "flex-50": !isDesktop,
                                        })}>
                                        <Field
                                            component={Date}
                                            hidePlaceholder
                                            idForm={`${FORM_ID}.search`}
                                            name="dueDateTo"
                                            customKey={isDesktop ? false : `${FORM_ID}.search.dueDateTo.mobile.label`}
                                            minDate={selectedDueDateFrom}
                                            isRequired
                                            formGroupClassName={classNames("w-100", { "mb-0": isDesktop })}
                                        />
                                    </div>
                                </>
                            )}
                            {filterButton}
                        </div>
                    </Form>
                )}
                <div className={isDesktop ? "mt-2" : "mt-0"}>
                    <EcomexInformativeRegimeList isDesktop={isDesktop} />
                </div>
            </PageLoading>
        );
    }

    renderContent() {
        const { isDesktop, fetching, fetchingDownload, selectedInformativeRegime } = this.props;

        const centerElement = () => (
            <div>
                <I18n
                    id="ecomex.informativeRegime.title"
                    component="h1"
                    componentProps={{ className: "cmf-title-center font-size-28-px m-0" }}
                />
            </div>
        );

        if (selectedInformativeRegime) {
            return <EcomexInformativeRegimeDetail isDesktop={isDesktop} />;
        }

        if (isDesktop) {
            return (
                <PageLoading loading={fetching} className="screen-loader">
                    <div className="admin-detail-head px-0 mb-0">
                        <Head onBack={this.handleBack} />
                        <Head
                            centerElement={centerElement}
                            isFetchingExport={fetchingDownload}
                            isMobile
                            exportList
                            handleClick={this.handleExport}
                            handleClickMessage="global.download"
                            csvDownload
                            accessibilityTextId="ecomex.informativeRegime.title"
                            imageStyle="mr-2"
                        />
                        {this.rowButtons()}
                        {this.renderList()}
                    </div>
                </PageLoading>
            );
        }

        return (
            <div className="admin-detail-head px-0 d-block mb-0">
                <Head
                    centerElement={centerElement}
                    centerContentClassName="mx-5"
                    isFetchingExport={fetchingDownload}
                    exportList
                    handleClick={this.handleExport}
                    handleClickMessage="global.download"
                    onBack={this.handleBack}
                    headerClassName="blue-main-header-mobile"
                    accessibilityTextId="ecomex.informativeRegime.title"
                    downloadImageWhite
                    uniqueDownload
                />
                <PageLoading loading={fetching} className="screen-loader">
                    {this.rowButtons()}
                    {this.renderList()}
                </PageLoading>
            </div>
        );
    }

    render() {
        const { isDesktop } = this.props;

        return (
            <>
                <Notification
                    scopeToShow={FORM_ID}
                    notificationClassname={classNames({ "navbar-fixed-top": !isDesktop })}
                />
                {this.renderContent()}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    fetching: ecomexSelectors.getFetching(state),
    fetchingDownload: ecomexSelectors.getFetchingDownload(state),
    ecomexInformativeRegime: ecomexSelectors.getEcomexInformativeRegime(state),
    filtersData: ecomexSelectors.getFiltersData(state),
    optionSelected: ecomexSelectors.getOptionSelected(state),
    selectedInformativeRegime: ecomexSelectors.getSelectedInformativeRegime(state),
    pageNumber: ecomexSelectors.getPageNumber(state),
    numberPerPage: ecomexSelectors.getNumberPerPage(state),
    operationsTypes: ecomexSelectors.getOperationsTypes(state),
    ecomexStates: ecomexSelectors.getEcomexStates(state),
});

export default compose(
    connect(mapStateToProps),
    withFormik({
        validateOnChange: true,
        validateOnBlur: true,
        mapPropsToValues: (props) => {
            const { ecomexStates, operationsTypes } = props;
            return {
                ecomexState: ecomexStates && "V",
                operationType: operationsTypes && operationsTypes[0] && operationsTypes[0].value,
                destination: "",
                operationNumber: "",
                payDateFrom: "",
                payDateTo: "",
                dueDateFrom: "",
                dueDateTo: "",
                settlementDateFrom: moment()
                    .subtract(30, "days")
                    .toDate(),
                settlementDateTo: moment(new Date()).toDate(),
                officialDateFrom: moment()
                    .subtract(30, "days")
                    .toDate(),
                officialDateTo: moment(new Date()).toDate(),
                embDateFrom: moment()
                    .subtract(30, "days")
                    .toDate(),
                embDateTo: moment(new Date()).toDate(),
            };
        },
        validationSchema: () =>
            Yup.lazy((values) =>
                Yup.object().shape({
                    payDateFrom: values.payDateTo
                        ? Yup.date()
                              .nullable()
                              .max(values.payDateTo, i18nUtils.get(`${FORM_ID}.search.minDate.error`))
                        : Yup.date().nullable(),
                    payDateTo: values.payDateFrom
                        ? Yup.date()
                              .nullable()
                              .min(values.payDateFrom, i18nUtils.get(`${FORM_ID}.search.maxDate.error`))
                        : Yup.date().nullable(),
                    dueDateFrom: values.dueDateTo
                        ? Yup.date()
                              .nullable()
                              .max(values.dueDateTo, i18nUtils.get(`${FORM_ID}.search.minDate.error`))
                        : Yup.date().nullable(),
                    dueDateTo: values.dueDateFrom
                        ? Yup.date()
                              .nullable()
                              .min(values.dueDateFrom, i18nUtils.get(`${FORM_ID}.search.maxDate.error`))
                        : Yup.date().nullable(),
                    settlementDateFrom: values.settlementDateTo
                        ? Yup.date()
                              .nullable()
                              .max(values.settlementDateTo, i18nUtils.get(`${FORM_ID}.search.minDate.error`))
                        : Yup.date().nullable(),
                    settlementDateTo: values.settlementDateFrom
                        ? Yup.date()
                              .nullable()
                              .min(values.settlementDateFrom, i18nUtils.get(`${FORM_ID}.search.maxDate.error`))
                        : Yup.date().nullable(),
                    officialDateFrom: values.officialDateTo
                        ? Yup.date()
                              .nullable()
                              .max(values.officialDateTo, i18nUtils.get(`${FORM_ID}.search.minDate.error`))
                        : Yup.date().nullable(),
                    officialDateTo: values.officialDateFrom
                        ? Yup.date()
                              .nullable()
                              .min(values.officialDateFrom, i18nUtils.get(`${FORM_ID}.search.maxDate.error`))
                        : Yup.date().nullable(),
                    embDateFrom: values.embDateTo
                        ? Yup.date()
                              .nullable()
                              .max(values.embDateTo, i18nUtils.get(`${FORM_ID}.search.minDate.error`))
                        : Yup.date().nullable(),
                    embDateTo: values.embDateFrom
                        ? Yup.date()
                              .nullable()
                              .min(values.embDateFrom, i18nUtils.get(`${FORM_ID}.search.maxDate.error`))
                        : Yup.date().nullable(),
                }),
            ),
        handleSubmit: (
            {
                ecomexState,
                operationType,
                destination,
                operationNumber,
                payDateFrom,
                payDateTo,
                dueDateFrom,
                dueDateTo,
                officialDateFrom,
                officialDateTo,
                settlementDateFrom,
                settlementDateTo,
                embDateFrom,
                embDateTo,
            },
            formikBag,
        ) => {
            const { dispatch, optionSelected, pageNumber, numberPerPage } = formikBag.props;

            dispatch(
                ecomexActions.listEcomexInformativeRegime(
                    pageNumber,
                    numberPerPage,
                    optionSelected,
                    ecomexState,
                    operationType,
                    destination,
                    operationNumber,
                    payDateFrom,
                    payDateTo,
                    dueDateFrom,
                    dueDateTo,
                    officialDateFrom,
                    officialDateTo,
                    settlementDateFrom,
                    settlementDateTo,
                    embDateFrom,
                    embDateTo,
                ),
            );
        },
    }),
)(EcomexInformativeRegimeMain);
