/* eslint-disable import/no-unresolved */
import classNames from "classnames";
import { withFormik } from "formik";
import { arrayOf, bool, element, func, node, shape, string } from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { goBack, push } from "react-router-redux";
import { compose } from "redux";
import { chooseImage, normalizeTransactionPath } from "util/transaction";

import Container from "pages/_components/Container";
import ContextMenu from "pages/_components/ContextMenu";
import Head from "pages/_components/Head";
import I18n from "pages/_components/I18n";
import * as i18n from "util/i18n";
import Image from "pages/_components/Image";
import Notification from "pages/_components/Notification";
import PermissionsList from "pages/administration/_components/AdvancedPermissionsList";
import UserCancelled from "pages/forms/UserCancelled";
import UserLiberator from "pages/forms/UserLiberator";
import UserSignatures from "pages/forms/UserSignatures";
import FormSignatures from "pages/forms/_components/FormSignatures";
import GlobalTransactionScheme from "pages/transactions/GlobalTransactionScheme";
import TransactionsSignatures from "pages/transactions/_components/TransactionsSignatures";
import { Row } from "react-bootstrap";
import Col from "react-bootstrap/lib/Col";
import { actions as formActions } from "reducers/form";
import { selectors as sessionSelectors } from "reducers/session";
import { actions as transactionsActions, selectors as transactionsSelectors } from "reducers/transactions";
import * as configUtils from "util/config";
import Button from "./Button";
import FormattedDate from "./FormattedDate";
import FieldLabel from "./fields/FieldLabel";
import PageLoading from "./PageLoading";

class TransactionTicket extends Component {
    static propTypes = {
        summary: shape({}).isRequired,
        notificationScope: string.isRequired,
        summaryTitle: string.isRequired,
        isDesktop: bool,
        fetching: bool.isRequired,
        handleClick: func.isRequired,
        emptyRightOption: bool,
        uniqueDownload: bool.isRequired,
        rightContent: func.isRequired,
        handleClickMessage: string.isRequired,
        headerClassName: string.isRequired,
        buttonsDetail: arrayOf({}),
        showDrawerMobile: bool.isRequired,
        closeMobileDrawer: func.isRequired,
        hasInlineButtons: bool.isRequired,
        hideMobileMenu: bool.isRequired,
        isSmallDesktop: bool.isRequired,
        handleBack: func.isRequired,
        children: node.isRequired,
        isFetchingExport: bool,
        isAdministrationTicket: bool,
        showModifiedBy: bool,
        fullName: string,
        centerElement: element.isRequired,
        downloadImageWhite: bool.isRequired,
        isPaymentAFIPTicket: bool,
        defaultConfiguration: bool,
        status: string,
        idSubtitle: string,
        loggedUser: shape({}).isRequired,
        onSignTicket: func,
        ignoreHomeButton: bool,
        hideSigns: bool,
        generateSignFunction: bool,
        dispatch: func.isRequired,
        potentialSignaturesData: arrayOf(
            shape({ typesOfSignatures: arrayOf({ signature: string, selected: bool }), liberator: string }),
        ).isRequired,
        activeEnvironment: shape({}).isRequired,
    };

    static defaultProps = {
        isDesktop: false,
        buttonsDetail: null,
        isFetchingExport: false,
        isAdministrationTicket: false,
        showModifiedBy: false,
        fullName: "",
        emptyRightOption: false,
        isPaymentAFIPTicket: false,
        defaultConfiguration: false,
        status: null,
        idSubtitle: null,
        onSignTicket: null,
        ignoreHomeButton: null,
        generateSignFunction: false,
        hideSigns: false,
    };

    state = {
        showDrawerMobile: false,
    };

    componentDidMount() {
        if (this.scrollRef.current) {
            this.scrollRef.current.scrollIntoView();
        }
    }

    rightContentTransactionScheme = (isFinished, isPending) => {
        const { isDesktop, summary } = this.props;

        if (isDesktop || isFinished || !isPending) {
            return <></>;
        }

        return (
            <>
                {summary?.idTransactionStatus !== "CANCELLED" && (
                    <ContextMenu
                        isDesktop={isDesktop}
                        buttonClassName="align-self-center account-dropdown-header-font font-black-alpha ml-2"
                        items={[
                            {
                                label: "pendingTransaction.info.scheme",
                                onClick: () => this.setState({ showDrawerMobile: true }),
                            },
                        ]}
                    />
                )}
            </>
        );
    };

    defaultBack = () => {
        const { dispatch } = this.props;
        normalizeTransactionPath(this.props);
        dispatch(goBack());
    };

    getShowDrawerMobile = () => {
        const { showDrawerMobile } = this.state;
        return showDrawerMobile;
    };

    checkSign = () => {
        const { summary, loggedUser, potentialSignaturesData } = this.props;
        const { signatures: transactionSignatures } = summary;
        if (loggedUser?.signatureLevel || loggedUser.liberator) {
            const hasSigned = transactionSignatures?.some((u) => u.idUser === loggedUser?.userId);

            if (!hasSigned) {
                const schemesSignatures = potentialSignaturesData.reduce((signatureSchemes, signatures) => {
                    const { typesOfSignatures } = signatures;
                    const compatibleSignatureEmpty = typesOfSignatures.find(
                        (signature) => signature.signature === loggedUser.signatureLevel && !signature.selected,
                    );

                    if (compatibleSignatureEmpty) {
                        return signatureSchemes.concat(
                            typesOfSignatures
                                .filter((signatureScheme) => !signatureScheme.selected)
                                .map((signatureScheme) => signatureScheme.signature),
                        );
                    }

                    return signatureSchemes;
                }, []);

                const schemesHasLiberator = potentialSignaturesData.some((scheme) => scheme.liberator);

                return (
                    (schemesSignatures && schemesSignatures.includes(loggedUser.signatureLevel)) ||
                    (loggedUser.liberator && schemesHasLiberator)
                );
            }
        }

        return false;
    };

    signAction = () => {
        const { dispatch, summary, summaryTitle, children } = this.props;

        dispatch(push("/transactions/sign"));

        dispatch(
            formActions.signTransactionData({
                idTransaction: summary?.idTransaction,
                idActivity: summary?.idActivity,
                idForm: "",
                content: children,
                title: summaryTitle,
                pendingDispatch: summary?.pendingDispatch,
                useTransactionData: summary?.useTransactionData,
            }),
        );

        dispatch(
            formActions.signTransactionPreview({
                idActivity: summary?.idActivity,
                idTransaction: summary?.idTransaction,
                idForm: "",
                ticketData: summary?.data || summary,
            }),
        );
    };

    cancelAction = () => {
        const { dispatch, summary, summaryTitle, children } = this.props;

        dispatch(push("/transactions/cancel"));
        dispatch(
            formActions.cancelTransactionData({
                idTransaction: summary?.idTransaction,
                content: children,
                title: summaryTitle,
            }),
        );
        dispatch(formActions.cancelTransactionPre({ idActivity: summary?.idActivity, idForm: "" }));
    };

    checkForActionButtons = (buttons, isPending) => {
        const { onSignTicket, ignoreHomeButton, generateSignFunction, isDesktop, dispatch } = this.props;
        const signTransaction = onSignTicket || generateSignFunction;
        if (signTransaction && isPending) {
            const signatureButton = this.checkSign();
            const signButton = !signatureButton ? (
                <Button
                    bsStyle="primary"
                    onClick={() => dispatch(push("/desktop"))}
                    label="global.goToHome"
                    className={classNames("m-0", { "max-width-btn w-100": isDesktop })}
                />
            ) : (
                <Button
                    bsStyle="primary"
                    onClick={onSignTicket || this.signAction}
                    label="forms.signTransaction.link"
                    block={!isDesktop}
                    className={classNames("m-0", { "max-width-btn w-100": isDesktop })}
                />
            );

            if (!ignoreHomeButton || signatureButton) {
                buttons.push(signButton);
            }
        }
    };

    cancelTransaction = () => {
        const { dispatch, summary, children, summaryTitle } = this.props;
        dispatch(push("/transactions/cancel"));
        dispatch(
            formActions.cancelTransactionData({
                idTransaction: summary?.idTransaction,
                content: children,
                title: summaryTitle,
            }),
        );
        dispatch(formActions.cancelTransactionPre({ idActivity: summary?.idActivity, idForm: "" }));
    };

    renderButtonArray = () => {
        const { buttonsDetail, summary, status, dispatch, isDesktop } = this.props;

        const isPending = summary?.idTransactionStatus === "PENDING" || status === "PENDING";

        const goButton = [
            isPending
                ? {
                      label: "goto.pendings.transaction",
                      bsStyle: "outline",
                      onClick: () => {
                          dispatch(transactionsActions.initPendings());
                          dispatch(push("/pendingTransaction/list"));
                          dispatch(
                              transactionsActions.loadListRequest(
                                  {
                                      pageNumber: 1,
                                      status: "PENDING",
                                      filterPendingDispatch: false,
                                      functionRequest: "pendingTransactions",
                                      idActivity: summary.idActivity,
                                  },
                                  true,
                                  false,
                              ),
                          );
                      },
                  }
                : {
                      label: "goto.historic.transaction",
                      bsStyle: "outline",
                      onClick: () => {
                          dispatch(transactionsActions.setFilters(null));
                          dispatch(push("/transactions/list"));
                      },
                  },
        ];
        if (summary?.idTransactionStatus === "PENDING") {
            goButton.push({
                label: "transaction.delete",
                bsStyle: "outline",
                onClick: this.cancelTransaction,
            });
        }

        const completeButtonsDetail = !isDesktop
            ? goButton
                  .concat(buttonsDetail)
                  .sort((a, b) => (a.bsStyle === "primary" && b.bsStyle !== "primary" ? -1 : 1))
            : goButton.concat(buttonsDetail);

        const buttons = completeButtonsDetail.map((button) => (
            <Button
                type="button"
                bsStyle={button.bsStyle}
                onClick={() => button.onClick()}
                label={button.label}
                fetching={button.loading}
                block={!isDesktop}
                className={classNames("m-0", { "max-width-btn w-100": isDesktop })}
            />
        ));

        this.checkForActionButtons(buttons, isPending);

        return buttons;
    };

    render() {
        const {
            isDesktop,
            fetching,
            notificationScope,
            handleBack,
            summary,
            handleClick,
            uniqueDownload,
            emptyRightOption,
            rightContent,
            handleClickMessage,
            headerClassName,
            buttonsDetail,
            hasInlineButtons,
            hideMobileMenu,
            isSmallDesktop,
            showDrawerMobile,
            closeMobileDrawer,
            summaryTitle,
            showModifiedBy,
            fullName,
            children,
            isFetchingExport,
            isAdministrationTicket,
            centerElement,
            downloadImageWhite,
            isPaymentAFIPTicket,
            defaultConfiguration,
            status,
            idSubtitle,
            activeEnvironment,
            hideSigns,
        } = this.props;

        const isFinished = summary?.idTransactionStatus === "FINISHED" || status === "FINISHED";
        const isPending = summary?.idTransactionStatus === "PENDING" || status === "PENDING";
        const payFunctionalities = configUtils.getBoolean("frontend.show.failedVoucher.payFunctionalities", false);
        const chequesFunctionalities = configUtils.getBoolean(
            "frontend.show.failedVoucher.chequesFunctionalities",
            false,
        );
        const transfersFunctionalities = configUtils.getBoolean(
            "frontend.show.failedVoucher.transfersFunctionalities",
            false,
        );
        const transfersActivitites = [
            "transfers.internal.send",
            "transfers.thirdParties.send",
            "transfers.internal.tad.send",
            "transfers.thirdParties.tad.send",
            "credin.sentRequest.send",
        ];

        const hasSignatureLevel = activeEnvironment.administrationScheme !== "simple";
        this.scrollRef = React.createRef();
        const defaults =
            !defaultConfiguration || !handleClick
                ? {}
                : {
                      uniqueDownload: isFinished,
                      downloadImageWhite: !isDesktop,
                      hideMobileMenu: !isDesktop,
                      rightContent: () => this.rightContentTransactionScheme(isFinished, isPending),
                      showDrawerMobile: this.getShowDrawerMobile(),
                      closeMobileDrawer: () => this.setState({ showDrawerMobile: false }),
                  };

        return (
            <>
                <Notification scopeToShow={notificationScope} />
                <PageLoading loading={fetching}>
                    <div ref={this.scrollRef} className="admin-detail-head px-0">
                        <Head
                            onBack={handleBack || this.defaultBack}
                            handleClick={handleClick}
                            emptyRightOption={emptyRightOption || isDesktop}
                            uniqueDownload={defaults.uniqueDownload || uniqueDownload}
                            rightContent={defaults.rightContent || rightContent}
                            handleClickMessage={handleClickMessage}
                            headerClassName={headerClassName}
                            centerElement={centerElement}
                            hasInlineButtons={hasInlineButtons}
                            hideMobileMenu={defaults.hideMobileMenu || hideMobileMenu}
                            isFetchingExport={isFetchingExport}
                            downloadImageWhite={defaults.downloadImageWhite || downloadImageWhite}
                        />
                        {isDesktop && !emptyRightOption && summary.idTransactionStatus !== "PENDING" && (
                            <Head
                                handleClick={handleClick}
                                uniqueDownload={defaults.uniqueDownload || uniqueDownload}
                                rightContent={defaults.rightContent || rightContent}
                                handleClickMessage={handleClickMessage}
                                hasInlineButtons={hasInlineButtons}
                                isFetchingExport={isFetchingExport}
                                downloadImageWhite={defaults.downloadImageWhite || downloadImageWhite}
                                headerClassName="remove-height"
                            />
                        )}
                    </div>
                    {summary.idTransactionStatus === "PENDING" && (
                        <GlobalTransactionScheme
                            idTransaction={summary?.idTransaction}
                            isDesktop={isDesktop}
                            showDrawerMobile={defaults.showDrawerMobile || showDrawerMobile}
                            closeMobileDrawer={defaults.closeMobileDrawer || closeMobileDrawer}
                        />
                    )}
                    <main className="px-0">
                        <Container className="flex-grow align-items-center">
                            <Col sm={12} md={9} lg={isSmallDesktop ? 6 : 5} className="cmf-container-white">
                                <div className="admin-content-center mt-3">
                                    <Image
                                        src={chooseImage(summary?.idTransactionStatus)}
                                        styles={{
                                            width: isDesktop ? "80px" : "65px",
                                            height: isDesktop ? "80px" : "65px",
                                        }}
                                    />
                                </div>
                                <div className="admin-content-center transaction-ticket-status">
                                    <I18n
                                        id={
                                            idSubtitle ||
                                            `forms.transaction.ticket.status.${summary.idTransactionStatus}`
                                        }
                                    />
                                </div>
                                <div className="admin-content-center ">
                                    {isAdministrationTicket && (
                                        <h1 className="cmf-title-center transaction-ticket-title">{summaryTitle}</h1>
                                    )}
                                    {!isAdministrationTicket && (
                                        <I18n
                                            component="h1"
                                            id={summaryTitle}
                                            componentProps={{
                                                className: `cmf-title-center transaction-ticket-title`,
                                            }}
                                        />
                                    )}
                                </div>

                                {summary && (
                                    <Col xs={12} className="px-2 pb-4">
                                        <Container className="flex-grow align-items-center mt-2">
                                            <Col sm={12} className="cmf-container-white px-0">
                                                {children}
                                            </Col>

                                            {summary.data?.editedPermissions && (
                                                <Col sm={12} className="cmf-container-white px-0">
                                                    <Row className="mx-0">
                                                        <I18n
                                                            component="h2"
                                                            id="administration.configuratePermissions.confirm.added.subtitle"
                                                            componentProps={{
                                                                className: "transaction-ticket-signature-title",
                                                            }}
                                                        />
                                                    </Row>
                                                    <Row className="mx-0">
                                                        <PermissionsList
                                                            permissions={summary.data.editedPermissions.newPermissions}>
                                                            {(list) => {
                                                                if (list) {
                                                                    return <>{list}</>;
                                                                }

                                                                return <I18n id="administration.permissions.empty" />;
                                                            }}
                                                        </PermissionsList>
                                                    </Row>
                                                </Col>
                                            )}

                                            {summary.data?.removedPermissions && (
                                                <Col sm={12} className="cmf-container-white px-0">
                                                    <Row className="mx-0">
                                                        <I18n
                                                            component="h2"
                                                            id="administration.configuratePermissions.confirm.removed.subtitle"
                                                            componentProps={{
                                                                className: "transaction-ticket-signature-title",
                                                            }}
                                                        />
                                                    </Row>
                                                    <Row className="mx-0">
                                                        <PermissionsList
                                                            permissions={
                                                                summary?.data.editedPermissions.removedPermissions
                                                            }>
                                                            {(list) => {
                                                                if (list) {
                                                                    return <>{list}</>;
                                                                }

                                                                return <I18n id="administration.permissions.empty" />;
                                                            }}
                                                        </PermissionsList>
                                                    </Row>
                                                </Col>
                                            )}

                                            {summary?.responseIBS &&
                                                transfersActivitites.includes(summary?.idActivity) &&
                                                !transfersFunctionalities &&
                                                !payFunctionalities &&
                                                !chequesFunctionalities && (
                                                    <Col xs={12} className="px-3 mb-2 transaction-ticket-signature">
                                                        <Row style={{ justifyContent: "space-between" }}>
                                                            <FieldLabel
                                                                labelKey="transfers.failed.response"
                                                                notMarginY
                                                            />
                                                            <p className="data-desc">{summary?.responseIBS}</p>
                                                        </Row>
                                                    </Col>
                                                )}

                                            {summary?.idTransactionStatus === "FAILED" &&
                                                (summary?.voucherErrorCode || summary?.data?.voucherErrorCode) && (
                                                    <Col xs={12} className="px-3 mb-0 transaction-ticket-signature">
                                                        <Row style={{ justifyContent: "space-between" }}>
                                                            <FieldLabel
                                                                labelKey="failedVoucher.error.code.label"
                                                                notMarginY
                                                            />
                                                            <p className="data-desc my-1">
                                                                {summary?.voucherErrorCode ||
                                                                    summary?.data?.voucherErrorCode}
                                                            </p>
                                                        </Row>
                                                    </Col>
                                                )}
                                            {summary?.idTransactionStatus === "FAILED" &&
                                                (summary?.voucherErrorMessage ||
                                                    summary?.data?.voucherErrorMessage ||
                                                    summary?.voucherServiceMessage ||
                                                    summary?.data?.voucherServiceMessage) && (
                                                    <Col xs={12} className="px-3 transaction-ticket-signature">
                                                        <Row style={{ justifyContent: "space-between" }}>
                                                            <FieldLabel
                                                                labelKey="failedVoucher.error.message.label"
                                                                notMarginY
                                                            />
                                                            <p className="data-desc my-1">
                                                                {summary?.voucherErrorMessage ||
                                                                    summary?.data?.voucherErrorMessage ||
                                                                    summary?.voucherServiceMessage ||
                                                                    summary?.data?.voucherServiceMessage}
                                                            </p>
                                                        </Row>
                                                    </Col>
                                                )}

                                            {/* SE PUEDE USAR ESTE COMPONENTTE PERO HAY QUE ARREGLARLO */}
                                            {isAdministrationTicket && (
                                                <Col sm={12} className="cmf-container-white px-0">
                                                    <FormSignatures transaction={summary} isDesktop={isDesktop} />
                                                    {summary?.idTransactionStatus === "CANCELLED" && (
                                                        <Col xs={12} className="px-3 mb-2 transaction-ticket-signature">
                                                            <UserCancelled
                                                                userEditorFullName={summary?.data?.cancelledUser}
                                                                modificationDateTime={summary?.data?.cancelledTime}
                                                                isDesktop={isDesktop}
                                                            />
                                                        </Col>
                                                    )}
                                                </Col>
                                            )}

                                            {isPaymentAFIPTicket && (
                                                <Col sm={12} className="cmf-container-white px-0">
                                                    <TransactionsSignatures
                                                        isDesktop={isDesktop}
                                                        transactionData={summary}
                                                    />
                                                </Col>
                                            )}

                                            {!isAdministrationTicket && !isPaymentAFIPTicket && !hideSigns && (
                                                <Col sm={12} className="cmf-container-white px-0">
                                                    <Row className="mx-0">
                                                        <I18n
                                                            component="h2"
                                                            id="accounts.new.account.signatures.label"
                                                            componentProps={{
                                                                className: "transaction-ticket-signature-title",
                                                            }}
                                                        />
                                                    </Row>

                                                    {isDesktop ? (
                                                        <Col
                                                            xs={12}
                                                            className="px-3 mt-md-1 mb-2 transaction-ticket-signature">
                                                            <Row className="space-between align-items-start flex-no-wrap">
                                                                <FieldLabel
                                                                    labelKey="accounts.new.account.signatures.preparedBy.label"
                                                                    notMarginY
                                                                />
                                                                <p className="data-desc d-inline my-0 ml-auto justify-content-end">
                                                                    {summary?.creatorFullName}
                                                                    <span className="add-dash" />
                                                                    <FormattedDate
                                                                        date={
                                                                            summary?.creationDate ||
                                                                            summary?.creationDateTime
                                                                        }
                                                                        dateFormat="dd/MM/yyyy"
                                                                        showTime
                                                                        withSpan
                                                                    />
                                                                </p>
                                                            </Row>
                                                        </Col>
                                                    ) : (
                                                        <Col xs={12} className="px-3 mb-2 transaction-ticket-signature">
                                                            <Row>
                                                                <FieldLabel
                                                                    labelKey="accounts.new.account.signatures.preparedBy.label"
                                                                    notMarginY
                                                                    formGroupTextClassName="w-100"
                                                                />
                                                                <p className="data-desc my-0">
                                                                    {summary?.creatorFullName} -{" "}
                                                                    {
                                                                        <FormattedDate
                                                                            date={
                                                                                summary?.creationDate ||
                                                                                summary?.creationDateTime
                                                                            }
                                                                            dateFormat="dd/MM/yyyy"
                                                                            showTime
                                                                            withSpan
                                                                        />
                                                                    }
                                                                </p>
                                                            </Row>
                                                        </Col>
                                                    )}

                                                    {summary?.signatures?.length > 0 && (
                                                        <Col xs={12} className="px-3 mb-2 transaction-ticket-signature">
                                                            <UserSignatures
                                                                title="accounts.new.account.signatures.signBy.label"
                                                                list={summary?.signatures}
                                                                isDesktop={isDesktop}
                                                                showSignatureLevel={hasSignatureLevel}
                                                            />
                                                        </Col>
                                                    )}
                                                    {summary?.idTransactionStatus === "CANCELLED" && (
                                                        <Col xs={12} className="px-3 mb-2 transaction-ticket-signature">
                                                            <UserCancelled
                                                                userEditorFullName={
                                                                    summary?.returnCode
                                                                        ? i18n.get(`returnCode.${summary.returnCode}`)
                                                                        : summary?.cancelledUser
                                                                }
                                                                modificationDateTime={
                                                                    summary?.returnCode
                                                                        ? summary?.submitDateTimeAsString
                                                                        : summary?.cancelledTime
                                                                }
                                                                isDesktop={isDesktop}
                                                            />
                                                        </Col>
                                                    )}
                                                    {summary?.liberatorUser &&
                                                        summary?.idTransactionStatus === "FINISHED" && (
                                                            <Col
                                                                xs={12}
                                                                className="px-3 mb-2 transaction-ticket-signature">
                                                                <UserLiberator
                                                                    userEditorFullName={summary?.liberatorUser}
                                                                    modificationDateTime={summary?.liberatorTime}
                                                                    isDesktop={isDesktop}
                                                                />
                                                            </Col>
                                                        )}
                                                    {showModifiedBy && (
                                                        <Row
                                                            style={{ justifyContent: "space-between" }}
                                                            className="transaction-ticket-signature">
                                                            <FieldLabel labelKey="accounts.new.account.signatures.modifiedBy.label" />
                                                            <p>
                                                                {summary?.userEditorFullName || fullName} -{" "}
                                                                <FormattedDate
                                                                    date={summary?.modificationDateTime}
                                                                    dateFormat="dd/MM/yyyy"
                                                                    showTime
                                                                />
                                                            </p>
                                                        </Row>
                                                    )}
                                                </Col>
                                            )}
                                        </Container>
                                    </Col>
                                )}
                            </Col>
                        </Container>
                        {buttonsDetail && (
                            <div
                                className={classNames("mb-4 pt-4 d-flex gap-3", {
                                    "justify-content-center": isDesktop,
                                    "flex-column px-3": !isDesktop,
                                })}>
                                {this.renderButtonArray()}
                            </div>
                        )}
                    </main>
                </PageLoading>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    loggedUser: sessionSelectors.getUser(state),
    potentialSignaturesData: transactionsSelectors.getPotentialSignaturesData(state),
    activeEnvironment: sessionSelectors.getActiveEnvironment(state),
});

export default compose(connect(mapStateToProps), withFormik({}))(TransactionTicket);
