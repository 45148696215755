/* eslint-disable import/no-unresolved */
import React, { Component } from "react";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import { bool, number, arrayOf } from "prop-types";
import { connect } from "react-redux";
import { selectors as transactionsSelectors } from "reducers/transactions";
import FormattedAmount from "pages/_components/FormattedAmount";
import * as i18n from "util/i18n";

class SelectedTransactionsAmount extends Component {
    static propTypes = {
        totalRows: number.isRequired,
        selectedTransactionRows: arrayOf().isRequired,
        showTotalOperations: bool,
        withoutData: bool,
        isDesktop: bool.isRequired,
    };

    static defaultProps = {
        showTotalOperations: true,
        withoutData: false,
    };

    render() {
        const { totalRows, selectedTransactionRows, showTotalOperations, withoutData, isDesktop } = this.props;

        const initialValue = 0;

        const amountArs = selectedTransactionRows?.reduce(
            (prev, curr) =>
                prev +
                (curr?.transactionAmounts["0"] ||
                    curr?.transactionAmounts["01"] ||
                    curr?.transactionAmounts["032"] ||
                    0),
            initialValue,
        );
        const amountUsd = selectedTransactionRows?.reduce(
            (prev, curr) =>
                prev +
                (curr?.transactionAmounts["2"] ||
                    curr?.transactionAmounts["02"] ||
                    curr?.transactionAmounts["840"] ||
                    0),
            initialValue,
        );
        const valuesClass = "font-weight-medium text-14";
        let operations = i18n.get("transactions.selected.operations").concat(" ", selectedTransactionRows?.length);

        if (showTotalOperations) {
            operations = operations.concat(" de ", totalRows);
        }

        if (amountArs > 0 || amountUsd > 0) {
            return (
                <Row className={`${isDesktop ? "mx-0 justify-content-end" : "mx-0 justify-content-end w-100 pr-3"}`}>
                    <Col
                        xs={12}
                        className={`d-flex ml-md-5 mb-1 mb-md-0 justify-content-end px-md-0 ${!isDesktop &&
                            "w-100 pr-0"}`}>
                        <span className={`p-0 mr-2 pt-1 ${valuesClass} ${isDesktop ? "mr-2" : "mr-0"}`}>
                            {i18n.get("transactions.selected.total.amount").toUpperCase()}
                        </span>
                        <FormattedAmount cleanClassName fontClassName={valuesClass} currency="0" quantity={amountArs} />
                        {isDesktop ? (
                            <>
                                <span className={`px-1 pt-1 ${valuesClass}`}>|</span>
                                <FormattedAmount
                                    cleanClassName
                                    className="data-amount"
                                    fontClassName={valuesClass}
                                    currency="2"
                                    quantity={amountUsd}
                                />
                            </>
                        ) : (
                            <>
                                <span className={`px-1 pt-1 ${valuesClass}`}>|</span>
                                <FormattedAmount
                                    cleanClassName
                                    className="data-amount"
                                    fontClassName={valuesClass}
                                    currency="2"
                                    quantity={amountUsd}
                                />
                            </>
                        )}
                    </Col>
                    <Col xs={12} className="px-0">
                        <p className="m-0 text-align-right font-weight-normal text-12"> {operations} </p>
                    </Col>
                </Row>
            );
        }
        if (withoutData) {
            return (
                <Row className="mx-0 justify-content-end">
                    <Col
                        xs={12}
                        className={`d-flex ml-md-5 mb-1 mb-md-0 justify-content-end px-md-0 ${!isDesktop &&
                            "w-100 pr-0"}`}>
                        <span className={`p-0 mr-2 pt-1 ${valuesClass} ${isDesktop ? "mr-2" : "mr-0"}`}>
                            {i18n.get("transactions.selected.total.amount").toUpperCase()}
                        </span>
                        <FormattedAmount cleanClassName fontClassName={valuesClass} currency="0" quantity={0} />
                        <span className={`px-1 pt-1 ${valuesClass}`}>|</span>
                        <FormattedAmount cleanClassName fontClassName={valuesClass} currency="2" quantity={0} />
                    </Col>
                    <Col xs={12} className="px-0">
                        <p className="m-0 text-align-right font-weight-normal text-12"> {operations} </p>
                    </Col>
                </Row>
            );
        }
        return null;
    }
}

const mapStateToProps = (state) => ({
    totalRows: transactionsSelectors.getTotalRows(state),
});

export default connect(mapStateToProps)(SelectedTransactionsAmount);
