import * as API from "middleware/api";
import { format } from "date-fns";

export const loadListRequest = (filters, onlyPendings, pendingDispatch) => {
    let { dateFrom, dateTo } = filters;
    const {
        pageNumber,
        minAmount,
        maxAmount,
        channel,
        client,
        dateType = null,
        status,
        idActivity,
        filterPendingDispatch,
        functionRequest,
        orderBy,
    } = filters;

    dateFrom = dateFrom ? format(dateFrom, "YYYY-MM-DD") : null;
    dateTo = dateTo ? format(dateTo, "YYYY-MM-DD") : null;

    return API.executeWithAccessToken("/transactions.list", {
        dateFrom,
        dateTo,
        pageNumber,
        minAmount: minAmount?.amount,
        maxAmount: maxAmount?.amount,
        minAmountCurrency: minAmount?.currency,
        maxAmountCurrency: maxAmount?.currency,
        channel,
        onlyPendings,
        pendingDispatch,
        client,
        dateType,
        status,
        idActivity,
        functionRequest,
        filterPendingDispatch: filterPendingDispatch || false,
        orderBy,
    });
};

export const downloadPendingTransactionsRequest = (filters, onlyPendings, pendingDispatch, downloadFormat) => {
    let { dateFrom, dateTo } = filters;
    const {
        minAmount,
        maxAmount,
        channel,
        client,
        dateType = null,
        status,
        idActivity,
        filterPendingDispatch,
        functionRequest,
        orderBy,
        filter,
    } = filters;

    dateFrom = dateFrom ? format(dateFrom, "YYYY-MM-DD") : null;
    dateTo = dateTo ? format(dateTo, "YYYY-MM-DD") : null;

    return API.executeWithAccessToken("/pending.transactions.download", {
        dateFrom,
        dateTo,
        minAmount: minAmount?.amount,
        maxAmount: maxAmount?.amount,
        minAmountCurrency: minAmount?.currency,
        maxAmountCurrency: maxAmount?.currency,
        channel,
        onlyPendings,
        pendingDispatch,
        client,
        dateType,
        status,
        idActivity,
        functionRequest,
        filterPendingDispatch: filterPendingDispatch || false,
        orderBy,
        format: downloadFormat,
        filter,
    });
};

export const loadTransactionActivities = () => API.executeWithAccessToken("/transactions.list.types");

export const signMultipleTransactions = (idActivity, transactions, otp, userId, idForm) =>
    API.executeWithAccessToken("/transactions.multiple.signatures", {
        transactions,
        idActivity,
        _otp: otp,
        userId,
        idForm,
    });

export const deleteMultipleTransactions = (idActivity, transactions, otp, userId, idForm) =>
    API.executeWithAccessToken("/transactions.multiple.delete", {
        transactions,
        idActivity,
        _otp: otp,
        userId,
        idForm,
    });

export const deleteDraftRequest = (idTransaction) =>
    API.executeWithAccessToken("/core.deleteTransactionDraft", { idTransactionToDelete: idTransaction });

export const getPendingTransactionsQuantity = () => API.executeWithAccessToken("/transactions.get.pending.quantity");

export const potentialSignaturesRequest = (idTransaction) =>
    API.executeWithAccessToken("/transactions.potential.signatures.get", { idTransactionToRead: idTransaction });

export const exportGeneralTicket = (generalTicketData) =>
    API.executeWithAccessToken("/generic.ticket.pdf", { ...generalTicketData });

export const isCbuFrequentDestination = (filters) =>
    API.executeWithAccessToken("/transfer.frequentDestinations.cbu", { cbuSearch: filters });

export const multipleSignatureDataPreview = (idActivity, transactions) =>
    API.executeWithAccessToken("/transactions.multiple.signatures.preview", { idActivity, transactions });
